import { Component, OnInit, ViewChild } from '@angular/core';
import { DataBaseService } from '../provider.service';
import { MASKS } from 'ng-brazil';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-usuarios',
  templateUrl: './colaboradores.component.html',
  styleUrls: ['./colaboradores.component.css']
})

export class ColaboradoresComponent implements OnInit {
  @ViewChild('pdfViewerUsuarios') pdfViewerOnDemand;
  public MASKS = MASKS;
  times:FormGroup;
  public timesList: FormArray;
  get timesFormGroup(){return this.times.get('time') as FormArray;}
  equipe:FormGroup;
  public equipeList: FormArray;
  get equipeFormGroup(){return this.equipe.get('equipe') as FormArray;}
  equipeEditar:FormGroup;
  public equipeListEditar: FormArray;
  get equipeFormGroupEditar(){return this.equipeEditar.get('equipeEditar') as FormArray;}
  cargos:FormGroup;
  public cargosList: FormArray;
  get cargosFormGroup(){return this.cargos.get('cargo') as FormArray;}
  todosTimes:any = [];
  todosCargos:any = [];
  usuarios:any = [];
  observacaoEditar:any = [];
  files:any = [];
  selectedFiles:any = [];
  pdfs:any = [];
  images:any = [];
  docs:any = [];
  vendedores:any = [];
  excluirAssociado:any = "NÃO";
  excluirAssociadoEditar:any = "NÃO";
  color:any="#337ab7";
  colorEditar:any="#337ab7";
  idTime:any = "";
  idCargo:any = "";
  nome:any = "";
  nomeEditar:any = "";
  email:any = "";
  emailEditar:any = "";
  cpf:any = "";
  cpfEditar:any = "";
  telefone:any = "";
  telefoneEditar:any = "";
  telefoneRecado:any = "";
  telefoneRecadoEditar:any = "";
  pessoaRecado:any = "";
  pessoaRecadoEditar:any = "";
  estadoCivil:any = "";
  estadoCivilEditar:any = "";
  cep:any = "";
  cepEditar:any = "";
  rua:any = "";
  ruaEditar:any = "";
  numero:any = "";
  numeroEditar:any = "";
  bairro:any = "";
  bairroEditar:any = "";
  municipio:any = "";
  municipioEditar:any = "";
  estado:any = "";
  estadoEditar:any = "";
  complemento:any = "";
  complementoEditar:any = "";
  tipo:any = "";
  tipoEditar:any = "";
  nascimento:any = "";
  nascimentoEditar:any = "";
  admissao:any = "";
  admissaoEditar:any = "";
  situacao:any = "";
  situacaoEditar:any = "";
  dataDemissao:any = "";
  dataDemissaoEditar:any = "";
  senha:any = "";
  senhaEditar:any = "";
  idUsuario:any = "";
  gerente:any = "";
  gerenteEditar:any = "";
  fimExperiencia:any = "";
  fimExperienciaEditar:any = "";
  observacao:any = "";
  pdfUrl:any = "";
  newObservacao:any = "";
  imagemSelecionada:any = "";
  timeVendedor:any = "";
  timeVendedorEditar:any = "";
  tamanhoUniformeEditar = "";
  tamanhoUniforme = "";
  dataHoje:any = ""; 
  tipoTime:any = "";
  razaoEmpresa:any = "";
  fantasiaEmpresa:any = "";
  cnpj:any = "";
  nomeContato:any = "";
  cargoResponsavel:any = "";
  razaoEmpresaEditar:any = "";
  fantasiaEmpresaEditar:any = "";
  cnpjEditar:any = "";
  nomeContatoEditar:any = "";
  cargoResponsavelEditar:any = "";
  indexImage:number = 0;
  cargoAntigo:any = "";
  cargoNovo:any = "";
  colaboradorComercialEditar:boolean = false;
  colaboradorComercial:boolean = false;
  logCargo:any = {};
  representantePjEditar:boolean = false;
  representantePj:boolean = false;
  colaboradorVivaMax:boolean = false;
  colaboradorVivaMaxEditar:boolean = false;
  permissoesEditar:any = "";
  tipoUsuario:any = "";
  todosUsuarios:any = [];
  filtroSituacao:any = "EFETIVADO";
  meta:number = 0;
  bonus:number = 0;
  metaEditar:number = 0;
  bonusEditar:number = 0;
  comissaoSobreVendas:number = 0;
  comissaoSobreVendasEditar:number = 0;
  comissaoSobreVendasCheck:boolean = false;
  comissaoSobreVendasCheckEditar:boolean = false;
  permissoes:any = {
    agenda : "NENHUMA",
    associados : "NENHUMA",
    atendimento : "NENHUMA",
    cadastroAssociados : "NENHUMA",
    caixa : "NENHUMA",
    cancelamentos : "NENHUMA",
    colaboradores : "NENHUMA",
    conecta : "NENHUMA",
    configuracoes : "NENHUMA",
    contratos : "NENHUMA",
    credenciados : "NENHUMA",
    dashboard : "NENHUMA",
    documentos : "NENHUMA",
    empresas : "NENHUMA",
    faturamento : "NENHUMA",
    financeiro : "NENHUMA",
    logs : "NENHUMA",
    planos : "NENHUMA",
    relatorios : "NENHUMA",
    sinistro : "NENHUMA",
    tarefas : "NENHUMA",
    vencimentos : "NENHUMA",
    vendas : "NENHUMA",
    centroCusto : "NENHUMA",
    programacao : "NENHUMA",
	  comissoes : "NENHUMA",
    marketing : "NENHUMA",
    fornecedores:"NENHUMA",
    dashboardAdm:"NENHUMA"
  };

  constructor(public db:DataBaseService, private formBuilder: FormBuilder, public app:AppComponent) { }

  ngOnInit(){
    this.tipoUsuario = localStorage.getItem('tipoVivamax');
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.times = this.formBuilder.group({time: this.formBuilder.array([this.createTime()])}); 
    this.timesList = this.times.get('time') as FormArray;
    this.equipe = this.formBuilder.group({equipe: this.formBuilder.array([this.createEquipe()])}); 
    this.equipeList = this.equipe.get('equipe') as FormArray;
    this.equipeEditar = this.formBuilder.group({equipeEditar: this.formBuilder.array([this.createEquipeEditar()])}); 
    this.equipeListEditar = this.equipeEditar.get('equipeEditar') as FormArray;
    this.cargos = this.formBuilder.group({cargo: this.formBuilder.array([this.createCargo()])}); 
    this.cargosList = this.cargos.get('cargo') as FormArray;
    this.db.getConfigs().subscribe((z:any) => {
        this.todosTimes = z[6].times;
        this.idTime = z[6]._id;
        if(this.todosTimes.length > 0){
          this.timesList.clear();
          this.todosTimes.forEach((item) => {
            this.addTime(item);
          })
        }
        this.todosCargos = z[22].cargos;
        this.idCargo = z[22]._id;
        if(this.todosCargos.length > 0){
          this.cargosList.clear();
          this.todosCargos.forEach((item) => {
            this.addCargo(item);
          })
        }
      this.db.getAllUsuarios().subscribe((dataUser:any) => {
        this.todosUsuarios = dataUser;
        let v = dataUser.filter((item:any) => {return item.colaboradorComercial == true && (item.situacao == "EFETIVADO" || item.situacao == "EXPERIÊNCIA")});
        this.vendedores = v.sort((a:any,b:any) => {if(a.nome < b.nome)return -1});
        this.filterColaboradores();
      });
    });
  }

  filterColaboradores(){
    if(this.filtroSituacao == ""){
      this.usuarios = this.todosUsuarios.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}return 1});
    }else{
      this.usuarios = this.todosUsuarios.filter((x:any) => { return x.situacao == this.filtroSituacao}).sort((a:any,b:any) => {if(a.nome < b.nome){return -1}return 1});
    }
  }

  addTime(item){
    this.timesList.push(this.createNovoTime(item));
  }

  createNovoTime(item):FormGroup{
    return this.formBuilder.group({
      time: [item.time, Validators.compose([Validators.required])],
      tipo:[item.tipo, Validators.compose([Validators.required])],
      vendedores:[[]]
    });
  }

  createEquipe():FormGroup{
    return this.formBuilder.group({
      vendedor: [null, Validators.compose([Validators.required])],
      id:[null, Validators.compose([Validators.required])],
    });
  }

  addEquipeNovo(){
    this.equipeList.push(this.createEquipe());
  }

  removeEquipe(index) {
    this.equipeList.removeAt(index);
  }

  selectVendedorEquipe(i:any,x:any){
    let index = this.vendedores.map((v:any) => {
      return v.nome;
    }).indexOf(x.srcElement.value);
    if(index >= 0){
      this.equipe.get('equipe.'+i+'.id').patchValue(this.vendedores[index]._id);
    }
  }
  
  createEquipeEditar():FormGroup{
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])],
      id:[null, Validators.compose([Validators.required])],
    });
  }

  createEquipeEditarOpen(item:any):FormGroup{
    return this.formBuilder.group({
      nome: [item.nome, Validators.compose([Validators.required])],
      id:[item.id, Validators.compose([Validators.required])],
    });
  }

  addEquipeEditarOpen(item:any){
    this.equipeListEditar.push(this.createEquipeEditarOpen(item));
  }

  addEquipeEditar(){
    this.equipeListEditar.push(this.createEquipeEditar());
  }

  removeEquipeEditar(index) {
    this.equipeListEditar.removeAt(index);
  }

  selectVendedorEquipeEditar(i:any,x:any){
    let index = this.vendedores.map((v:any) => {
      return v.nome;
    }).indexOf(x.srcElement.value);
    if(index >= 0){
      this.equipeEditar.get('equipeEditar.'+i+'.id').patchValue(this.vendedores[index]._id);
    }
  }

  createTime():FormGroup{
    return this.formBuilder.group({
      time: [null, Validators.compose([Validators.required])],
      tipo:[null, Validators.compose([Validators.required])],
      vendedores:[[]]
    });
  }

  addTimeNovo(){
    this.timesList.push(this.createTime());
  }

  removeTime(index) {
    this.timesList.removeAt(index);
  }

  salvarTimes(){
    let dados = {times: this.timesList.value};
    this.db.patchConfiguracoes(this.idTime,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let oLogs = {
        "menu" : "COLABORADORES", 
        "descricao" : "TIMES ALTERADOS", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : localStorage.getItem('usuarioVivamax')
      }
      this.db.postLogs(oLogs).subscribe(res => {
        console.log(res);
      this.app.openAlert("Time Salvo com sucesso!");
      this.closeModalTimes();
      this.ngOnInit();
      }, error => console.log(error))
    }, err => {
      console.log(err)
    })
  }

  openModalTimes(){
    let modal = document.getElementById('modalTimes');
    modal.style.display = "block"
  }

  closeModalTimes(){
    let modal = document.getElementById('modalTimes');
    modal.style.display = "none"
  }

  addCargo(item){
    this.cargosList.push(this.createNovoCargo(item));
  }

  createNovoCargo(item):FormGroup{
    return this.formBuilder.group({
      cargo: [item.cargo, Validators.compose([Validators.required])]
    });
  }

  createCargo():FormGroup{
    return this.formBuilder.group({
      cargo: [null, Validators.compose([Validators.required])]
    });
  }

  addCargoNovo(){
    this.cargosList.push(this.createCargo());
  }

  removeCargo(index) {
    this.cargosList.removeAt(index);
  }

  salvarCargos(){
    let dados = {cargos: this.cargosList.value};
    this.db.patchConfiguracoes(this.idCargo,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let oLogs = {
        "menu" : "COLABORADORES", 
        "descricao" : "CARGOS ALTERADOS", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : localStorage.getItem('usuarioVivamax')
      }
      this.db.postLogs(oLogs).subscribe(res => {
        console.log(res);
      this.app.openAlert("Cargo Salvo com sucesso!");
      this.closeModalCargos();
      this.ngOnInit();
    }, error => console.log(error))
    }, err => {
      console.log(err)
    })
  }

  openModalCargos(){
    let modal = document.getElementById('modalCargos');
    modal.style.display = "block"
  }

  closeModalCargos(){
    let modal = document.getElementById('modalCargos');
    modal.style.display = "none"
  }

  limparSelects(){
    this.gerente = "";
  }

  limparSelectsEditar(){
    this.gerenteEditar = "";
    this.logCargo = {
      cargoAntigo: this.cargoAntigo,
      cargoNovo : this.tipoEditar
    }
  }

  openModalNovo(){
    let modal = document.getElementById('modalNovo');
    modal.style.display = "block";
  }

  closeModalNovo(){
    let modal = document.getElementById('modalNovo');
    modal.style.display = "none";
    this.nome = "";
    this.email = "";
    this.cpf = "";
    this.telefone = "";
    this.tipo = "";
    this.nascimento = "";
    this.senha = "";
    this.admissao = "";
    this.excluirAssociado = "NÃO";
    this.telefoneRecado = "";
    this.estadoCivil = "";
    this.cep = "";
    this.rua = "";
    this.pessoaRecado = "";
    this.numero = "";
    this.bairro = "";
    this.complemento = "";
    this.municipio = "";
    this.estado = "";
    this.situacao = "";
    this.fimExperiencia = "";
    this.observacao = "";
    this.timeVendedor = "";
    this.tamanhoUniforme = "";
    this.representantePj = false;
    this.colaboradorComercial = false;
    this.colaboradorVivaMax = false;
    this.color="#337ab7";
    this.razaoEmpresa = "";
    this.fantasiaEmpresa = "";
    this.cnpj = "";
    this.nomeContato = "";
    this.cargoResponsavel = "";
    this.permissoes = {
      agenda : "NENHUMA",
      associados : "NENHUMA",
      atendimento : "NENHUMA",
      cadastroAssociados : "NENHUMA",
      caixa : "NENHUMA",
      cancelamentos : "NENHUMA",
      colaboradores : "NENHUMA",
      conecta : "NENHUMA",
      configuracoes : "NENHUMA",
      contratos : "NENHUMA",
      credenciados : "NENHUMA",
      dashboard : "NENHUMA",
      documentos : "NENHUMA",
      empresas : "NENHUMA",
      faturamento : "NENHUMA",
      financeiro : "NENHUMA",
      logs : "NENHUMA",
      planos : "NENHUMA",
      relatorios : "NENHUMA",
      sinistro : "NENHUMA",
      tarefas : "NENHUMA",
      vencimentos : "NENHUMA",
      vendas : "NENHUMA",
      centroCusto : "NENHUMA",
      programacao : "NENHUMA",
      comissoes : "NENHUMA",
      marketing : "NENHUMA",
      fornecedores: "NENHUMA"
    },
    this.meta = 0;
    this.bonus = 0;
    this.comissaoSobreVendas = 0;
    this.comissaoSobreVendasCheck = false;
  }

  cadastrarUsuario(){
    if(window.confirm('Deseja realmente cadastrar esse usuário?')){
      if(!this.admissao){
        this.admissao = this.dataHoje;
      }
      if(!this.tipo){
        this.tipo = "REPRESENTANTE PJ";
      }
      if(this.representantePj){
        this.permissoes.vendas = "EDITAR";
      }
      let equipe = !this.comissaoSobreVendasCheck ? null : this.equipeList.value;
      let fim = moment(this.admissao.split('/').reverse().join('-')).add(45,'days').toISOString().split('T')[0];
      let obs = [];
      if(this.observacao == ("" || null || undefined)){
        obs = []
      }else{
        obs = [{
          conteudo:this.observacao,
          data: this.dataHoje
        }]
      }
      let dados = {
        admissao:this.admissao,
        nome: this.nome,
        email:this.email,
        cpf: this.cpf,
        telefone: this.telefone,
        tipo: this.tipo,
        nascimento: this.nascimento,
        pwd: this.senha,
        gerente:this.gerente,
        documentos:[],
        excluirAssociado:this.excluirAssociado,
        telefoneRecado: this.telefoneRecado,
        estadoCivil: this.estadoCivil,
        cep:this.cep,
        rua:this.rua,
        pessoaRecado:this.pessoaRecado,
        numero:this.numero,
        bairro:this.bairro,
        complemento:this.complemento,
        municipio:this.municipio,
        estado:this.estado,
        situacao:this.situacao,
        fimExperiencia: fim,
        observacao:obs,
        vendas:[],
        time:this.timeVendedor,
        tamanhoUniforme: this.tamanhoUniforme,
        agenda:[],
        tarefas:[],
        avisos:[],
        color:this.color.replace('#','%23'),
        representantePj:this.representantePj,
        razaoEmpresa : this.razaoEmpresa,
        fantasiaEmpresa : this.fantasiaEmpresa,
        cnpj : this.cnpj,
        nomeContato : this.nomeContato,
        cargoResponsavel : this.cargoResponsavel,
        colaboradorComercial: this.colaboradorComercial,
        permissoes: this.permissoes,
        meta:this.meta,
        bonus:this.bonus,
        comissaoSobreVendas:this.comissaoSobreVendas,
        comissaoSobreVendasCheck: this.comissaoSobreVendasCheck,
        equipe: equipe
      }
      this.db.cadastroUsuario(dados).subscribe(res => {
        console.log(res);
        this.app.openAlert("Usuário Cadastrado com Sucesso!");
        this.closeModalNovo();
        this.ngOnInit();
      },err => {
        console.log(err);
        this.app.openAlert("Não foi possivel cadastrar, tente novamente!")
      })
    }
  }

  excluirUsuario(id){
    if(window.confirm("Deseja realmente excluri esse usuário?")){
      this.db.deleteUsuario(id).subscribe(res => {
        console.log(res)
        this.app.openAlert("Usuário Excluido com Sucesso!");
        this.ngOnInit()
      }, err => {
        console.log(err);
        this.app.openAlert("Erro ao Excluir Usuário, Tente Novamente!")
      })
    }
  }

  openModalEditar(usuario){
    this.admissaoEditar = usuario.admissao,
    this.nomeEditar = usuario.nome;
    this.emailEditar = usuario.email;
    this.cpfEditar = usuario.cpf;
    this.nascimentoEditar = usuario.nascimento;
    this.telefoneEditar = usuario.telefone;
    this.cargoAntigo = usuario.tipo;
    this.tipoEditar = usuario.tipo;
    this.senhaEditar = usuario.pwd;
    this.idUsuario = usuario._id;
    this.gerenteEditar = usuario.gerente;
    this.docs = usuario.documentos;
    this.excluirAssociadoEditar = usuario.excluirAssociado;
    this.telefoneRecadoEditar = usuario.telefoneRecado;
    this.estadoCivilEditar = usuario.estadoCivil;
    this.cepEditar = usuario.cep;
    this.ruaEditar = usuario.rua;
    this.pessoaRecadoEditar = usuario.pessoaRecado,
    this.numeroEditar = usuario.numero;
    this.bairroEditar = usuario.bairro;
    this.complementoEditar = usuario.complemento;
    this.municipioEditar = usuario.municipio;
    this.estadoEditar = usuario.estado;
    this.situacaoEditar = usuario.situacao;
    this.timeVendedorEditar = usuario.time;
    this.fimExperienciaEditar = usuario.fimExperiencia ? usuario.fimExperiencia?.split('-').reverse().join('/') : "";
    this.tamanhoUniformeEditar = usuario.tamanhoUniforme;
    this.representantePjEditar = usuario.representantePj;
    this.colaboradorComercialEditar = usuario.colaboradorComercial;
    this.permissoesEditar = usuario.permissoes;
    this.metaEditar = usuario.meta;
    this.bonusEditar = usuario.bonus;
    this.comissaoSobreVendasEditar = usuario.comissaoSobreVendas;
    this.comissaoSobreVendasCheckEditar = usuario.comissaoSobreVendasCheck;
    if(!this.representantePjEditar){
      this.colaboradorVivaMaxEditar = true;
    }
    this.razaoEmpresaEditar = usuario.razaoEmpresa;
    this.fantasiaEmpresaEditar = usuario.fantasiaEmpresa;
    this.cnpjEditar = usuario.cnpj;
    this.nomeContatoEditar = usuario.nomeContato;
    this.cargoResponsavelEditar = usuario.cargoResponsavel;
    if(usuario.observacao){
      this.observacaoEditar = usuario.observacao;
    }else{
      this.observacaoEditar = [];
    }
    if(usuario.color){
      this.colorEditar = usuario.color;
    }else{
      this.colorEditar = "#337ab7";
    }
    if(this.docs && this.docs.length > 0){
      this.docs.forEach((item) => {
       let ext = item.url.split('.').pop();
        if(ext == 'pdf' || ext == 'PDF'){
          this.pdfs.push(item);
        }else{
          this.images.push(item);
        }
      })
    }
    if(usuario.equipe?.length > 0){
      this.equipeListEditar.clear();
      usuario.equipe.forEach((item:any) => {
        this.addEquipeEditarOpen(item);
      })
    }
    let modal = document.getElementById('modalEditar');
    modal.style.display = "block";
    this.delButton();
 }

 fecharModalEditar(){
    let modal = document.getElementById('modalEditar');
    modal.style.display = "none";
    this.admissaoEditar = "";
    this.nomeEditar = "";
    this.emailEditar = "";
    this.representantePjEditar = false;
    this.cpfEditar = "";
    this.nascimentoEditar = "";
    this.telefoneEditar = "";
    this.tipoEditar = "";
    this.senhaEditar = "";
    this.idUsuario = "";
    this.gerente = "";
    this.telefoneRecadoEditar = "";
    this.estadoCivilEditar = "";
    this.cepEditar = "";
    this.ruaEditar = "";
    this.pessoaRecadoEditar = "";
    this.numeroEditar = "";
    this.bairroEditar = "";
    this.complementoEditar = "";
    this.municipioEditar = "";
    this.estadoEditar = "";
    this.situacaoEditar = "";
    this.fimExperienciaEditar = "";
    this.observacaoEditar = [];
    this.docs = [];
    this.pdfs = [];
    this.images = [];
    this.excluirAssociadoEditar = "NÃO";
    this.timeVendedorEditar = "";
    this.tamanhoUniformeEditar = "";
    this.colorEditar = "337ab7";
    this.razaoEmpresaEditar = "";
    this.fantasiaEmpresaEditar = "";
    this.cnpjEditar = "";
    this.nomeContatoEditar = "";
    this.cargoResponsavelEditar = "";
    this.permissoesEditar = {
        agenda : "NENHUMA",
        associados : "NENHUMA",
        atendimento : "NENHUMA",
        cadastroAssociados : "NENHUMA",
        caixa : "NENHUMA",
        cancelamentos : "NENHUMA",
        colaboradores : "NENHUMA",
        conecta : "NENHUMA",
        configuracoes : "NENHUMA",
        contratos : "NENHUMA",
        credenciados : "NENHUMA",
        dashboard : "NENHUMA",
        documentos : "NENHUMA",
        empresas : "NENHUMA",
        faturamento : "NENHUMA",
        financeiro : "NENHUMA",
        logs : "NENHUMA",
        planos : "NENHUMA",
        relatorios : "NENHUMA",
        sinistro : "NENHUMA",
        tarefas : "NENHUMA",
        vencimentos : "NENHUMA",
        vendas : "NENHUMA",
        centroCusto : "NENHUMA",
        programacao : "NENHUMA",
        marketing : "NENHUMA",
        fornecedores: "NENHUMA"
    }
    this.metaEditar = 0;
    this.bonusEditar = 0;
    this.comissaoSobreVendasEditar = 0;
    this.comissaoSobreVendasCheckEditar = false;
    this.logCargo = {};
    this.colaboradorComercialEditar = false;
    this.equipeListEditar.clear();
 }

 editarUsuario(){
  if(window.confirm('Deseja realmente editar esse usuário?')){
    if(this.logCargo.cargoAntigo){
      let dados = {
        data: this.dataHoje,
        conteudo: "Cargo alterado de "+this.logCargo.cargoAntigo+" para "+this.logCargo.cargoNovo+" pelo usuario: "+localStorage.getItem('usuarioVivamax')
      }
      this.observacaoEditar.push(dados);
    }     
    this.tipoEditar = this.representantePjEditar ? "REPRESENTANTE PJ" : this.tipoEditar;
    if(this.representantePjEditar){
      this.permissoesEditar.vendas = "EDITAR";
    }
    let equipeEditar = !this.comissaoSobreVendasCheckEditar ? null : this.equipeListEditar.value;
    let dados = {
      admissao:this.admissaoEditar,
      nome: this.nomeEditar,
      email:this.emailEditar,
      cpf: this.cpfEditar,
      telefone: this.telefoneEditar,
      tipo: this.tipoEditar,
      nascimento: this.nascimentoEditar,
      pwd: this.senhaEditar,
      representantePj : this.representantePjEditar,
      gerente:this.gerenteEditar,
      excluirAssociado : this.excluirAssociadoEditar,
      telefoneRecado: this.telefoneRecadoEditar,
      estadoCivail: this.estadoCivilEditar,
      cep:this.cepEditar,
      rua:this.ruaEditar,
      numero:this.numeroEditar,
      bairro:this.bairroEditar,
      complemento:this.complementoEditar,
      municipio:this.municipioEditar,
      estado:this.estadoEditar,
      situacao:this.situacaoEditar,
      fimExperiencia:this.fimExperienciaEditar,
      observacao:this.observacaoEditar,
      pessoaRecado:this.pessoaRecadoEditar,
      time:this.timeVendedorEditar,
      tamanhoUniforme: this.tamanhoUniformeEditar,
      color:this.colorEditar.replace('#','%23'),
      razaoEmpresa : this.razaoEmpresaEditar,
      fantasiaEmpresa : this.fantasiaEmpresaEditar,
      cnpj : this.cnpjEditar,
      nomeContato : this.nomeContatoEditar,
      cargoResponsavel : this.cargoResponsavelEditar,
      colaboradorComercial : this.colaboradorComercialEditar,
      permissoes: this.permissoesEditar,
      meta: this.metaEditar,
      bonus: this.bonusEditar,
      comissaoSobreVendas:this.comissaoSobreVendasEditar,
      comissaoSobreVendasCheck: this.comissaoSobreVendasCheckEditar,
      equipe: equipeEditar
    }
    this.db.patchUsuario(this.idUsuario, dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let oLogs = {
        "menu" : "COLABORADORES", 
        "descricao" : "COLABORADOR "+this.nomeEditar+" ALTERADO", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : localStorage.getItem('usuarioVivamax')
      }
      this.db.postLogs(oLogs).subscribe(res => {
        console.log(res);
        this.app.openAlert("Usuário Editado com Sucesso!");
        this.fecharModalEditar();
        this.ngOnInit();
       },err => {
        console.log(err)
      });      
    },err => {
      console.log(err);
      this.app.openAlert("Não foi possivel editar, tente novamente!")
    });
  }
 }

 onChange(event:any){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

uploadFile(){
 const imagedata = new FormData();
 for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
  this.db.enviarDocUser(this.idUsuario, imagedata).subscribe((res) => {
      console.log(res);
      this.atualizarDocs();
    },(error) => {
      console.log(error);
      this.atualizarDocs();
    });
}

atualizarDocs(){
  this.docs = [];
  this.db.getUsuarioById(this.idUsuario).subscribe((result:any) => {
    this.docs = result.documentos;
    this.pdfs = [];
    this.images = [];
    this.selectedFiles = "";
    this.files = [];
    if(this.docs && this.docs.length > 0){
      this.pdfs = [];
      this.images = [];
      this.docs.forEach((item) => {
      let ext = item.url.split('.').pop();
        if(ext == 'pdf' || ext == 'PDF'){
          this.pdfs.push(item);
        }else{
          this.images.push(item);
        }
      })
    }
  })
}

openModalPdfViewer(url){
  this.pdfViewerOnDemand.pdfSrc = url;
  this.pdfViewerOnDemand.refresh();
}

openModalLightBox(url, i){
  console.log(url)
  this.indexImage = i;
  this.imagemSelecionada = url;
  let modal = document.getElementById('myModal')
  modal.style.display = "block";
}

nextImg(){
  let i = this.indexImage
  if((this.images.length - 1) == i){
    this.imagemSelecionada = this.images[0].url;
    this.indexImage = 0;
  }else{
    i++
    this.imagemSelecionada = this.images[i].url;
    this.indexImage++
  }
}

prevImg(){
  let i = this.indexImage
  if(i == 0){
    this.imagemSelecionada = this.images[this.images.length - 1].url;
    this.indexImage = this.images.length - 1;
  }else{
    i--
    this.imagemSelecionada = this.images[i].url;
    this.indexImage--
  }
}

closeModal(){
  let modal = document.getElementById('myModal')
  modal.style.display = "none";
}

delButton(){
  let buttonDiv = document.getElementsByName("delButton")
  for(let i=0; i < buttonDiv.length; i++ ){
    buttonDiv[i].toggleAttribute("hidden")
    if(buttonDiv[i].className == "delButton"){
      buttonDiv[i].className = "delButtonShow"
    }else{
      buttonDiv[i].className = "delButton"
    }
  }
}

delDoc(url:any){
   if(window.confirm('Deseja Realmente Deletar esse Documento?')){
    this.db.removeDoc(url).subscribe(res => {
      console.log(res);
      this.db.removeDocUsuarioDB(this.idUsuario,url).subscribe(result => {
        console.log(result);
        this.atualizarDocsDel(url);
      }, error => {
        console.log(error);
        this.atualizarDocsDel(url);
      })
     },err => {
       console.log(err);
        this.db.removeDocUsuarioDB(this.idUsuario,url).subscribe(result => {
        console.log(result);
        this.atualizarDocsDel(url);
      }, error => {
        console.log(error);
        this.atualizarDocsDel(url);
      })
    })
  }
}

atualizarDocsDel(url){
  let idx = this.docs.map((item) => {
    return item.url
  }).indexOf(url)
  if(idx >= 0){
    this.docs.splice(idx,1);
    if(this.docs && this.docs.length > 0){
        this.pdfs = [];
        this.images = [];
        this.docs.forEach((item) => {
        let ext = item.url.split('.').pop();
          if(ext == 'pdf' || ext == 'PDF'){
            this.pdfs.push(item);
          }else{
            this.images.push(item);
          }
        })
      }
    }
  }

  buscarCep(cepGet){
    let c = cepGet.replace(/_/gi,'').replace('.','').replace('-','');
    if(c.length > 7){
      this.db.getCep(c).subscribe((data:any) => {
        let cep = data;
         if(cep.erro == true){
           this.app.openAlert("CEP NAO ENCONTRADO");
         }else{
           this.rua = cep.logradouro.toUpperCase();
           this.bairro = cep.bairro.toUpperCase();
           this.municipio = cep.localidade.toUpperCase();
           this.estado = cep.uf.toUpperCase();
         }
      });
    }
  }

  buscarCepEditar(cepGet){
    let c = cepGet.replace(/_/gi,'').replace('.','').replace('-','');
    if(c.length > 7){
      this.db.getCep(c).subscribe((data:any) => {
        let cep = data;
         if(cep.erro == true){
           this.app.openAlert("CEP NAO ENCONTRADO");
         }else{
           this.ruaEditar = cep.logradouro.toUpperCase();
           this.bairroEditar = cep.bairro.toUpperCase();
           this.municipioEditar = cep.localidade.toUpperCase();
           this.estadoEditar = cep.uf.toUpperCase();
         }
      });
    }
  }

novaObservacao(obs){
  let dados = {
    data: this.dataHoje,
    conteudo: obs
  }
  this.db.postObservacaoUser(this.idUsuario,dados).subscribe(res => {
    console.log(res);
    this.app.openAlert("Observação Criada com Sucesso!");
    this.newObservacao = "";
    this.observacaoEditar.push(dados);
  }, err => {
    console.log(err);
    this.app.openAlert("Erro ao cadastrar Observação!")
  })
}

copiarCodigoSite(user:any){
  const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = 'https://www.vivamax.com.br/?id='+user._id;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  mudarPermissao(key:any, value:any){
    this.permissoes[key] = value;
  } 

  mudarPermissaoEditar(key:any, value:any){
    this.permissoesEditar[key] = value;
  }

  confereSenha(senha: string): boolean {
    return senha.includes('%') || senha.includes('$') || senha.includes('&') || senha.includes('#');
  }

}