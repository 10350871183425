<div class="modal-screen" id="aviso">
  <div class="inner">
      <div class="topo-modal">
         AVISO
      </div>
      <div class="content-modal">
          <p class="p-2" style="font-weight:500">{{mensagem}}</p>
          <div class="row align-items-center">
              <div class="col col-12 col-md-12">
                  <button class="btn btn-outline-primary btn-block" (click)="closeAlert()">OK</button>
              </div>
          </div>
      </div>
  </div>
</div>
<div (click)="openMenu()" class="iconMenu" *ngIf="auth.isLoggedIn()" [hidden]="screenSelect">
  <img src="../assets/img/logo-icon.png" width="50px">
</div>
<div id="mySidenav" class="sidenav" *ngIf="auth.isLoggedIn()">
  <div class="voltarSticky">
    <span class="closebtn" (click)="closeMenu()">&times;</span>
  </div>
    <div class="logoMenu">
      <img src="../assets/img/logo.png" style="cursor: pointer;" (click)="goTo('inicio')">
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/dashboard'}" (click)="goTo('dashboard')" *ngIf="db?.usuario?.permissoes['dashboard'] == 'EDITAR' || db?.usuario?.permissoes['dashboard'] == 'VER'">
      <fa-icon [icon]="['fas', 'home']"></fa-icon>
      <label>DASHBOARD</label> 
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/associados'}" (click)="goTo('associados')" *ngIf="db?.usuario?.permissoes['associados'] == 'EDITAR' || db?.usuario?.permissoes['associados'] == 'VER'">
      <fa-icon [icon]="['fas', 'users']"></fa-icon>
      <label>ASSOCIADOS</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/relatorios'}" (click)="goTo('relatorios')" *ngIf="db?.usuario?.permissoes['relatorios'] == 'EDITAR' || db?.usuario?.permissoes['relatorios'] == 'VER'">
      <fa-icon [icon]="['fas', 'clipboard-list']"></fa-icon>
      <label>RELATÓRIOS</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/credenciados'}" (click)="goTo('credenciados')" *ngIf="db?.usuario?.permissoes['credenciados'] == 'EDITAR' || db?.usuario?.permissoes['credenciados'] == 'VER'">
      <fa-icon [icon]="['fas', 'hospital-user']"></fa-icon>
      <label>CREDENCIADOS</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/colaboradores'}" (click)="goTo('colaboradores')" *ngIf="db?.usuario?.permissoes['colaboradores'] == 'EDITAR' || db?.usuario?.permissoes['colaboradores'] == 'VER'">
      <fa-icon [icon]="['fas', 'user-lock']"></fa-icon>
      <label>COLABORADORES</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/empresas'}" (click)="goTo('empresas')" *ngIf="db?.usuario?.permissoes['empresas'] == 'EDITAR' || db?.usuario?.permissoes['empresas'] == 'VER'">
      <fa-icon [icon]="['fas', 'warehouse']"></fa-icon>
      <label>EMPRESAS</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/planos'}" (click)="goTo('planos')" *ngIf="db?.usuario?.permissoes['planos'] == 'EDITAR' || db?.usuario?.permissoes['planos'] == 'VER'">
      <fa-icon [icon]="['fas', 'book-medical']"></fa-icon>
      <label>PLANOS</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/sub-contratos'}" (click)="goTo('sub-contratos')" *ngIf="db?.usuario?.permissoes['contratos'] == 'EDITAR' || db?.usuario?.permissoes['contratos'] == 'VER'">
      <fa-icon [icon]="['fas', 'city']"></fa-icon>
      <label>SUB-CONTRATOS</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/controle-vendas'}" (click)="goTo('controle-vendas')" *ngIf="db?.usuario?.permissoes['vendas'] == 'EDITAR' || db?.usuario?.permissoes['vendas'] == 'VER'">
      <fa-icon [icon]="['fas', 'list']"></fa-icon>
      <label>CONTROLE<br>DE VENDAS</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/relatorios-financeiro'}" (click)="goTo('relatorios-financeiro')" *ngIf="db?.usuario?.permissoes['caixa'] == 'EDITAR' || db?.usuario?.permissoes['caixa'] == 'VER'">
      <fa-icon [icon]="['fas', 'clipboard-list']"></fa-icon>
      <label>RELATÓRIOS<br>FINANCEIROS</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/centro-custo'}" (click)="goTo('centro-custo')" *ngIf="db?.usuario?.permissoes['centroCusto'] == 'EDITAR' || db?.usuario?.permissoes['centroCusto'] == 'VER'">
      <fa-icon [icon]="['fas', 'sitemap']"></fa-icon>
      <label>CENTROS DE CUSTO</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/faturamento'}" (click)="goTo('faturamento')" *ngIf="db?.usuario?.permissoes['faturamento'] == 'EDITAR' || db?.usuario?.permissoes['faturamento'] == 'VER'">
      <fa-icon [icon]="['fas', 'indent']"></fa-icon>
      <label>FATURAMENTO</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/fornecedores'}" (click)="goTo('fornecedores')" *ngIf="db?.usuario?.permissoes['fornecedores'] == 'EDITAR' || db?.usuario?.permissoes['fornecedores'] == 'VER'">
      <fa-icon [icon]="['fas', 'cubes']"></fa-icon>
      <label>FORNECEDORES</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/caixa'}" (click)="goTo('caixa')" *ngIf="db?.usuario?.permissoes['caixa'] == 'EDITAR' || db?.usuario?.permissoes['caixa'] == 'VER'">
      <fa-icon [icon]="['fas', 'file-invoice-dollar']"></fa-icon>
      <label>FLUXO CAIXA</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/contas-pagar'}" (click)="goTo('contas-pagar')" *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR' || db?.usuario?.permissoes['financeiro'] == 'VER'">
      <fa-icon [icon]="['fas', 'money-check-alt']"></fa-icon>
      <label>CONTAS PAGAR</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/contas-receber'}" (click)="goTo('contas-receber')" *ngIf="db?.usuario?.permissoes['financeiro'] == 'EDITAR' || db?.usuario?.permissoes['financeiro'] == 'VER'">
      <fa-icon [icon]="['fas', 'hand-holding-usd']"></fa-icon>
      <label>CONTAS RECEBER</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/programacao'}" (click)="goTo('programacao')" *ngIf="db?.usuario?.permissoes['programacao'] == 'EDITAR' || db?.usuario?.permissoes['programacao'] == 'VER'">
      <fa-icon [icon]="['fas', 'calendar']"></fa-icon>
      <label>PROGRAMAÇÃO<br>ORÇAMENTÁRIA</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/tarefas'}" (click)="goTo('tarefas')" *ngIf="db?.usuario?.permissoes['tarefas'] == 'EDITAR' || db?.usuario?.permissoes['tarefas'] == 'VER'">
      <fa-icon [icon]="['fas', 'tasks']"></fa-icon>
      <label>TAREFAS</label>
    </div>
    <accordion [isAnimated]="true" *ngIf="db?.usuario?.permissoes['comissoes'] == 'EDITAR' || db?.usuario?.permissoes['comissoes'] == 'VER'">
      <accordion-group [panelClass]="accord">
          <span accordion-heading>
            <fa-icon [icon]="['fas', 'chart-pie']"></fa-icon><label>COMISSÕES</label>
          </span>
          <span accordion-item>
            <div class="btnMenu" (click)="goTo('comissoes')">
              <label>GERAR COMISSÃO</label>
            </div>
            <div class="btnMenu" (click)="goTo('relatorio-comissoes')">
              <label>RELATÓRIO COMISSÕES</label>
            </div> 
          </span>
      </accordion-group>
    </accordion>
    <div class="btnMenu" [ngClass]="{'active': url == '/marketing'}" (click)="goTo('marketing')" *ngIf="db?.usuario?.permissoes['marketing'] == 'EDITAR' || db?.usuario?.permissoes['marketing'] == 'VER'">
      <fa-icon [icon]="['fas', 'bullhorn']"></fa-icon>
      <label>MARKETING</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/logs'}" (click)="goTo('logs')" *ngIf="db?.usuario?.permissoes['logs'] == 'EDITAR' || db?.usuario?.permissoes['logs'] == 'VER'">
      <fa-icon [icon]="['fas', 'clipboard-check']"></fa-icon>
      <label>LOGS</label>
    </div>
    <div class="btnMenu" [ngClass]="{'active': url == '/configuracoes'}" (click)="goTo('configuracoes')" *ngIf="db?.usuario?.permissoes['configuracoes'] == 'EDITAR' || db?.usuario?.permissoes['configuracoes'] == 'VER'">
      <fa-icon [icon]="['fas', 'cog']"></fa-icon>
      <label>CONFIGURAÇÕES</label>
    </div>
  <!--   <div class="btnMenu" [ngClass]="{'active': url == '/celesc'}" (click)="goTo('celesc')">
      <fa-icon [icon]="['fas', 'lightbulb']"></fa-icon>
      <label>CELESC</label>
    </div> -->
  <br>
  <div style="text-align: center; width: 100%; font-size: 0.7em; color:darkgray">
    <p>Olá {{nomeUsuario}}</p>
    V. 3.9.7 - 26/09/2024
  </div>
  <br>
  <div style="text-align: center; width: 100%;">
    <button class="btn btn-light" (click)="auth.logOut();closeMenu()">  
      <fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>&nbsp;SAIR
    </button>
  </div>
  <br><br>
</div>
<ng-http-loader></ng-http-loader>
<router-outlet id="main">
  <div class="alertBox danger slide-top" id="alertaFaturamento">
      O Sistema possui {{faturamentoHoje}} Contas para Faturar!
      <div style="height: 50px;"></div>
      <button class="btn btn-outline-light" (click)="closeBoxAlert('alertaFaturamento')">ENTENDI</button>
  </div>
  <div class="alertBox danger slide-top" id="alertaContasPagar">
      O Sistema possui {{contasPagarHoje}} Contas Vencidas!
      <br><br>
      <button class="btn btn-outline-light" (click)="closeBoxAlert('alertaContasPagar')">ENTENDI</button>
  </div>
  <div class="alertBox success slide-top" id="alertaContasReceber">
      O Sistema possui {{contasReceberHoje}} Contas Para Receber!
      <br><br>
      <button class="btn btn-outline-dark" (click)="closeBoxAlert('alertaContasReceber')">ENTENDI</button>
  </div>
  <div class="alertBox warning slide-top" id="alertaAgendamento">
      O Sistema possui {{agendamentosHoje}} <span *ngIf="agendamentosHoje > 1"> Agendamentos Hoje!</span><span *ngIf="agendamentosHoje == 1"> Agendamento Hoje!</span>
      <br><br>
      <button class="btn btn-outline-dark" (click)="closeBoxAlert('alertaAgendamento')">ENTENDI</button>
  </div>
  <div class="alertBox danger slide-top" id="alertaTarefasGeral">
        O Sistema possui {{tarefasPendentes}}  <span *ngIf="tarefasPendentes > 1"> Tarefas Pendentes!</span><span *ngIf="tarefasPendentes == 1"> Tarefa Pendente!</span> 
        <br><br>
        <button class="btn btn-outline-light" (click)="closeBoxAlert('alertaTarefasGeral')">ENTENDI</button>
  </div>
  <div class="alertBox danger slide-top" id="alertaTarefas">
      Você possui {{tarefasUsuarioPendente}} <span *ngIf="tarefasUsuarioPendente > 1"> Tarefas Pendentes:</span><span *ngIf="tarefasUsuarioPendente == 1"> Tarefa Pendente:</span> 
      <div id="tarefasLista" class="listaTask"></div>
      <button class="btn btn-outline-light" (click)="closeBoxAlert('alertaTarefas')">ENTENDI</button>
  </div>
  <div class="alertBox success slide-top" id="avisos">
        Tarefas Resolvidas:
        <div id="avisosLista" class="listaTask"></div>
        <button class="btn btn-outline-dark" (click)="closeBoxAviso()">ENTENDI</button>
  </div>
</router-outlet>