import { Component, OnInit } from '@angular/core';
import { DataBaseService } from '../provider.service';
import * as XLSX from 'xlsx';
import { AppComponent } from '../app.component';
import * as moment from 'moment';

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  styleUrls: ['./relatorios.component.css']
})

export class RelatoriosComponent implements OnInit {
  planos:any = [];
  vendedores:any = [];
  relatoriosCancelados:any = [];
  relatorioDependentes:any = [];
  relatorioAgendamentos:any = [];
  relPlanos:boolean = false;
  relSeguros:boolean = false;
  relAgendamentos:boolean = false;
  relAtendimentos:boolean = false;
  relCancelados:boolean = false;
  relSinistros:boolean = false;
  relAniversariantes:boolean = false;
  relCombinados:boolean = false;
  dataInicioCancelado:any = "";
  dataFimCancelado:any = "";
  dataInicioSolicitacaoCancelado:any = "";
  dataFimSolicitacaoCancelado:any = "";
  vendedorCancelamento:any = "";
  matriculaCancelado:any = null;
  relatorio:any = [];
  dataInicioPlano:any = "";
  dataFimPlano:any = "";
  cidadePlano:any = "";
  cidadeCancelado:any = "";
  subContratoCancelado:any = "";
  nomePlano:any = "";
  excetoPlano:any = "NENHUM";
  vendedorPlano:any = "TODOS";
  situacaoPlano:any = "TODOS";
  situacaoPlanoComb:any = "TODOS";
  tipoPlano:any = "";
  dataSeguradoInicio:any = "";
  dataSeguradoFim:any = "";
  nomePlanoSeguros:any = "";
  tipoSegurado:any = "TITULAR";
  tipoPlanoSegurado:any = "";
  planosSegurado:any = [];
  totalCombinados:number = 0;
  sexo:any = "";
  subContrato:any = "";
  taxaAdesao:any = null;
  profissao:any = "";
  empresaPlano:any = "";
  planosGerais:any = [];
  subContratos:any = [];
  empresaSelect:boolean = false;
  cadastroSelect:boolean = false;
  adesaoSelect:boolean = false;
  matriculaSelect:boolean = false;
  cpfSelect:boolean = false;
  titularSelect:boolean = false;
  cidadeSelect:boolean = false;
  contratoSelect:boolean = false;
  mensalidadeSelect:boolean = false;
  totalMensalidadeSelect:boolean = false;
  emailSelect:boolean = false;
  planoSelect:boolean = false;
  vendedorSelect:boolean = false;
  idadeSelect:boolean = false;
  telefoneSelect:boolean = false;
  enderecoSelect:boolean = false;
  sexoSelect:boolean = false;
  situacaoSelect:boolean = false;
  taxaAdesaoSelect:boolean = false;
  dataCancelamentoSelect:boolean = false;
  dataNascimentoSelect:boolean = false;
  motivoCancelamentoSelect:boolean = false;
  motivoInadimplenteSelect:boolean = false;
  motivoPendenciaSelect:boolean = false;
  observacaoPendenciaSelect:boolean = false;
  diasPendentesSelect:boolean = false;
  afastadoDesdeSelect:boolean = false;
  formaPagamentoSelect:boolean = false;
  localTrabalhoSelect:boolean = false;
  origemSelect:boolean = false;
  dataInicioAgendamento:any = "";
  dataFimAgendamento:any = "";
  dataInicioProcedimentoAgendamento:any = "";
  dataFimProcedimentoAgendamento:any = "";
  matriculaAgendamento:number;
  medicoAgendamento:any = "";
  cidadeAgendamento:any = "";
  pagamentoAgendamento:any = "";
  valorAgendamento:number;
  pacienteAgendamento:any = "";
  dataInicioSinistro:any = "";
  dataFimSinistro:any = "";
  matriculaSinistro:number;
  sinistradoSinistro:any = "";
  situacaoSinistro:any = "";
  tipoSinistro:any = "";
  servicoSinistro:boolean = false;
  indenizacaoSinistro:boolean = false;
  assistenciaSinistro:boolean = false;
  dataAniversario:any = "";
  msgParabens:any = "";
  mensalidadeTotal:number = 0;
  mensalidadeVivamax:number = 0;
  mensalidadeConecta:number = 0;
  semAssinatura:any = "TODOS";
  semAutorizacao:any = "TODOS";
  relatorioBackup:any = [];
  dataInicioAtendimento:any = "";
  dataFimAtendimento:any = "";
  motivoAtendimento:any = [""];
  relatorioAtendimentos:any = [];
  titularCheck:boolean = false;
  dataNascimento:any = "";
  cancelamentoAba:boolean = false;
  motivosCancelamento:any = [];
  motivoCancelamento:any = "";
  motivosPendencia:any = [];
  motivoPendencia:any = "";
  motivosInadimplente:any = [];
  motivoInadimplente:any = "";
  mesPagamento:any = "";
  formaPagamento:any = "";
  empresa:any = "";
  empresas:any = [];
  dataInicioComb:any = "";
  dataFimComb:any = "";
  titularComb:any = "";
  tipoPlanoComb:any = "";
  subContratoComb:any = "";
  empresaComb:any = "";
  planoAtendimento:any = "";
  contratoAtendimento:any = "";
  empresaAtendimento:any = "";
  valorPersonalizado:any = "TODOS";
  marcadas:any = "TODOS";

  constructor(public db:DataBaseService, public app:AppComponent) { }

ngOnInit(){
  this.db.getTodosPlanos().subscribe((data:any) => {
    this.planos = data.sort((a,b)=>{if(a.nome < b.nome){return -1}});
    this.db.getVendedoresAtivos().subscribe((x:any) => {
      x.map((v:any) => {
        if(v.nome == ''){
          v.nome = v.fantasiaEmpresa;
        }
      })
      this.vendedores = x.sort((a:any,b:any) => {if(a.nome < b.nome)return -1});
      this.db.getSubContratos().subscribe((s:any) => {
        this.subContratos = s.sort((a,b)=>{if(a.nome < b.nome){return -1}});;
        this.db.getConfigs().subscribe((z:any) => {
          this.msgParabens = z[2].msgAniversario;
          this.motivosCancelamento = z[13].motivosCancelamento;
          this.motivosPendencia = z[11].motivosPendencia;
          this.motivosInadimplente = z[12].inadimplencias;
          this.db.getEmpresas().subscribe((emp:any) => {
            this.empresas = emp.sort((a,b)=>{if(a.fantasiaEmpresa < b.fantasiaEmpresa){return -1}});;
          })
        });
      });
    });
  });
}

  closeAll(){
    this.relatorio = [];
    this.relPlanos = false;
    this.relSeguros = false;
    this.relatorioAgendamentos = false;
    this.relAgendamentos = false;
    this.relAtendimentos = false;
    this.relSinistros = false;
    this.relAniversariantes = false;
    this.relCancelados = false;
    this.relCombinados = false;
  }

  showPlanos(){
    this.closeAll();
    this.relPlanos = true;
  }

  showSeguros(){
    this.closeAll();
    this.relSeguros = true;
  }

  showAgendamentos(){
    this.closeAll();
    this.relAgendamentos = true;
  }

  showAtendimentos(){
    this.closeAll();
    this.relAtendimentos = true;
  }

  showSinistros(){
    this.closeAll();
    this.relSinistros = true;
  }

  showAniversariantes(){
    this.closeAll();
    this.relAniversariantes = true;
  }

  showCombinados(){
    this.closeAll();
    this.relCombinados = true;
  }

  showCancelados(){
    this.closeAll();
    this.relCancelados = true;
  }

  limparCamposPlanos(){
    this.dataInicioPlano = "";
    this.dataFimPlano = "";
    this.cidadePlano = "";
    this.nomePlano=  "";
    this.excetoPlano = "NENHUM";
    this.vendedorPlano = "TODOS";
    this.situacaoPlano = "TODOS";
    this.tipoPlano = "";
    this.sexo = "";
    this.taxaAdesao = null;
    this.profissao = "";
    this.subContrato = "";
  }

  buscarPlanos(){
    this.relatorio = [];
    let situacaoPlanos = "";
    let vendors = "";
    let exceto:any;
    let subContrato = "";
    let codEmpresa = "";
    if(this.subContrato){
      subContrato = this.subContrato.nome;
    }
    if(this.empresa){
      codEmpresa = this.empresa.codigo;
    }
    if(this.excetoPlano != "NENHUM"){
      exceto = "SIM"
    }else{
      exceto = "NÃO"
    }
    if(this.situacaoPlano == "TODOS"){
      situacaoPlanos = "";
    }else{
      situacaoPlanos = this.situacaoPlano;
    }
    if(this.vendedorPlano == "TODOS"){
      vendors = "";
    }else{
      vendors = this.vendedorPlano;
    }
    this.db.getAssociadosByPlanos(this.valorPersonalizado,this.empresaPlano,this.tipoPlano,exceto,this.dataInicioPlano,this.dataFimPlano,this.cidadePlano,this.nomePlano,this.excetoPlano,vendors,situacaoPlanos,this.sexo, this.taxaAdesao, subContrato, this.profissao,this.dataNascimento,this.motivoPendencia,this.motivoInadimplente,this.mesPagamento,this.formaPagamento,codEmpresa).subscribe((data:any) => {
      this.relatorioBackup = data;
      this.semAssinatura = "TODOS";
      this.semAutorizacao = "TODOS";
      this.mensalidadeTotal = 0;
      this.mensalidadeVivamax = 0;
      this.mensalidadeConecta = 0;
      let ctrl:number = 1;
      data.forEach((item:any) => {
        if(item.totalMensalidade){
          this.mensalidadeTotal += item.totalMensalidade;
          if(item.empresa == "VIVA MAX"){
            this.mensalidadeVivamax += item.totalMensalidade;
          }
          if(item.empresa == "CONECTA"){
            this.mensalidadeConecta += item.totalMensalidade;
          }
        }
        if(item.nascimento){
          item.idade = this.idade(item.nascimento.split('-')[0],item.nascimento.split('-')[1],item.nascimento.split('-')[2])
        }
        this.relatorio.push(item);
        if(ctrl == data.length){
          if(this.diasPendentesSelect){
            this.getPendentes();
          }
        }else{
          ctrl++;
        }
      });
    })
  }

  idade(ano_aniversario:any, mes_aniversario:any, dia_aniversario:any){
    let d = new Date;
        let ano_atual = d.getFullYear();
        let mes_atual = d.getMonth() + 1;
        let dia_atual = d.getDate();
        ano_aniversario = +ano_aniversario;
        mes_aniversario = +mes_aniversario;
        dia_aniversario = +dia_aniversario;
        let quantos_anos = ano_atual - ano_aniversario;

    if (mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario) {
        quantos_anos--;
    }
    return quantos_anos < 0 ? 0 : quantos_anos;
}

  getPendentes(){
    this.relatorio = [];
    this.relatorioBackup = [];
    let associados = [];
    let ctrl = 1;
    let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.db.getAssociadosPendentes().subscribe((data:any) => {
      console.log("aaa")
      if(data && data.length > 0){
        data.forEach((a:any) => {
          let date1 = new Date(a.data);
          let date2 = new Date(dataHoje);
          let timeDiff = Math.abs(date2.getTime() - date1.getTime());
          let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
          a.diasPendentes = diffDays;
          associados.push(a);
          if(ctrl == data.length){
            this.relatorio = associados;
            this.relatorioBackup = associados;
          }else{
            ctrl++;
          }
        })
      }
    })
  }

  selectAssinatura(){
    if(this.semAssinatura == "TODOS"){
      this.buscarPlanos();
    }
    if(this.semAssinatura == "SEM"){
     this.relatorio = this.relatorioBackup.filter(item => {
      return item.semAssinatura == true;
    });
    }
    if(this.semAssinatura == "COM"){
      this.relatorio = this.relatorioBackup.filter(item => {
        return item.semAssinatura == false || !item.semAssinatura;
      });
    }
  }

  selectAutorizacao(){
    if(this.semAutorizacao == "TODOS"){
      this.buscarPlanos();
    }
    if(this.semAutorizacao == "SEM"){
     this.relatorio = this.relatorioBackup.filter(item => {
      return item.semAutorizacao == true;
    });
    }
    if(this.semAutorizacao == "COM"){
      this.relatorio = this.relatorioBackup.filter(item => {
        return item.semAutorizacao == false || !item.semAutorizacao;
      });
    }
  }

  selectTipoPlanoSegurado(){
    this.planosSegurado = [];
    this.nomePlanoSeguros = [];
      this.planos.forEach((item) => {
        if(item.tipo == this.tipoPlanoSegurado){
          this.planosSegurado.push(item);
        }
       })
  }

  selectTipoPlanoGeral(){
    this.planosGerais = [];
    this.nomePlano = [];
    if(this.tipoPlano == "TODOS"){
      this.planosGerais = this.planos;
    }else{
      this.planos.forEach((item) => {
        if(item.tipo == this.tipoPlano){
          this.planosGerais.push(item);
        }
       })
    }
  }
 
  buscarSegurados(){
    this.relatorio = [];
    this.relatorioDependentes = [];
    let dependentesASF = [];
    this.db.getAssociadosByDataPlano(this.dataSeguradoInicio,this.dataSeguradoFim,this.nomePlanoSeguros,this.tipoPlanoSegurado).subscribe((data:any) => {
      data.forEach((item:any) => {
        let control = 1;    
        let idx = this.relatorio.map((m:any) => {
          return m.cpf;
        }).indexOf(item.cpf);
        if(idx >= 0){
          if(item.empresa == "SEGUROS"){
            this.relatorio[idx].valorCapitalSegurado += item.valorCapitalSegurado;
          }else{
            this.relatorio[idx].valorCapitalSegurado += item.valorCapitalSegurado;
            if(item.dependentes && item.dependentes.length > 0){
              item.dependentes.forEach((dependente,i) => {
                if(dependente.asf == 'FUNERAL CONTRATADO' && dependente.situacao == 'ATIVO'){
                  dependente.matricula = item.matricula+i.toString();
                  dependente.sexoTitular = item.sexo;
                  dependente.dataAdesao = item.dataAdesao;
                  if(item.valorCapitalSegurado){
                    dependente.valorCapitalSegurado = item.valorCapitalSegurado;
                  }else{
                    dependente.valorCapitalSegurado = 0;
                  }
                  if(this.marcadas == "TODOS"){
                    dependentesASF.push(dependente);
                  }else{
                    if(this.marcadas == "marcadas" && dependente.corretora == true){
                      dependentesASF.push(dependente);
                    }
                    if(this.marcadas == "nao" && (dependente.corretora == null || dependente.corretora == false)){
                      dependentesASF.push(dependente);
                    }
                  }
                }
                if(item.dependentes.length == control){
                  if(item.dependentesExtra && item.dependentesExtra.length > 0){
                  let controlExtra = 1;
                  item.dependentesExtra.forEach((dependenteExtra,i) => {
                    if(dependenteExtra.asf == 'FUNERAL CONTRATADO' && dependenteExtra.situacao == 'ATIVO' && !item.plano.match('MASTER')){
                      dependenteExtra.matricula = item.matricula+(item.dependentes.length+i).toString();
                      dependenteExtra.sexoTitular = item.sexo;
                      dependenteExtra.dataAdesao = item.dataAdesao;
                      if(item.valorCapitalSegurado){
                        dependenteExtra.valorCapitalSegurado = item.valorCapitalSegurado;
                      }else{
                        dependenteExtra.valorCapitalSegurado = 0;
                      }
                      if(this.marcadas == "TODOS"){
                        dependentesASF.push(dependenteExtra);
                      }else{
                        if(this.marcadas == "marcadas" && dependenteExtra.corretora == true){
                          dependentesASF.push(dependenteExtra);
                        }
                        if(this.marcadas == "nao" && (dependenteExtra.corretora == null || dependenteExtra.corretora == false)){
                          dependentesASF.push(dependenteExtra);
                        }
                      }
                    }
                    if(item.dependentesExtra.length == controlExtra){
                      this.relatorioDependentes = dependentesASF;
                    }else{
                      controlExtra++;
                    }
                  })
                }else{
                  this.relatorioDependentes = dependentesASF;
                }
                }else{
                  control++
                }
              })
            }else{
              if(item.dependentesExtra && item.dependentesExtra.length > 0){
                let controlExtra = 1;
                item.dependentesExtra.forEach((dependenteExtra,i) => {
                  if(dependenteExtra.asf == 'FUNERAL CONTRATADO' && dependenteExtra.situacao == 'ATIVO' && !item.plano.match('MASTER')){
                    dependenteExtra.matricula = item.matricula+i.toString();
                    dependenteExtra.sexoTitular = item.sexo;
                    dependenteExtra.dataAdesao = item.dataAdesao;
                    if(item.valorCapitalSegurado){
                      dependenteExtra.valorCapitalSegurado = item.valorCapitalSegurado;
                    }else{
                      dependenteExtra.valorCapitalSegurado = 0;
                    }
                    if(this.marcadas == "TODOS"){
                      dependentesASF.push(dependenteExtra);
                    }else{
                      if(this.marcadas == "marcadas" && dependenteExtra.corretora == true){
                        dependentesASF.push(dependenteExtra);
                      }
                      if(this.marcadas == "nao" && (dependenteExtra.corretora == null || dependenteExtra.corretora == false)){
                        dependentesASF.push(dependenteExtra);
                      }
                    }
                  }
                  if(item.dependentesExtra.length == controlExtra){
                    this.relatorioDependentes = dependentesASF;
                  }else{
                    controlExtra++
                  }
                })
              }else{
                this.relatorioDependentes = dependentesASF;
              }
            }
          }
        }else{
          if(item.dependentes && item.dependentes.length > 0){
            item.dependentes.forEach((dependente,i) => {
              if(dependente.asf == 'FUNERAL CONTRATADO' && dependente.situacao == 'ATIVO'){
                dependente.matricula = item.matricula+i.toString();
                dependente.sexoTitular = item.sexo;
                dependente.dataAdesao = item.dataAdesao;
                if(item.valorCapitalSegurado){
                  dependente.valorCapitalSegurado = item.valorCapitalSegurado;
                }else{
                  dependente.valorCapitalSegurado = 0;
                }
                if(this.marcadas == "TODOS"){
                  dependentesASF.push(dependente);
                }else{
                  if(this.marcadas == "marcadas" && dependente.corretora == true){
                    dependentesASF.push(dependente);
                  }
                  if(this.marcadas == "nao" && (dependente.corretora == null || dependente.corretora == false)){
                    dependentesASF.push(dependente);
                  }
                }
              }
              if(item.dependentes.length == control){
                if(item.dependentesExtra && item.dependentesExtra.length > 0){
                let controlExtra = 1;
                item.dependentesExtra.forEach((dependenteExtra,i) => {
                  if(dependenteExtra.asf == 'FUNERAL CONTRATADO' && dependenteExtra.situacao == 'ATIVO' && !item.plano.match('MASTER')){
                    dependenteExtra.matricula =  item.matricula+(item.dependentes.length+i).toString();
                    dependenteExtra.sexoTitular = item.sexo;
                    dependenteExtra.dataAdesao = item.dataAdesao;
                    if(item.valorCapitalSegurado){
                      dependenteExtra.valorCapitalSegurado = item.valorCapitalSegurado;
                    }else{
                      dependenteExtra.valorCapitalSegurado = 0;
                    }
                    if(this.marcadas == "TODOS"){
                      dependentesASF.push(dependenteExtra);
                    }else{
                      if(this.marcadas == "marcadas" && dependenteExtra.corretora == true){
                        dependentesASF.push(dependenteExtra);
                      }
                      if(this.marcadas == "nao" && (dependenteExtra.corretora == null || dependenteExtra.corretora == false)){
                        dependentesASF.push(dependenteExtra);
                      }
                    }
                  }
                  if(item.dependentesExtra.length == controlExtra){
                    this.relatorio.push(item);
                    this.relatorioDependentes = dependentesASF;
                  }else{
                    controlExtra++;
                  }
                })
              }else{
                this.relatorio.push(item);
                this.relatorioDependentes = dependentesASF;
              }
              }else{
                control++
              }
            })
          }else{
            if(item.dependentesExtra && item.dependentesExtra.length > 0){
              let controlExtra = 1;
              item.dependentesExtra.forEach((dependenteExtra,i) => {
                if(dependenteExtra.asf == 'FUNERAL CONTRATADO' && dependenteExtra.situacao == 'ATIVO' && !item.plano.match('MASTER')){
                  dependenteExtra.matricula = item.matricula+i.toString();
                  dependenteExtra.sexoTitular = item.sexo;
                  dependenteExtra.dataAdesao = item.dataAdesao;
                  if(item.valorCapitalSegurado){
                    dependenteExtra.valorCapitalSegurado = item.valorCapitalSegurado;
                  }else{
                    dependenteExtra.valorCapitalSegurado = 0;
                  }
                  if(this.marcadas == "TODOS"){
                    dependentesASF.push(dependenteExtra);
                  }else{
                    if(this.marcadas == "marcadas" && dependenteExtra.corretora == true){
                      dependentesASF.push(dependenteExtra);
                    }
                    if(this.marcadas == "nao" && (dependenteExtra.corretora == null || dependenteExtra.corretora == false)){
                      dependentesASF.push(dependenteExtra);
                    }
                  }
                }
                if(item.dependentesExtra.length == controlExtra){
                  this.relatorio.push(item);
                  this.relatorioDependentes = dependentesASF;
                }else{
                  controlExtra++
                }
              })
            }else{
              this.relatorio.push(item);
              this.relatorioDependentes = dependentesASF;
            }
          }
        }
      })
    })
  }

  /* buscarSegurados(){
    this.relatorio = [];
    this.relatorioDependentes = [];
    let dependentesASF = [];
    this.db.getAssociadosByDataPlano(this.dataSeguradoInicio,this.dataSeguradoFim,this.nomePlanoSeguros,this.tipoPlanoSegurado).subscribe((data:any) => {
      data.forEach((item:any) => {
        let control = 1;     
        if(item.dependentes && item.dependentes.length > 0){
          item.dependentes.forEach((dependente) => {
            if(dependente.asf == 'FUNERAL CONTRATADO' && dependente.situacao == 'ATIVO'){
              dependente.sexoTitular = item.sexo;
              dependente.dataAdesao = item.dataAdesao;
              if(item.valorCapitalSegurado){
                dependente.valorCapitalSegurado = item.valorCapitalSegurado;
              }else{
                dependente.valorCapitalSegurado = 0;
              }
              if(this.marcadas == "TODOS"){
                dependentesASF.push(dependente);
              }else{
                if(this.marcadas == "marcadas" && dependente.corretora == true){
                  dependentesASF.push(dependente);
                }
                if(this.marcadas == "nao" && (dependente.corretora == null || dependente.corretora == false)){
                  dependentesASF.push(dependente);
                }
              }
            }
            if(item.dependentes.length == control){
              if(item.dependentesExtra && item.dependentesExtra.length > 0){
              let controlExtra = 1;
              item.dependentesExtra.forEach((dependenteExtra) => {
                if(dependenteExtra.asf == 'FUNERAL CONTRATADO' && dependenteExtra.situacao == 'ATIVO' && !item.plano.match('MASTER')){
                  dependenteExtra.sexoTitular = item.sexo;
                  dependenteExtra.dataAdesao = item.dataAdesao;
                  if(item.valorCapitalSegurado){
                    dependenteExtra.valorCapitalSegurado = item.valorCapitalSegurado;
                  }else{
                    dependenteExtra.valorCapitalSegurado = 0;
                  }
                  if(this.marcadas == "TODOS"){
                    dependentesASF.push(dependenteExtra);
                  }else{
                    if(this.marcadas == "marcadas" && dependenteExtra.corretora == true){
                      dependentesASF.push(dependenteExtra);
                    }
                    if(this.marcadas == "nao" && (dependenteExtra.corretora == null || dependenteExtra.corretora == false)){
                      dependentesASF.push(dependenteExtra);
                    }
                  }
                }
                if(item.dependentesExtra.length == controlExtra){
                  this.relatorio.push(item);
                  this.relatorioDependentes = dependentesASF;
                }else{
                  controlExtra++;
                }
              })
            }else{
              this.relatorio.push(item);
              this.relatorioDependentes = dependentesASF;
            }
            }else{
              control++
            }
          })
        }else{
          if(item.dependentesExtra && item.dependentesExtra.length > 0){
            let controlExtra = 1;
            item.dependentesExtra.forEach((dependenteExtra) => {
              if(dependenteExtra.asf == 'FUNERAL CONTRATADO' && dependenteExtra.situacao == 'ATIVO' && !item.plano.match('MASTER')){
                dependenteExtra.sexoTitular = item.sexo;
                dependenteExtra.dataAdesao = item.dataAdesao;
                if(item.valorCapitalSegurado){
                  dependenteExtra.valorCapitalSegurado = item.valorCapitalSegurado;
                }else{
                  dependenteExtra.valorCapitalSegurado = 0;
                }
                if(this.marcadas == "TODOS"){
                  dependentesASF.push(dependenteExtra);
                }else{
                  if(this.marcadas == "marcadas" && dependenteExtra.corretora == true){
                    dependentesASF.push(dependenteExtra);
                  }
                  if(this.marcadas == "nao" && (dependenteExtra.corretora == null || dependenteExtra.corretora == false)){
                    dependentesASF.push(dependenteExtra);
                  }
                }
              }
              if(item.dependentesExtra.length == controlExtra){
                this.relatorio.push(item);
                this.relatorioDependentes = dependentesASF;
              }else{
                controlExtra++
              }
            })
          }else{
            this.relatorio.push(item);
            this.relatorioDependentes = dependentesASF;
          }
        }
      })
    })
  } */

  print(){
    var tab = document.getElementById('divToPrintRel');
    var style = "<style>";
        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "</style>";
    var win = window.open('', '', 'height=auto,width=100%');
    win.document.write(style); 
    win.document.write(tab.outerHTML);
    win.document.close();
    win.print();
  }

  printXsl(){
    var ws = XLSX.utils.table_to_sheet(document.getElementById('divToPrintRel'),{raw:true});
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'vivamax.xlsx',{cellStyles:true});
  }

  checkDate(date1, date2, dateCheck){ 
    var d1 = new Date(date1.split('-')[0],date1.split('-')[1]-1,date1.split('-')[2]);
    var d2 = new Date(date2.split('-')[0],date2.split('-')[1]-1,date2.split('-')[2]);
    var d3 = new Date(dateCheck.split('-')[0],dateCheck.split('-')[1]-1,dateCheck.split('-')[2]);
   if(d3 >= d1 && d3 <= d2){ 
       return true;
    }else{ 
       return false;
    } 
} 

  buscarAgendamentos(){
    this.relatorioAgendamentos = [];
    this.db.getAssociadosByAgendamento(this.dataInicioAgendamento,this.dataFimAgendamento,this.matriculaAgendamento,this.valorAgendamento,this.medicoAgendamento,this.cidadeAgendamento,this.pagamentoAgendamento, this.pacienteAgendamento).subscribe((data:any) => {
      this.relatorio = data;
      this.relatorio.forEach((item:any) => {
        if(item.agendamentos.length > 0){
          item.agendamentos.forEach((a:any) => {
          if(this.checkDate(this.dataInicioAgendamento,this.dataFimAgendamento,a.dataAgendamento)){
              a.titular = item.nome;
              a.cidade = item.municipio;
              a.matricula = item.matricula;
              this.relatorioAgendamentos.push(a);
            }
         })
        }
      })
    })
  }

  buscarAgendamentosProcedimento(){
    this.relatorioAgendamentos = [];
    this.db.getAssociadosByProcedimento(this.dataInicioProcedimentoAgendamento,this.dataFimProcedimentoAgendamento,this.matriculaAgendamento,this.valorAgendamento,this.medicoAgendamento,this.cidadeAgendamento,this.pagamentoAgendamento, this.pacienteAgendamento).subscribe((data:any) => {
      this.relatorio = data;
      this.relatorio.forEach((item) => {
        if(item.agendamentos.length > 0){
          item.agendamentos.forEach((agenda) => {
          if(this.checkDate(this.dataInicioProcedimentoAgendamento,this.dataFimProcedimentoAgendamento,agenda.agendadoParaMedico)){
            agenda.titular = item.nome;
            agenda.cidade = item.municipio;
            agenda.matricula = item.matricula;
            this.relatorioAgendamentos.push(agenda);
          }
        })
        }
      })
    })
  }

  changeServicos(){
    this.relatorio = this.relatorioBackup;
    let arr = [];
    if(this.indenizacaoSinistro){
      arr.push("INDENIZAÇÃO")
    }else{
      let i = arr.map((item) => {return item}).indexOf('INDENIZAÇÃO');
      if(i >= 0){
        arr.splice(i,1)
      }
    }
    if(this.assistenciaSinistro){
      arr.push("ASSISTÊNCIA")
    }else{
      let id = arr.map((item) => {return item}).indexOf('ASSISTÊNCIA');
      if(id >= 0){
        arr.splice(id,1)
      }
    }
    if(this.servicoSinistro){
      arr.push("SERVIÇO")
    }else{
      let idx = arr.map((item) => {return item}).indexOf('SERVIÇO');
      if(idx >= 0){
        arr.splice(idx,1)
      }
    }
    if(arr.length > 0){
      this.relatorio = this.relatorioBackup.filter(array => array.servico.some(item => arr.includes(item)));
    }
  }

buscarSinistros(){
  this.relatorio = [];
  this.relatorioBackup = [];
  if(this.tipoSinistro != "" && this.tipoSinistro == "TITULAR"){
    this.db.getAssociadosBySinistroTitular(this.dataInicioSinistro,this.dataFimSinistro,this.matriculaSinistro,this.sinistradoSinistro,this.situacaoSinistro).subscribe((dt:any) => {
      let relatorio = dt;
      relatorio.forEach((titular) => {
        let arrayServicos = [];
        if(titular.sinistro != ""){
          if(this.checkDate(this.dataInicioSinistro,this.dataFimSinistro,titular.sinistro.dataSinistro)){
            titular.sinistro.matricula = titular.matricula;
            titular.sinistro.sinistrado = titular.nome;
            titular.sinistro.tipo = "TITULAR";
            if(titular.sinistro.assistenciaSinistro == true){
              arrayServicos.push("ASSISTÊNCIA")
            }
            if(titular.sinistro.indenizacaoSinistro == true){
              arrayServicos.push("INDENIZAÇÃO")
            }
            if(titular.sinistro.servicosSinistro == true){
              arrayServicos.push("SERVIÇOS")
            }
            titular.sinistro.servico = arrayServicos;
            this.relatorio.push(titular.sinistro);
            this.relatorioBackup.push(titular.sinistro);
            this.relatorio.sort((a,b) => {
              if(a.matricula < b.matricula){
                return -1;
              }else{
                return 1;
              }
            })
          }
        }
      })
  })
  }
  if(this.tipoSinistro == ""){
    this.db.getAssociadosBySinistroTitular(this.dataInicioSinistro,this.dataFimSinistro,this.matriculaSinistro,this.sinistradoSinistro,this.situacaoSinistro).subscribe((dt:any) => {
      let relatorio = dt;
      relatorio.forEach((titular) => {
        let arrayServicos = [];
        if(titular.sinistro != ""){
          if(this.checkDate(this.dataInicioSinistro,this.dataFimSinistro,titular.sinistro.dataSinistro)){
            titular.sinistro.matricula = titular.matricula;
            titular.sinistro.sinistrado = titular.nome;
            titular.sinistro.tipo = "TITULAR";
            if(titular.sinistro.assistenciaSinistro == true){
              arrayServicos.push("ASSISTÊNCIA")
            }
            if(titular.sinistro.indenizacaoSinistro == true){
              arrayServicos.push("INDENIZAÇÃO")
            }
            if(titular.sinistro.servicosSinistro == true){
              arrayServicos.push("SERVIÇOS")
            }
            titular.sinistro.servico = arrayServicos;
            this.relatorio.push(titular.sinistro);
            this.relatorioBackup.push(titular.sinistro);
            this.relatorio.sort((a,b) => {
              if(a.matricula < b.matricula){
                return -1;
              }else{
                return 1;
              }
            })
          }
        }
      })
    this.db.getAssociadosBySinistroDependente(this.dataInicioSinistro,this.dataFimSinistro,this.matriculaSinistro,this.sinistradoSinistro,this.situacaoSinistro,this.tipoSinistro).subscribe((data:any) => {
      let relatorioDependentes = data;
      relatorioDependentes.forEach((dependente) => {
        let arrServDep = [];
        if(dependente.dependentes.length > 0){
          dependente.dependentes.forEach((dpt) => {
            if(dpt.sinistro){
              if(this.checkDate(this.dataInicioSinistro,this.dataFimSinistro,dpt.sinistro.dataSinistro)){
                if(dpt.sinistro.assistenciaSinistro == true){
                  arrServDep.push("ASSISTÊNCIA")
                }
                if(dpt.sinistro.indenizacaoSinistro == true){
                  arrServDep.push("INDENIZAÇÃO")
                }
                if(dpt.sinistro.servicosSinistro == true){
                  arrServDep.push("SERVIÇOS")
                }
                let objDep = {
                  matricula:dependente.matricula,
                  sinistrado: dpt.nome,
                  situacao: dpt.sinistro.situacao,
                  tipo: dpt.tipo,
                  servico: arrServDep
                }
                this.relatorio.push(objDep);
                this.relatorioBackup.push(objDep);
                this.relatorio.sort((a,b) => {
                  if(a.matricula < b.matricula){
                    return -1;
                  }else{
                    return 1;
                  }
                })
              }
             }
          })
        }
      })
     this.db.getAssociadosBySinistroDependenteExtra(this.dataInicioSinistro,this.dataFimSinistro,this.matriculaSinistro,this.sinistradoSinistro,this.situacaoSinistro,this.tipoSinistro).subscribe((dataExtra:any) => {
      let relatorioDependentesExtras = dataExtra;
      relatorioDependentesExtras.forEach((dependenteExtra) => {
        let arrServExtras = [];
        if(dependenteExtra.dependentesExtra.length > 0){
          dependenteExtra.dependentesExtra.forEach((dptExtra) => {
            if(dptExtra.sinistro){
            if(this.checkDate(this.dataInicioSinistro,this.dataFimSinistro,dptExtra.sinistro.dataSinistro)){
              if(dptExtra.sinistro.assistenciaSinistro == true){
                arrServExtras.push("ASSISTÊNCIA")
              }
              if(dptExtra.sinistro.indenizacaoSinistro == true){
                arrServExtras.push("INDENIZAÇÃO")
              }
              if(dptExtra.sinistro.servicosSinistro == true){
                arrServExtras.push("SERVIÇOS")
              }
              let objDepExtra = {
                matricula:dependenteExtra.matricula,
                sinistrado: dptExtra.nome,
                situacao: dptExtra.sinistro.situacao,
                tipo: dptExtra.tipo,
                servico: arrServExtras
              }
              this.relatorio.push(objDepExtra);
              this.relatorioBackup.push(objDepExtra);
              this.relatorio.sort((a,b) => {
                if(a.matricula < b.matricula){
                  return -1;
                }else{
                  return 1;
                }
              })
            }}
          })
        }
      })
     })
    })
  })
  }
  if(this.tipoSinistro != "" && this.tipoSinistro != "TITULAR"){
    this.db.getAssociadosBySinistroDependente(this.dataInicioSinistro,this.dataFimSinistro,this.matriculaSinistro,this.sinistradoSinistro,this.situacaoSinistro,this.tipoSinistro).subscribe((data:any) => {
      let relatorioDependentes = data;
      relatorioDependentes.forEach((dependente) => {
        let arrServDep = [];
        if(dependente.dependentes.length > 0){
          dependente.dependentes.forEach((dpt) => {
            if(dpt.sinistro && ((this.tipoSinistro != "" && dpt.tipo == this.tipoSinistro) || this.tipoSinistro == "")){
              if(this.checkDate(this.dataInicioSinistro,this.dataFimSinistro,dpt.sinistro.dataSinistro)){
                if(dpt.sinistro.assistenciaSinistro == true){
                  arrServDep.push("ASSISTÊNCIA")
                }
                if(dpt.sinistro.indenizacaoSinistro == true){
                  arrServDep.push("INDENIZAÇÃO")
                }
                if(dpt.sinistro.servicosSinistro == true){
                  arrServDep.push("SERVIÇOS")
                }
                let objDep = {
                  matricula:dependente.matricula,
                  sinistrado: dpt.nome,
                  situacao: dpt.sinistro.situacao,
                  tipo: dpt.tipo,
                  servico: arrServDep
                }
                this.relatorio.push(objDep);
                this.relatorioBackup.push(objDep);
                this.relatorio.sort((a,b) => {
                  if(a.matricula < b.matricula){
                    return -1;
                  }else{
                    return 1;
                  }
                })
              }
             }
          })
        }
      })
     this.db.getAssociadosBySinistroDependenteExtra(this.dataInicioSinistro,this.dataFimSinistro,this.matriculaSinistro,this.sinistradoSinistro,this.situacaoSinistro,this.tipoSinistro).subscribe((dataExtra:any) => {
      let relatorioDependentesExtras = dataExtra;
      relatorioDependentesExtras.forEach((dependenteExtra) => {
        let arrServExtras = [];
        if(dependenteExtra.dependentesExtra.length > 0){
          dependenteExtra.dependentesExtra.forEach((dptExtra) => {
            if(dptExtra.sinistro && ((this.tipoSinistro != "" && dptExtra.tipo == this.tipoSinistro) || this.tipoSinistro == "")){
              if(this.checkDate(this.dataInicioSinistro,this.dataFimSinistro,dptExtra.sinistro.dataSinistro)){
              if(dptExtra.sinistro.assistenciaSinistro == true){
                arrServExtras.push("ASSISTÊNCIA")
              }
              if(dptExtra.sinistro.indenizacaoSinistro == true){
                arrServExtras.push("INDENIZAÇÃO")
              }
              if(dptExtra.sinistro.servicosSinistro == true){
                arrServExtras.push("SERVIÇOS")
              }
              let objDepExtra = {
                matricula:dependenteExtra.matricula,
                sinistrado: dptExtra.nome,
                situacao: dptExtra.sinistro.situacao,
                tipo: dptExtra.tipo,
                servico: arrServExtras
              }
              this.relatorio.push(objDepExtra);
              this.relatorioBackup.push(objDepExtra);
              this.relatorio.sort((a,b) => {
                if(a.matricula < b.matricula){
                  return -1;
                }else{
                  return 1;
                }
              })
            }}
          })
        }
      })
     })
    })
  }   
}

buscarAniversariantes(){
  let dia = this.dataAniversario.split('-')[2];
  let mes = this.dataAniversario.split('-')[1];
  let dataNiver = mes+'-'+dia;
  this.db.getAssociadosAniversario(dataNiver).subscribe((data:any) => {
    this.relatorio = data;
  });
}

sendWhats(cel){
  let celular = cel.replace('(','').replace(')','').replace(' ','').replace('-','');
  let url = 'https://api.whatsapp.com/send?text='+this.msgParabens+'&phone=55'+celular+'';
  window.open(url); 
}

verificaData(data){
  if(data){
    let ano = data.split('-')[0];
    if(Number(ano) > 9999){
      this.app.openAlert("ATENÇÃO! O ANO NÃO PODE TER MAIS QUE 4 DIGITOS!")
    }
  }
}

buscarCancelados(){
  this.cancelamentoAba = true;
  this.relatorio = [];
  let sub:any = this.subContratoCancelado ? this.subContratoCancelado.nome : "";
  this.db.getAssociadosCancelados(this.dataInicioCancelado,this.dataFimCancelado,this.matriculaCancelado,this.motivoCancelamento,sub,this.cidadeCancelado,this.vendedorCancelamento).subscribe((data:any) => {
    this.mensalidadeTotal = 0;
    this.mensalidadeVivamax = 0;
    this.mensalidadeConecta = 0;      
    data.forEach((item:any) => {
      if(item.totalMensalidade){
        this.mensalidadeTotal += item.totalMensalidade;
        if(item.empresa == "VIVA MAX"){
          this.mensalidadeVivamax += item.totalMensalidade;
        }
        if(item.empresa == "CONECTA"){
          this.mensalidadeConecta += item.totalMensalidade;
        }
      }
      let m;
      let a = new Date(item.dataAdesao);
      let c = new Date(item.dataCancelamento);
      m = (c.getFullYear() - a.getFullYear()) * 12;
      m += c.getMonth() - a.getMonth();
      item.meses = m;
      this.relatorio.push(item);
    })
  })
}

buscarSolicitacaoCancelados(){
  this.cancelamentoAba = false;
  this.relatorio = [];
  this.db.getAssociadosSolicitacaoCancelados(this.dataInicioSolicitacaoCancelado,this.dataFimSolicitacaoCancelado).subscribe((data:any) => {
    this.mensalidadeTotal = 0;
    this.mensalidadeVivamax = 0;
    this.mensalidadeConecta = 0;      
    data.forEach((item:any) => {
      if(item.totalMensalidade){
        this.mensalidadeTotal += item.totalMensalidade;
        if(item.empresa == "VIVA MAX"){
          this.mensalidadeVivamax += item.totalMensalidade;
        }
        if(item.empresa == "CONECTA"){
          this.mensalidadeConecta += item.totalMensalidade;
        }
      }
      let m;
      let a = new Date(item.dataAdesao);
      let c = new Date(item.dataSolicitacaoCancelamento);
      m = (c.getFullYear() - a.getFullYear()) * 12;
      m += c.getMonth() - a.getMonth();
      item.meses = m;
      this.relatorio.push(item);
    })
  })
}

buscarAtendimento(){
  let dtInicio = this.dataInicioAtendimento == "" ? '2020-01-01' : this.dataInicioAtendimento;
  let dtFim = this.dataFimAgendamento == "" ? '2100-01-01' : this.dataFimAgendamento;
  this.relatorioAtendimentos = [];
  let ctrl = 1;
  this.db.getAtendimentos(dtInicio,dtFim,this.motivoAtendimento,this.empresaAtendimento,this.planoAtendimento,this.contratoAtendimento).subscribe((data:any) => {
    if(data){
      data.forEach((user) => {
        if(user.historicos){
          user.historicos.forEach((item) => {
            if((item.data >= dtInicio && item.data <= dtFim) && (this.motivoAtendimento[0] != '' && this.motivoAtendimento.includes(item.motivo) || this.motivoAtendimento[0] == '')){
              let obj = {
                matricula : user.matricula,
                data : item.data,
                nome : user.nome,
                motivo : item.motivo,
                msg : item.msg,
                localTrabalho: user.localTrabalho,
                plano: user.plano,
                subContrato: user.subContrato
              }
              this.relatorioAtendimentos.push(obj);
            }
          })
        }
        if(ctrl == data.length){
          this.relatorioAtendimentos.sort((a,b) => {if(a.data < b.data) return -1})
        }else{
          ctrl++
        }
      })
    }
  })
}
 
buscarCombinados(){
  this.totalCombinados = 0;
  this.relatorio = [];
  let rel = [];
  let subContrato = this.subContratoComb ? this.subContratoComb.nome : "";
  let empresaComb = this.empresaComb ? this.empresaComb.codigo : "";
  let situacaoPlanosComb = "";
  if(this.situacaoPlanoComb == "TODOS"){
    situacaoPlanosComb = "";
  }else{
    situacaoPlanosComb = this.situacaoPlanoComb;
  }
  let dataInicio = this.dataInicioComb ? this.dataInicioComb : '2000-01-01';
  let dataFim = this.dataFimComb ? this.dataFimComb : '2200-01-01';
  this.db.getCombinados(dataInicio,dataFim,this.titularComb,this.tipoPlanoComb,subContrato,empresaComb,situacaoPlanosComb).subscribe((data:any) => {
    if(data.length > 0){
      let ctrl = 1;
      data.forEach((a:any) => {
        this.totalCombinados += Number(a.totalMensalidade);
        if(rel.length > 0){
          let i = rel.map((y:any) => {
            return y.cpf;
          }).indexOf(a.cpf);
          if(i >= 0){
            if(a.empresa == "VIVA MAX"){
              rel[i].vivamaxValor += a.totalMensalidade;
              rel[i].matricula = a.matricula;
            }else{
              if(a.empresa == "SEGUROS"){
                rel[i].segurosValor += a.totalMensalidade
              }
              if(a.empresa == "CONECTA"){
                rel[i].conectaValor += a.totalMensalidade
              }
            }
            rel[i].totalMensalidade += a.totalMensalidade;
            rel[i].plano += " / "+a.plano;
          }else{
            a.vivamaxValor = 0;
            a.segurosValor = 0;
            a.conectaValor = 0;
            if(a.empresa == "VIVA MAX"){
              a.vivamaxValor += a.totalMensalidade
            }else{
              if(a.empresa == "SEGUROS"){
                a.segurosValor += a.totalMensalidade
              }
              if(a.empresa == "CONECTA"){
                a.conectaValor += a.totalMensalidade
              }
            }
            rel.push(a);
          }
        }else{
          a.vivamaxValor = 0;
          a.segurosValor = 0;
          a.conectaValor = 0;
          if(a.empresa == "VIVA MAX"){
            a.vivamaxValor += a.totalMensalidade
          }else{
            if(a.empresa == "SEGUROS"){
              a.segurosValor += a.totalMensalidade
            }
            if(a.empresa == "CONECTA"){
              a.conectaValor += a.totalMensalidade
            }
          }
          rel.push(a);
        }
        if(data.length == ctrl){
          this.relatorio = rel.sort((a,b) => {if(a.nome < b.nome){return -1}});
        }else{
          ctrl++;
        }
      })
    }
  })
}

}
