<!-- CARTEIRINHA DOWNLOAD -->
<div id="carteiraDownload">
    <div class="cardImpressao">
        <div class="cartaoImpressao">
            <div class="cartaoImpressaoMargem">
                <div class="cartaoAssociado">
                    CARTÃO DO<br><span style="color:red">ASSOCIADO</span>
                </div>
                <div style="text-align: center;">
                    <img src="../assets/img/logo.png" width="70%">
                </div>
                <br>
                <div class="row nomeCarteirinha">
                    <div class="col">
                        {{carteirinhaSelecionada?.nome}}
                    </div>
                </div>
                <div class="row dadosCarteirinha">
                    <div class="col">
                        EMISSÃO: {{carteirinhaSelecionada?.emissao | date: 'dd/MM/yyyy'}}
                    </div>
                    <div class="col">
                        VALIDADE: {{carteirinhaSelecionada?.validade | date: 'dd/MM/yyyy'}}
                    </div>
                </div>
                <div class="row dadosCarteirinha">
                    <div class="col">
                        TIPO: {{carteirinhaSelecionada?.tipo}}
                    </div>
                    <div class="col">
                        MATRÍCULA: {{carteirinhaSelecionada?.matricula}}
                    </div>
                </div>
            </div>
            <div style="height: 4em"></div>
            <div class="cartaoImpressao">
                <div class="cartaoImpressaoMargem">
                    <div class="row" style="margin-left: 2em;margin-bottom: 5em;">
                        <div class="col col-4" style="text-align: left;font-weight: bold;">
                            vivamax.com.br
                        </div>
                        <div class="col col-8" style="text-align: right;font-weight: bold;">
                            <fa-icon [icon]="['fab', 'facebook']"></fa-icon>&nbsp;<fa-icon
                                [icon]="['fab', 'instagram']"></fa-icon>&nbsp;vivamaxplanofamiliar
                        </div>
                    </div>
                    <div class="row no-gutters aling-items-center"
                        style="text-align: center;margin-left: 2em;margin-bottom: 5em;">
                        <div class="col">
                            <img src="../../assets/img/logo.png" width="100%">
                        </div>
                        <div class="col">
                            <img src="../../assets/img/sindilojas.png" width="80%">
                        </div>
                    </div>
                    <div class="row aling-items-center">
                        <div class="col" style="font-weight: 800; margin-bottom: 2em;">
                            45.3038-4235 45.99921-0125&nbsp;<fa-icon [icon]="['fab', 'whatsapp']"></fa-icon>
                        </div>
                        <div class="col" style="line-height: 1em;text-align: center;font-weight: 600;">
                            R. Marechal Cândido Rondon, 1818<br>
                            Sala 12 - Centro / Cascavel-PR
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- MODAL EXTRATO FINANCEIRO -->
<div class="modalExtrato" id="modalExtrato">
        <div class="row">
            <div class="col col-2">
                <button class="btn btn-outline-danger btn-block" (click)="closeExtrato()">
                    <fa-icon [icon]="['fas', 'undo']"></fa-icon>&nbsp;VOLTAR
                </button>
            </div>
        </div>
        <br>
    <table class="table table-striped">
        <thead>
            <tr>
                <th>#</th>
                <th>Vencimento</th>
                <th>Valor</th>
                <th>Valor Total</th>
                <th>Situação</th>
                <th>Data Pagamento</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let e of extratoAssociado; let i = index">
                <td>{{ i+1 }}</td>
                <td>{{ e?.vencimento | date:'dd/MM/yyyy' }}</td>
                <td>{{ e?.valor | currency:'BRL' }}</td>
                <td>{{ e?.valorTotal | currency:'BRL' }}</td>
                <td [style.color]="e.status == 'Pago' ? 'green' : 'red'">{{ e?.status }}</td>
                <td>{{ e?.dataPagamento | date:'dd/MM/yyyy' }}</td>
            </tr>
        </tbody>
    </table>
</div>
<!-- MODAL ATUALIZAR AGENDAMENTO -->
<div class="modalAgendamento" id="modalEditarAgendamento">
    <div style="padding: 2em;">
        <div class="row">
            <div class="col col-3">
                <button class="btn btn-outline-danger btn-block" (click)="fecharAgenda()">
                    <fa-icon [icon]="['fas', 'undo']"></fa-icon>&nbsp;VOLTAR AGENDAMENTOS
                </button>
            </div>
            <div class="col col-6">
                <br>
            </div>
            <div class="col col-3">
                <button class="btn btn-outline-success btn-block" (click)="editarAgendamento()">
                    <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;SALVAR ALTERAÇÕES
                </button>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-4">
                <label>Paciente:</label>
                <select class="form-control" [(ngModel)]="pacienteMedicoEditar">
                    <option selected></option>
                    <option [value]="nomeEditar">{{nomeEditar}} || TITULAR</option>
                    <option *ngFor="let dependente of todosDependentes" [value]="dependente.nome">{{dependente.nome}} ||
                        {{dependente.tipo}}</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone para Contato</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                    [(ngModel)]="telefoneContatoMedicoEditar">
            </div>
            <div class="col col-2" style="text-align: center;">
                <div class="form-check">
                    <label class="form-check-label" for="whatsappContatoMedicoEditar" style="padding-bottom:1em">
                        Whatsapp
                    </label>
                    <br>
                    <input class="form-check-input" type="checkbox" id="whatsappContatoMedicoEditar"
                        [(ngModel)]="whatsappContatoMedicoEditar">
                </div>
            </div>
            <div class="col col-2">
                <label>Telefone para Recado</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                    [(ngModel)]="telefoneRecadoMedicoEditar">
            </div>
            <div class="col col-2" style="text-align: center;">
                <div class="form-check">
                    <label class="form-check-label" for="whatsappRecadoMedicoEditar" style="padding-bottom:1em">
                        Whatsapp
                    </label>
                    <br>
                    <input class="form-check-input" type="checkbox" id="whatsappRecadoMedicoEditar"
                        [(ngModel)]="whatsappRecadoMedicoEditar">
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-2">
                <label>Guia do SUS</label>
                <select class="form-control" [(ngModel)]="guiaSusMedicoEditar">
                    <option selected></option>
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Procedimento</label>
                <select class="form-control" [(ngModel)]="procedimentoMedicoEditar">
                    <option selected></option>
                    <option value="CONSULTA">CONSULTA</option>
                    <option value="EXAME">EXAME</option>
                </select>
            </div>
            <div class="col col-3">
                <label>Especialidade</label>
                <select [(ngModel)]="especialidadeMedicoEditar" class="form-control">
                    <option selected></option>
                    <option *ngFor="let esp of todasEspecialidades" [value]="esp.nome">{{esp.nome}}</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Médico(a) Preferencial</label>
                <select class="form-control" (change)="medicoChange()" [(ngModel)]="medicoPreferencialMedicoEditar">
                    <option selected></option>
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>
            <div class="col col-3">
                <label>Nome do Médico(a)</label>
                <input type="text" class="form-control" [(ngModel)]="medicoPreferenciaEditar"
                    [disabled]="medicoPreferencialMedicoEditar != 'SIM'"
                    oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-3">
                <label>1ª Data Preferencial</label>
                <input type="date" (change)="verificaData(primeiraDataMedicoEditar)" class="form-control"
                    [(ngModel)]="primeiraDataMedicoEditar">
            </div>
            <div class="col col-3">
                <label>1ª Periodo Preferencial</label>
                <select class="form-control" (change)="medicoChange()" [(ngModel)]="primeiroPeriodoMedicoEditar">
                    <option selected></option>
                    <option value="MANHÃ">MANHÃ</option>
                    <option value="TARDE">TARDE</option>
                </select>
            </div>
            <div class="col col-3">
                <label>2ª Data Preferencial</label>
                <input type="date" (change)="verificaData(segundaDataMedicoEditar)" class="form-control"
                    [(ngModel)]="segundaDataMedicoEditar">
            </div>
            <div class="col col-3">
                <label>2ª Periodo Preferencial</label>
                <select class="form-control" (change)="medicoChange()" [(ngModel)]="segundoPeriodoMedicoEditar">
                    <option selected></option>
                    <option value="MANHÃ">MANHÃ</option>
                    <option value="TARDE">TARDE</option>
                </select>
            </div>
        </div>
        <br>
        <hr>
        <h5>Credenciado</h5>
        <div class="row">
            <div class="col col-6">
                <label>Laboratório/Médico</label>
                <input type="text" class="form-control" [(ngModel)]="laboratorioMedicoEditar"
                    oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-3">
                <label>Telefone</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                    [(ngModel)]="telefoneMedicoEditar">
            </div>
            <div class="col col-3">
                <label>Secretária</label>
                <input type="text" class="form-control" oninput="this.value = this.value.toUpperCase()"
                    [(ngModel)]="secretariaMedicoEditar">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-3">
                <label>Já é Paciente</label>
                <select class="form-control" [(ngModel)]="jaEPacienteMedicoEditar">
                    <option selected></option>
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>
            <div class="col col-3">
                <label>Agendado Para</label>
                <input type="date" (change)="verificaData(agendadoParaMedicoEditar)" class="form-control"
                    [(ngModel)]="agendadoParaMedicoEditar">
            </div>
            <div class="col col-3">
                <label>Horário</label>
                <input type="time" class="form-control" [(ngModel)]="horarioMedicoEditar">
            </div>
            <div class="col col-3">
                <label>Guia Emitida</label>
                <input type="date" (change)="verificaData(guiaEmitidaMedicoEditar)" class="form-control"
                    [(ngModel)]="guiaEmitidaMedicoEditar">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-3">
                <label>Valor da Consulta</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="valorConsultaMedicaEditar">
                </div>
            </div>
            <div class="col col-3">
                <label>Forma de Pagamento</label>
                <select class="form-control" [(ngModel)]="formaPagamentoMedicoEditar">
                    <option value="CARTÃO">CARTÃO</option>
                    <option value="DINHEIRO">DINHEIRO</option>
                </select>
            </div>
            <div class="col col-3" *ngIf="formaPagamentoMedicoEditar == 'DINHEIRO'">
                <label>Precisa Troco?</label>
                <select class="form-control" [(ngModel)]="precisaTrocoMedicoEditar">
                    <option value="SIM">SIM</option>
                    <option value="NÃO">NÃO</option>
                </select>
            </div>
            <div class="col col-3" *ngIf="precisaTrocoMedicoEditar == 'SIM'">
                <label>Para Quanto?</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <input type="number" class="form-control" [(ngModel)]="trocoMedicoEditar">
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="guiaCredenciadoEditar"
                        [(ngModel)]="levarGuiaMedicoEditar">
                    <label class="form-check-label" for="guiaCredenciadoEditar" style="margin-left:10px">
                        LEVAR GUIA NO CREDENCIADO
                    </label>
                </div>
            </div>
            <div class="col">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="retiraGuiaEditar"
                        [(ngModel)]="retiraGuiaMedicoEditar">
                    <label class="form-check-label" for="retiraGuiaEditar" style="margin-left:10px">
                        ASSOCIADO RETIRA A GUIA NO ESCRITÓRIO
                    </label>
                </div>
            </div>
            <div class="col">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="semGuiMedicoEditar"
                        [(ngModel)]="semGuiaMedicoEditar">
                    <label class="form-check-label" for="semGuiMedicoEditar" style="margin-left:10px">
                        SEM GUIA DE ATENDIMENTO
                    </label>
                </div>
            </div>
        </div>
        <br>
        <div class="row" style="padding:10px">
            <div class="col">
                <label>Observações</label>
                <textarea class="form-control" [(ngModel)]="observacaoMedicoEditar" rows="4"
                    oninput="this.value = this.value.toUpperCase()"></textarea>
            </div>
        </div>
        <br><br>
    </div>
</div>
<!-- MODAL EDITAR ASSOCIADO -->
<div class="modal" id="modalEditar">
    <!-- INICIO MODAIS INTERNOS -->
    <div class="backblack" *ngIf="loadCard">
        <div class="inner-black">
            <div class="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
    <div id="divToPrint" style="display:none">
        <div class="row">
            <div class="col" style="text-align: left;">
                <img src="../../assets/img/logo.png" style="width: 250px;">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-8" *ngIf="subContratoEditar != ''">
                <label>Sub Contrato:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{subContratoEditar}}
                </div>
            </div>
            <div class="col col-8" *ngIf="planoFisicoCheckEditar">
                <label>Plano Fisico:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{planoEditar}}
                </div>
            </div>
            <div class="col col-1">
                &nbsp;
            </div>
            <div class="col col-3">
                <label>Matricula:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{matriculaEditar}}
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5">
                <label>Nome Completo:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{nomeEditar}}
                </div>
            </div>
            <div class="col col-2">
                <label>Estado Civil:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{estadoCivilEditar}}
                </div>
            </div>
            <div class="col col-2">
                <label>CPF:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{cpfEditar}}
                </div>
            </div>
            <div class="col col-3">
                <label>Data Adesão:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{dataAdesaoEditar | date:'dd/MM/yyyy'}}
                </div>
            </div>
        </div>
        <br>
        <div class="row align-items-center">
            <div class="col col-2">
                <label>Nascimento:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{nascimentoEditar | date:'dd/MM/yyyy'}}
                </div>
            </div>
            <div class="col col-4">
                <label>Telefone:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{telefoneEditar}}
                </div>
            </div>
            <div class="col col-4">
                <label>Celular:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{celularEditar}}
                </div>
            </div>
            <div class="col col-2">
                <br>
                <div class="form-check" *ngIf="!whatsappEditar">
                    <input class="form-check-input" type="checkbox" id="whatsEditarPrint">
                    <label class="form-check-label" for="whatsEditarPrint">&nbsp;&nbsp;Whatsapp</label>
                </div>
                <div class="form-check" *ngIf="whatsappEditar">
                    <input class="form-check-input" type="checkbox" checked id="whatsEditarPrint">
                    <label class="form-check-label" for="whatsEditarPrint">&nbsp;&nbsp;Whatsapp</label>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-2">
                <label>Sexo:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{sexoEditar}}
                </div>
            </div>
            <div class="col col-2">
                <label>CEP:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{cepEditar}}
                </div>
            </div>
            <div class="col col-3">
                <label>Rua:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{ruaEditar}}
                </div>
            </div>
            <div class="col col-2">
                <label>Número:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{numeroEditar}}
                </div>
            </div>
            <div class="col col-3">
                <label>Bairro:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{bairroEditar}}
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-2">
                <label>Complemento:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{complementoEditar}}
                </div>
            </div>
            <div class="col col-3">
                <label>Município:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{municipioEditar}}
                </div>
            </div>
            <div class="col col-1">
                <label>UF:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{estadoEditar}}
                </div>
            </div>
            <div class="col col-3">
                <label>Local Trabalho:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{localTrabalhoEditar}}
                </div>
            </div>
            <div class="col col-3">
                <label>Função:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{funcaoEditar}}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>E-mail:</label>
                <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                    &nbsp;{{emailEditar}}
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <label>DADOS CADASTRAIS DOS DEPENDENTES</label>
            </div>
        </div>
        <div *ngIf="dep.length == 0">
            NÃO POSSUI DEPENDENTES CADASTRADOS
        </div>
        <div *ngIf="dep.length > 0">
            <form [formGroup]="dependentesEditar">
                <div class="row" formArrayName="dependenteEditar">
                    <div class="col col-12"
                        *ngFor="let dependenteEditar of dependentesEditarFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row align-items-center">
                            <div class="form-group col-2">
                                <label>Tipo:</label>
                                <div
                                    style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                                    &nbsp;{{dependentesEditar.controls.dependenteEditar.value[i].tipo}}
                                </div>
                            </div>
                            <div class="form-group col-3">
                                <label>Nome:</label>
                                <div
                                    style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                                    &nbsp;{{dependentesEditar.controls.dependenteEditar.value[i].nome}}
                                </div>
                            </div>
                            <div class="form-group col-2">
                                <label>Data Nascimento:</label>
                                <div
                                    style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                                    &nbsp;{{dependentesEditar.controls.dependenteEditar.value[i].nascimento}}
                                </div>
                            </div>
                            <div class="form-group col-2">
                                <label>CPF:</label>
                                <div
                                    style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                                    &nbsp;{{dependentesEditar.controls.dependenteEditar.value[i].cpf}}
                                </div>
                            </div>
                            <div class="form-group col-3">
                                <label>ASF:</label>
                                <div
                                    style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px; font-size: 0.8em;">
                                    &nbsp;{{dependentesEditar.controls.dependenteEditar.value[i].asf}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="row">
            <div class="col">
                <label>DADOS CADASTRAIS DOS DEPENDENTES EXTRAS</label>
            </div>
        </div>
        <br>
        <div *ngIf="depExtras.length == 0">
            NÃO POSSUI DEPENDENTES EXTRAS CADASTRADOS
        </div>
        <div *ngIf="depExtras.length > 0">
            <form [formGroup]="dependentesExtraEditar">
                <div class="row" formArrayName="dependenteExtraEditar">
                    <div class="col col-12"
                        *ngFor="let dependenteExtraEditar of dependentesExtraEditarFormGroup.controls; let i = index;">
                        <div class="row align-items-center" [formGroupName]="i">
                            <div class="form-group col-2">
                                <label>Tipo:</label>
                                <div
                                    style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                                    &nbsp;{{dependentesExtraEditar.controls.dependenteExtraEditar.value[i].tipo}}
                                </div>
                            </div>
                            <div class="form-group col-3">
                                <label>Nome:</label>
                                <div
                                    style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                                    &nbsp;{{dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nome}}
                                </div>
                            </div>
                            <div class="form-group col-2">
                                <label>Data Nascimento:</label>
                                <div
                                    style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                                    &nbsp;{{dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nascimento}}
                                </div>
                            </div>
                            <div class="form-group col-2">
                                <label>CPF:</label>
                                <div
                                    style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                                    &nbsp;{{dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf}}
                                </div>
                            </div>
                            <div class="form-group col-3">
                                <label>ASF:</label>
                                <div
                                    style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px; font-size: 0.8em">
                                    &nbsp;{{dependentesExtraEditar.controls.dependenteExtraEditar.value[i].asf}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        Dependentes extras com idade acima de 69 (sessenta e nove) anos na data de início do contrato ou com dados
        incompletos ou com doenças pré-existentes terão cobertura apenas do Convênio de Saúde.<br>
        ASF = Assistência Funeral.<br>
        ASF Adicional = Assistência Funeral mediante pagamento de mensalidade extra.<br>
        <br>
        <strong>DECLARAÇÃO DE SAÚDE</strong>
        <br>
        O proponente ou algum dos dependentes possui ou já possuiu alguma doença, tais como: diabetes, hipertensão
        arterial, hepatite, AIDS, infarto, doenças cardíacas, tumor maligno (cÂncer), derrane, doenças de chagas,
        leucemia, enfisema, cirrose, doenças renais, doenças neurológicas, doenças digestivas, doenças pulmonares ou
        qualquer outroa doença?<br>
        <br>
        <div>
            <label>Proponente Possui Problemas Pré Existentes?</label>
            <div class="row">
                <div class="col col-2">
                    <div class="form-check" *ngIf="doencaEditar == 'SIM'">
                        <input class="form-check-input" type="checkbox" id="doencaNaoEditarPrint" checked>
                        <label class="form-check-label" for="doencaNaoEditar" style="margin-left:10px">SIM</label>
                    </div>
                    <div class="form-check" *ngIf="doencaEditar != 'SIM'">
                        <input class="form-check-input" type="checkbox" id="doencaSimEditarPrint">
                        <label class="form-check-label" for="doencaSimEditar" style="margin-left:10px">SIM</label>
                    </div>
                </div>
                <div class="col col-2">
                    <div class="form-check" *ngIf="doencaEditar == 'NÃO'">
                        <input class="form-check-input" type="checkbox" id="doencaNaoEditarPrint" checked>
                        <label class="form-check-label" for="doencaNaoEditar" style="margin-left:10px">NÃO</label>
                    </div>
                    <div class="form-check" *ngIf="doencaEditar != 'NÃO'">
                        <input class="form-check-input" type="checkbox" id="doencaNaoEditarPrint">
                        <label class="form-check-label" for="doencaNaoEditar" style="margin-left:10px">NÃO</label>
                    </div>
                </div>
                <div class="col col-8" *ngIf="doencaEditar == 'SIM'">
                    <label>Especificar:</label>
                    <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                        &nbsp;{{especificarDoencaEditar}}
                    </div>
                </div>
            </div>
        </div>
        <p style="page-break-after: always;">&nbsp;</p>
        <p style="page-break-before: always;">&nbsp;</p>
        <div class="row">
            <div class="col" style="text-align: left;">
                <img src="../../assets/img/logo.png" style="width: 250px">
            </div>
        </div>
        <br>
        <strong>ADESÃO E MANUTENÇÃO</strong><br>
        <br>
        <div class="row">
            <div class="col">
                Taxa de Adesão {{ taxaAdesaoEditar | currency:'BRL' }}
            </div>
            <div class="col">
                Mensalidade Total {{ totalMensalidadeEditar | currency:'BRL' }}
            </div>
        </div>
        <div style="font-size:12px; text-align: justify;">
            <br>
            Forma de pagamento da mensalidade de acordo com Autorização de Dados de Pagamento vinculado ao
            contratao.<br>
            <br>
            Estou ciente e autorizo desde já a atualizaçao monetária para correção do valor da mensalidade todo mês de
            janeiro, de acordo com o Índice de Preços ao Consumidor Amplo - IPCA ou outro que venha a substituí-lo.<br>
            <br>
            <strong>INFORMAÇÕES COMPLEMENTARES</strong><br>
            <br>
            O contrato tem início de vigência na data do desconto em folha de pagamento, pagamento da taxa de adesão ou
            primeira mensalidade sendo esta data utilizada para contagem dos prazos de carência.<br>
            O limite de idade para adesão ao contrato é de 69 (sessenta e nove) anos para plano físico e 64 (sessenta e
            quatro) anos para planos especiais de servidores públicos municipais.<br>
            Somente os contratos firmados junto a entidades de classes (sindicatos e associações) e com desconto em
            folha de pagamento possui cobertura de indenização à família por morte natural do titular. O cônjuge do
            titular, filhos e dependentes extras não possuem cobertura de indenização por morte natural ou
            acidental.<br>
            Os contratos não vinculados a entidades de classes, em hipótese alguma terão cobertura de indenização por
            morte natural do titular, cônjuge, filhos e dependentes extras, tendo direito tão somente a indenização por
            morte acidental do titular.<br>
            Cobertura de Cesta Natalidade extensiva somente ao cônjuge do titular devidamente cadastrado.<br>
            Cobertura de assistência funeral extensiva automaticamente para o cônjuge e filhos dependentes com até 18
            (dezoito) anos, de acordo com a regra do Imposto de Renda.<br>
            Pai, mãe, sogro, sogra e filhos maiores de 18 (dezoito) anos que não se encaixarem na regra de Imposto de
            Renda terão cobertura de assistência funeral desde que preenchidos todos os dados (nome completo, data de
            nascimento e CPF) e se enquadrarem nos critérios de aceitação da contratante, sendo idade limite de 69
            (sessenta e nove) anos na data de inclusão, pagamento de mensalidade adicional e inexistência de doenças
            pré-existentes.<br>
            Cobertura de Morte Natural, Morte Acidental, Assistência Funeral e Cartão Alimentação para titular possuem
            carência de 90 (noventa) dias a partir da data do início da vigência do contrato.<br>
            Cobertura de Assistência Funeral para cônjuge e filhos possuem carência de 90 (noventa) dias a partir da
            data do início da vigência do contrato, exceto suicídio, o qual haverá carência de 2 (dois) anos.<br>
            Quando contratada a cobertura de assistência funeral para dependentes extras, sendo filhos acima de 18
            (dezoito) anos, pais ou sogros, haverá carência de 180 (cento e oitenta) dias à contar da data de início de
            vigência do contrato, exceto suicídio, o qual haverá carência de 2 (dois) anos.<br>
            Cobertura de Cesta Natalidade possui carência de 1 (um) ano a partir do início de vigência do contrato, com
            prazo de prescrição de 3 (três) meses após o nascimento.<br>
            Sorteio mensal vinculado à Loteria Federal, realizado todo último sábado do mês. O associado poderá
            solicitar a qualquer tempo o número da sorte a que se vincula o contrato.<br>
            Possui direito à indenização da cobertura de diagnóstico de doenças graves apenas os titulares dos planos
            com desconto em folha de pagamento que possui ao menos de 65 (sessenta e cinco) anos na data do diagnóstico
            que estejam em plena atividade laboral. Proponentes com idade superior a 65 (sessenta e cinco) anos na data
            do diagnóstico ou aposentados por tempo de trabalho ou doença, independentemente da idade, não possuem
            direito a indenização.<br>
            Eventos cobertos: Tumor Maligno, Acidente Vascular Cerebral, Infarto do Miocárdio e Transplante de Órgãos
            Vitais, de acordo com condições gerais da apólice de seguro de vida em grupo vinculada ao contrato.<br>
            A falta de pagamento da mensalidade em prazo igual ou superior a 30 (trinta) dias corridos, seja por
            insuficiência de fundos, qualquer motivo informado pelo banco emissor do cartão de crédito ou inadimplência
            do boleto, acarretará em suspensão dos serviços ora ofertados, ensejando automaticamente em novos prazos de
            carência. Os dependentes extras com idade superior a 69 (sessenta e nove) anos serão automaticamente
            excluídos sem possibilidade de retorno.<br>
            A falta de repasse à Viva Max, pela entidade contratante, por prazo superior a 15 (quinze) dias do
            vencimento, acarretará em suspensão dos serviços ora ofertados, ensejando automaticamente e novos prazos de
            carência. Os dependentes extras com idade superior a 69 (sessenta e nove) anos serão automaticamente
            exclusos sem possibilidade de retorno.<br>
            Havendo desistência, exoneração de cargo público para contratos com desconto em folha de pagamento ou
            cancelamento do plano do titular por quaisquer motivos, automaticamente serão cancelados os planos dos
            proponentes extras a que se vinculam a mensalidade. <br>
            <br>
            <strong>DECLARAÇÃO DO PROPONENTE</strong><br>
            <br>
            Pelo presente, solicito minha inclusão e de meus beneficiários descritos no item 3 no Plano Familiar de
            Benefícios Viva Max, a qual é empresa estipulante da apólice de seguro que contempla o produto, devidamente
            inscrita sob o CNPJ 20.504.980/00001-77, com sede da matriz no endereço Rua Marechal Cândido Rondon, 1818,
            Sala 12, bairro Centro, município de Cascavel, estado do Paraná, constituindo-o como meu/nosso mandatário
            junto às seguradoras, com poderes para representar junto as mesmas em futuras renovações, negociações,
            manutenção, e continuidade da apólice de seguro a que se vinculam minhas coberturas, podendo inclusive
            alterar a atual seguradora, bem como acertar com a mesma ou outras novos valores de prêmios de seguro, taxa
            e capitais segurados ainda que tais alterações contratuais venham a gerar ônus, deveres ou perdas de direito
            para minha pessoa ou meus beneficiários, mas desde que visem garantir a sustentação técnica e a manutenção
            das demais cláusulas contratuais as quais ora são por mim aderidas.<br>
            Declaro que ao preencher a proposta de adesão tomei conhecimento na íntegra das condições contratuais do
            plano incluindo as condições gerais do seguro de vida em grupo contendo todas as cláusulas que foram
            integralmente lidas entendidas e aceitas por mim.<br>
            Declaro estar de acordo com a presente proposta e condições do plano e que nada omiti em relação ao meu
            estado de saúde ou de meus beneficiários vinculados ao meu plano, estando ciente do prazo de até 15 (quinze)
            dias úteis para aceitação tácita ou recusa da proposta tendo prestado todas as informações completas e
            verídicas, ficando à Viva Max autorizada a utilizar tais informações no amparo e defesa de seus
            direitos.<br>
            Estou ciente que de acordo com o artigo 766 do Código Civil brasileiro, se prestei declarações inexatas ou
            omiti informações e/ou circunstâncias que possam influir na aceitação da proposta perderei o direito às
            garantias contratuais, tornando-se nulo de pleno direito, esse contrato.<br>
            <br><br>
            <div class="row">
                <div class="col" style="text-align: center;">{{municipioEditar}} - {{estadoEditar}}, {{dataAdesaoEditar
                    | date:'dd/MM/yyyy'}}</div>
            </div>
        </div>
        <br><br>
        <div class="row">
            <div class="col" style="text-align: center;line-height: 1.3em;">
                <span style="border-bottom:1px solid #000; width: 400px;">
                    {{vendedorEditar}}
                </span><br>
                Representante
            </div>
        </div>
    </div>
    <div id="divToPrintRemetente" style="display:none">
        <strong>{{nomeEditar}}</strong><br>
        {{celularEditar}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{localTrabalhoEditar}}<br>
        {{ruaEditar}}, {{numeroEditar}}&nbsp;&nbsp;&nbsp;{{bairroEditar}}<br>
        {{municipioEditar}} - {{estadoEditar}} - CEP: {{cepEditar}}
    </div>
    <div id="divToPrintFinanceiro" style="display:none">
        <div class="row">
            <div class="col" style="text-align: left;">
                <img src="../../assets/img/logo.png" style="width: 250px">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col" style="text-align: center;">
                <strong>AUTORIZAÇÃO DE DADOS DE PAGAMENTO VINCULADO AO CONTRATO Nº {{matriculaEditar}}</strong>
            </div>
        </div>
        <br><br>
        <div class="row" *ngIf="formaPagamentoEditar == 'DESCONTO EM FOLHA DE PAGAMENTO'">
            <div class="col">
                Eu, {{nomeEditar}}, portador(a) do CPF {{cpfEditar}} autorizo o débito mensal em minha folha de
                pagamento do valor de {{totalMensalidadeEditar | currency:'BRL'}}, a partir de
                {{mesFolhaPagamentoEditarPrint}}, referente à mensalidade do Plano Familiar de Benefícios Viva Max,
                sendo parceria firmada entre a mesma e o(a) {{subContratoEditar}}, CNPJ {{cnpjSubContrato}}.<br>
                <br><br>
                Estou ciente e autorizo desde já a atualização monetária para correção do valor da mensalidade todo mês
                de janeiro, de acordo com o índice de Preços ao Consumidor Amplo - IPCA ou outro que venha a
                substituí-lo.<br>
                <br>
            </div>
        </div>
        <div class="row" *ngIf="formaPagamentoEditar == 'DEBITO RECORRENTE'">
            <div class="col">
                Eu, {{nomeEditar}}, portador(a) do CPF {{cpfEditar}} autorizo o lançamento da taxa de adesão no valor de
                {{taxaAdesaoEditar | currency:'BRL'}}, bem como a mensalidade no valor de {{totalMensalidadeEditar |
                currency:'BRL'}}, a ser debitado todo dia {{diaVencimentoEditar}}, a partir do mês de {{mesSeguinte}} na
                modalidade de débito recorrente no cartão de crédito por mim indicado, conforme dados abaixo, referente
                à contratação do Plano Familiar de Benefícios Viva Max.<br>
                <br><br>
                <div class="row">
                    <div class="col">
                        <label>Nome:</label>
                        <div
                            style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                            &nbsp;{{nomeCartaoEditar}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-4">
                        <label>Número do Cartão:</label>
                        <div
                            style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                            &nbsp;{{ '**** **** ****' + numeroCartaoEditar?.slice(14,19) }}
                        </div>
                    </div>

                    <div class="col col-4">
                        <label>Validade:</label>
                        <div
                            style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                            &nbsp;{{validadeCartaoEditar}}
                        </div>
                    </div>
                    <div class="col col-4">
                        <label>Código de Segurança:</label>
                        <div
                            style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px">
                            &nbsp;***
                        </div>
                    </div>
                </div>
                <br>
                Estou ciente que é de minha total e inescusável responsabilidade eventuais dados de terceiros por mim
                indicados para cobrança em débito recorrente.<br>
                <br>
                Estou ciente também e autorizo desde já a atualização monetária para correção do valor da mensalidade
                todo mês de janeiro, de acordo com o índice de Preços ao Consumidor Amplo - IPCA ou outro que venha a
                substituí-lo.<br>
                <br>
            </div>
        </div>
        <div class="row" *ngIf="formaPagamentoEditar == 'CARNÊ' || formaPagamentoEditar == 'BOLETO'">
            <div class="col">
                Eu, {{nomeEditar}}, portador(a) do CPF {{cpfEditar}} autorizo o envio de boleto mensal no valor de
                {{totalMensalidadeEditar | currency:'BRL'}}, para o endereço de e-mail informado na proposta vinculada à
                esta autorização, com vencimento todo dia {{diaVencimentoEditar}} a partir de {{mesSeguinte}.<br>
                <br><br>
                Estou ciente e autorizo desde já a atualização monetária para correção do valor da mensalidade todo mês
                de janeiro, de acordo com o índice de Preços ao Consumidor Amplo - IPCA ou outro que venha a
                substituí-lo.<br>
                <br>
            </div>
        </div>
    </div>
    <div id="myModal" class="modalLightbox">
        <span (click)="closeModal()" class="close">&times;</span>
        <div class="prev" (click)="prevImg()">&lt;</div>
        <img [src]="imagemSelecionada" class="imagemLightbox">
        <div class="next" (click)="nextImg()">&gt;</div>
    </div>
    <div id="lightSinistro" class="modalLightbox">
        <span (click)="closeModalSinistro()" class="close">&times;</span>
        <div class="prev" (click)="prevImgSinistro()">&lt;</div>
        <img [src]="imagemSelecionadaSinistro" class="imagemLightbox">
        <div class="next" (click)="nextImgSinistro()">&gt;</div>
    </div>
    <div id="lightCancelamento" class="modalLightbox">
        <span (click)="closeModalCancelamento()" class="close">&times;</span>
        <div class="prev" (click)="prevImgCancelamento()">&lt;</div>
        <img [src]="imagemSelecionadaCancelamento" class="imagemLightbox">
        <div class="next" (click)="nextImgCancelamento()">&gt;</div>
    </div>
    <div style="width: 800px; height: 400px;display: none;">
        <ng2-pdfjs-viewer #pdfViewerAssociado [externalWindow]="true" [downloadFileName]="'vivamax.pdf'"
            [openFile]="false" [viewBookmark]="false" [download]="true"></ng2-pdfjs-viewer>
    </div>
    <!-- FIM MODAIS INTERNOS -->
    <div class="row align-items-center header"
        [style.background]="empresaAssociado == 'CONECTA' ? '#01afac' : '#c7444a'">
        <div class="col" style="color:white;font-size: 1.2em;font-weight: bold;">
            {{empresaAssociado}}
        </div>
        <div class="col col-10" *ngIf="empresaAssociado == 'VIVA MAX'">
            <button class="btn btn-sm btn-outline-light" (click)="printTudo()"
                *ngIf="db?.usuario?.permissoes['contratos'] != 'NENHUMA'">
                <fa-icon [icon]="['fas', 'print']"></fa-icon>&nbsp;COMPLETA
            </button>
            &nbsp;&nbsp;&nbsp;
            <button class="btn btn-sm btn-outline-light" (click)="printRemetente()"
                *ngIf="db?.usuario?.permissoes['contratos'] != 'NENHUMA'">
                <fa-icon [icon]="['fas', 'print']"></fa-icon>&nbsp;REMETENTE
            </button>
            &nbsp;&nbsp;&nbsp;
            <button class="btn btn-sm btn-outline-light" (click)="printProposta()"
                *ngIf="db?.usuario?.permissoes['contratos'] != 'NENHUMA'">
                <fa-icon [icon]="['fas', 'print']"></fa-icon>&nbsp;PROPOSTA
            </button>
            &nbsp;&nbsp;&nbsp;
            <button class="btn btn-sm btn-outline-light" (click)="printFinanceiro()"
                *ngIf="db?.usuario?.permissoes['contratos'] != 'NENHUMA'">
                <fa-icon [icon]="['fas', 'print']"></fa-icon>&nbsp;FINANCEIRO
            </button>
            &nbsp;&nbsp;&nbsp;
            <button class="btn btn-sm btn-outline-light" (click)="printContrato()"
                *ngIf="db?.usuario?.permissoes['contratos'] != 'NENHUMA'">
                <fa-icon [icon]="['fas', 'print']"></fa-icon>&nbsp;CONTRATO
            </button>
              &nbsp;&nbsp;&nbsp;
            <button class="btn btn-sm btn-outline-light" (click)="openExtrato()"
                *ngIf="db?.usuario?.permissoes['contratos'] != 'NENHUMA'">
                <fa-icon [icon]="['fas', 'hand-holding-usd']"></fa-icon>&nbsp;EXTRATO
            </button>
        </div>
        <div style="position:absolute;right:10px">
            <button class="btn btn-outline-light" (click)="closeModalEditar()">
                <fa-icon [icon]="['fas', 'window-close']"></fa-icon>
            </button>
        </div>
    </div>
    <div class="corpo">
        <div class="row align-items-center"
            [ngStyle]="{'pointer-events': db?.usuario?.permissoes['associados'] == 'EDITAR' ? 'auto' : 'none'}">
            <div class="col col-2">
                <label>Matricula</label>
                <input type="number" min="0" class="form-control" [(ngModel)]="matriculaEditar"
                    (change)="salvar('matricula',matriculaEditar)">
            </div>
            <div class="col col-2">
                <label>Mat. Servidor</label>
                <input type="number" min="0" class="form-control" [(ngModel)]="matriculaServidorEditar"
                    (change)="salvar('matriculaServidor',matriculaServidorEditar)">
            </div>
            <div class="col col-2">
                <label>Nº Sorte</label>
                <input type="number" min="0" class="form-control" [(ngModel)]="numSorte" (change)="salvarTemp('numSorte',numSorte)">
            </div>
            <div class="col col-3" [ngClass]="numSorte ? 'col-3' : 'col-5'">
                <label>Vendedor</label>
                <select class="form-control" [(ngModel)]="vendedorEditar"
                    (change)="selectVendedorEditar(vendedorEditar)"
                    [ngStyle]="{'border-color': !vendedorEditar ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option value="SISTEMA SITE">SISTEMA SITE</option>
                    <option *ngFor="let vendedor of todosVendedores" [value]="vendedor.nome">{{vendedor.nome}}</option>
                </select>
            </div>
            <div class="col col-3">
                <label>Situação</label>
                <select class="form-control" [(ngModel)]="situacaoEditar" (change)="mudarStatus(situacaoEditar)" [ngStyle]="{'pointer-events': situacaoEditar != 'CANCELADO' ? 'all' : 'none'}">
                    <option value="ATIVO">ATIVO</option>
                    <option value="PENDENTE">PENDENTE</option>
                    <option value="ALTERAÇÃO">ALTERAÇÃO</option>
                    <option value="INADIMPLENTE I">INADIMPLENTE I</option>
                    <option value="INADIMPLENTE II">INADIMPLENTE II</option>
                    <option value="AFASTADO">AFASTADO</option>
                    <option value="AFASTADO INADIMPLENTE">AFASTADO INADIMPLENTE</option>
                    <option value="CANCELADO" disabled>CANCELADO</option>
                </select>
            </div>
            <div class="col col-3" *ngIf="situacaoEditar == 'AFASTADO' || situacaoEditar == 'AFASTADO INADIMPLENTE'"
                style="background-color: #f7e96b; padding:10px;margin-top: 10px;">
                <label>Data de Afastamento</label>
                <input type="date" [(ngModel)]="afastadoDesde" class="form-control"
                    (change)="salvar('afastadoDesde',afastadoDesde)">
            </div>
        </div>
        <br>
        <tabset #staticTabs>
            <tab heading="DADOS PESSOAIS" style="padding:10px;"
                [ngStyle]="{'pointer-events': (situacaoEditar != 'CANCELADO' && db?.usuario?.permissoes['associados'] == 'EDITAR') ? 'auto' : 'none'}">
                <br>
                <div class="row"
                    style="background-color: #c7444a; text-align: center; padding:10px; color:white; font-size: 1.3em;font-weight: bold;"
                    *ngIf="situacaoEditar == 'CANCELADO'">
                    <div class="col">
                        CONTRATO CANCELADO
                    </div>
                </div>
                <div *ngIf="situacaoEditar == 'PENDENTE'" style="background-color: #f7e96b; padding:10px">
                    <div class="row"
                        style="text-align: center; padding:10px; color:black; font-size: 1.3em;font-weight: bold;">
                        <div class="col">
                            CONTRATO PENDENTE
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-4">
                            <label>Motivo da Pendência</label>
                            <select class="form-control" [(ngModel)]="motivoPendenciaEditar"
                                (change)="salvarTemp('motivoPendencia',motivoPendenciaEditar)">
                                <option value="" selected></option>
                                <option *ngFor="let motivo of motivosPendencia" [value]="motivo.nome">{{motivo.nome}}
                                </option>
                            </select>
                        </div>
                        <div class="col col-8">
                            <label>Observação Pendente</label>
                            <input type="text" class="form-control" [(ngModel)]="observacaoPendenteEditar"
                                (change)="salvarTemp('observacaoPendente',observacaoPendenteEditar)">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-6"></div>
                    <div class="col col-3" style="text-align: center;">
                        <button class="btn btn-outline-primary" (click)="baixarCarteirinhaTitular()">
                            <fa-icon [icon]="['fas', 'address-card']" size="1x"></fa-icon> CARTEIRA TITULAR
                        </button>
                    </div>
                    <div class="col col-3">
                        <button class="btn btn-success btn-block" (click)="salvarDados()">
                            <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;SALVAR DADOS
                        </button>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-5">
                        <label>Nome Completo:</label>
                        <input type="text" class="form-control" [(ngModel)]="nomeEditar"
                            (change)="salvarTemp('nome',nomeEditar)" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-2">
                        <label>Estado Civil:</label>
                        <select class="form-control" [(ngModel)]="estadoCivilEditar"
                            (change)="salvarTemp('estadoCivil',estadoCivilEditar)">
                            <option value="SOLTEIRO">SOLTEIRO</option>
                            <option value="CASADO">CASADO</option>
                            <option value="DIVORCIADO">DIVORCIADO</option>
                            <option value="VIÚVO">VIÚVO</option>
                            <option value="SEPARADO">SEPARADO</option>
                            <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                        </select>
                    </div>
                    <div class="col col-3">
                        <label>CPF:</label>
                        <input type="text" class="form-control" [(ngModel)]="cpfEditar" cpf [textMask]="{mask: MASKS.cpf.textMask}" (change)="cpfUnicoEditar(cpfEditar)" (keyup)="app.checkCPF(cpfEditar)">
                        <div class="errorMsg" *ngIf="cpfEditar && !app.checkCPF(cpfEditar)">CPF incorreto!</div>
                        <div class="errorMsg" *ngIf="!cpfCheckEditar">CPF Já Cadastrado!</div>
                    </div>
                    <div class="col col-2">
                        <label>Data Adesão:</label>
                        <input type="date" (keyup)="verificaData(dataAdesaoEditar)" class="form-control"
                            [(ngModel)]="dataAdesaoEditar" (blur)="salvarTemp('dataAdesao',dataAdesaoEditar)">
                    </div>
                </div>
                <br>
                <div class="row align-items-center">
                    <div class="col col-2">
                        <label>Nascimento:</label>
                        <input type="date" (keyup)="verificaData(nascimentoEditar)" class="form-control"
                            [(ngModel)]="nascimentoEditar" (blur)="salvarTemp('nascimento',nascimentoEditar)">
                    </div>
                    <div class="col col-4">
                        <label>Telefone:</label>
                        <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                            [(ngModel)]="telefoneEditar" (change)="salvarTemp('telefone',telefoneEditar)">
                    </div>
                    <div class="col col-4">
                        <label>Celular:</label>
                        <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                            [(ngModel)]="celularEditar" (change)="salvarTemp('celular',celularEditar)">
                    </div>
                    <div class="col col-2">
                        <br>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="whatsappEditar"
                                id="whatsEditar" (change)="salvarTemp('whatsapp',whatsappEditar)">
                            <label class="form-check-label" for="whatsEditar">&nbsp;&nbsp;Whatsapp</label>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-2">
                        <label>Sexo:</label>
                        <select class="form-control" [(ngModel)]="sexoEditar" (change)="salvarTemp('sexo',sexoEditar)">
                            <option value="M">MASCULINO</option>
                            <option value="F">FEMININO</option>
                        </select>
                    </div>
                    <div class="col col-2">
                        <label>CEP:</label>
                        <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control"
                            [(ngModel)]="cepEditar" (change)="salvarTemp('cep',cepEditar)">
                    </div>
                    <div class="col col-1">
                        <br>
                        <button class="btn btn-light" (click)="buscarCepEditar(cepEditar)">
                            <fa-icon [icon]="['fas', 'search']"></fa-icon>
                        </button>
                    </div>
                    <div class="col col-3">
                        <label>Rua:</label>
                        <input type="text" class="form-control" [(ngModel)]="ruaEditar"
                            oninput="this.value = this.value.toUpperCase()" (change)="salvarTemp('rua',ruaEditar)">
                    </div>
                    <div class="col col-2">
                        <label>Número:</label>
                        <input type="text" class="form-control" [(ngModel)]="numeroEditar"
                            oninput="this.value = this.value.toUpperCase()"
                            (change)="salvarTemp('numero',numeroEditar)">
                    </div>
                    <div class="col col-2">
                        <label>Bairro:</label>
                        <input type="text" class="form-control" [(ngModel)]="bairroEditar"
                            oninput="this.value = this.value.toUpperCase()"
                            (change)="salvarTemp('bairro',bairroEditar)">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-2">
                        <label>Complemento:</label>
                        <input type="text" class="form-control" [(ngModel)]="complementoEditar"
                            oninput="this.value = this.value.toUpperCase()"
                            (change)="salvarTemp('complemento',complementoEditar)">
                    </div>
                    <div class="col col-3">
                        <label>Município:</label>
                        <input type="text" class="form-control" [(ngModel)]="municipioEditar"
                            oninput="this.value = this.value.toUpperCase()"
                            (change)="salvarTemp('municipio',municipioEditar)">
                    </div>
                    <div class="col col-1">
                        <label>UF:</label>
                        <input type="text" class="form-control" [(ngModel)]="estadoEditar"
                            oninput="this.value = this.value.toUpperCase()"
                            (change)="salvarTemp('estado',estadoEditar)">
                    </div>
                    <div class="col col-3">
                        <label>Local Trabalho:</label>
                        <input type="text" class="form-control" [(ngModel)]="localTrabalhoEditar"
                            oninput="this.value = this.value.toUpperCase()"
                            (change)="salvarTemp('localTrabalho',localTrabalhoEditar)">
                    </div>
                    <div class="col col-3">
                        <label>Função:</label>
                        <input type="text" class="form-control" [(ngModel)]="funcaoEditar"
                            oninput="this.value = this.value.toUpperCase()"
                            (change)="salvarTemp('funcao',funcaoEditar)">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-6">
                        <label>Login E-Consig:</label>
                        <input type="text" class="form-control" [(ngModel)]="loginEconsigEditar"
                            (change)="salvarTemp('loginEconsig',loginEconsigEditar)">
                    </div>
                    <div class="col col-6">
                        <label>Senha E-Consig:</label>
                        <input type="text" class="form-control" [(ngModel)]="senhaEconsigEditar"
                            (change)="salvarTemp('senhaEconsig',senhaEconsigEditar)">
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="db.tipoUsuario == 'MASTER' || db.tipoUsuario == 'AUX. ADMINISTRATIVO' || db.tipoUsuario == 'ASSESSOR COMERCIAL' || db.tipoUsuario == 'GERENTE'">
                    <div class="col col-8">
                        <label>E-mail:</label>
                        <input type="text" class="form-control" [(ngModel)]="emailEditar" autocomplete="off"
                        (keyup)="app.checkEmail(emailEditar)"
                        oninput="this.value = this.value.toUpperCase()" (change)="salvarTemp('email',emailEditar)">
                        <div class="errorMsg" *ngIf="emailEditar && !app.checkEmail(emailEditar)">E-mail inválido!</div>
                    </div>
                    <div class="col col-4">
                        <label>Senha:</label>
                        <div class="input-group mb-3">
                            <input class="form-control" autocomplete="off" [(ngModel)]="senhaEditar"
                                [type]="show ? 'text' : 'password'" (change)="salvarTemp('senha',senhaEditar)">
                            <div class="input-group-prepend" (click)="showSenha()">
                                <span class="input-group-text" id="basic-addon1"><fa-icon
                                        [icon]="['fas', 'eye']"></fa-icon></span>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div [hidden]="empresaAssociado != 'VIVA MAX'">
                    <label>Proponente Possui Problemas Pré Existentes?</label>
                    <div class="row">
                        <div class="col col-2">
                            <br>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="doencaSimEditar"
                                    (change)="selectDoencaEditar($event)" [checked]="doencaEditar == 'SIM'">
                                <label class="form-check-label" for="doencaSimEditar"
                                    style="margin-left:10px">SIM</label>
                            </div>
                        </div>
                        <div class="col col-2">
                            <br>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="doencaNaoEditar"
                                    (change)="selectDoencaEditar($event)" [checked]="doencaEditar == 'NÃO'">
                                <label class="form-check-label" for="doencaNaoEditar"
                                    style="margin-left:10px">NÃO</label>
                            </div>
                        </div>
                        <div class="col col-8" *ngIf="doencaEditar == 'SIM'">
                            <label>Especificar:</label>
                            <input type="text" class="form-control" [(ngModel)]="especificarDoencaEditar"
                                oninput="this.value = this.value.toUpperCase()"
                                (change)="salvarTemp('especificarDoenca',especificarDoencaEditar)">
                        </div>
                    </div>
                </div>
                <br>
                <br>
                 <div *ngIf="backup?.indicacaoPremiada == true">
                    <label>Proponente Possui Problemas Pré Existentes?</label>
                    <div class="row">
                        <div class="col col-12">
                           <label>Nome Indicação</label>
                           {{ backup?.nomeIndicacaoPremiada }}
                        </div>
                        <div class="col col-6">
                           <label>Matricula Indicação</label>
                           {{ backup?.matriculaIndicacaoPremiada }}
                        </div>
                        <div class="col col-6">
                           <label>Mês da Indicação</label>
                           {{ backup?.mesIndicacaoPremiada }}
                        </div>
                        <div class="col col-12" *ngIf="backup?.semDadosIndicacao">
                           <label>Dados Incompletos Marcados</label>
                        </div>
                    </div>
                    <br>
                <br>
                </div>
            </tab>
             <tab heading="DEPENDENTES" style="padding:10px" *ngIf="empresaAssociado != 'SEGUROS'" [ngStyle]="{'pointer-events': (situacaoEditar != 'CANCELADO' && db?.usuario?.permissoes['associados'] == 'EDITAR') ? 'auto' : 'none'}">
                <div class="row align-items-center">
                    <div class="col col-5"></div>
                    <div class="col col-3" style="text-align: center;">
                        <button class="btn btn-outline-primary" (click)="baixarCarteirinha('dep',-1)">
                            <fa-icon [icon]="['fas', 'address-card']" size="1x"></fa-icon> CARTEIRA TITULAR
                        </button>
                    </div>
                    <div class="col col-4" style="text-align: center;">
                        <button class="btn btn-success btn-block" (click)="salvarDependentes()">
                            <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;SALVAR DEPENDENTES
                        </button>
                    </div>
                </div>
                <br>
                <form [formGroup]="dependentesEditar">
                    <div class="row" formArrayName="dependenteEditar">
                        <div class="form-group col-3" style="text-align: left;"
                            [hidden]="listaDeDependentesEditar?.length > 0">
                            <br>
                            <button class="btn btn-outline-success btn-sm" (click)="addDependenteEditar('')">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE
                            </button>
                        </div>
                        <div class="form-group col-9" [hidden]="listaDeDependentesEditar?.length > 0">
                            &nbsp;
                        </div>
                        <div class="col col-12" *ngFor="let dependenteEditar of dependentesEditarFormGroup.controls; let i = index;">
                            <div [formGroupName]="i" class="row align-items-center">
                                <div class="form-group col-2">
                                    <label>Situação:</label>
                                    <select class="form-control" formControlName="situacao">
                                        <option value="ATIVO">ATIVO</option>
                                        <option value="SUSPENSO">SUSPENSO</option>
                                    </select>
                                </div>
                                <div class="form-group col-3">
                                    <label>Tipo:</label>
                                    <select class="form-control" formControlName="tipo" id="tipoEditar{{i}}" style="text-align: left;" (change)="tipoDependenteEditar(i)">
                                        <option value=""></option>
                                        <option value="CÔNJUGE">CÔNJUGE</option>
                                        <option value="FILHO">FILHO</option>
                                        <option value="FILHA">FILHA</option>
                                        <option value="OUTROS" *ngIf="empresaAssociado == 'CONECTA'">OUTROS</option>
                                    </select>
                                </div>
                                <div class="form-group col-7">
                                    <label>Nome:</label>
                                    <input type="text" class="form-control" formControlName="nome" oninput="this.value = this.value.toUpperCase()"
                                        [ngStyle]="{'border-color': (dependentesEditar.controls.dependenteEditar.value[i].nome == '' || dependentesEditar.controls.dependenteEditar.value[i].nome == null) ? '#dd443e' : null}">
                                </div>
                                <div class="form-group col-5" *ngIf="empresaAssociado == 'VIVA MAX' || hibrido">
                                    <label>ASF:</label>
                                    <select class="form-control" formControlName="asf" id="asfEditar{{i}}"
                                        (change)="selectAsfEditar()">
                                        <option value="FUNERAL AUTOMÁTICO ATÉ 18 ANOS" disabled>FUNERAL AUTOMÁTICO ATÉ
                                            18 ANOS</option>
                                        <option value="FUNERAL AUTOMÁTICO VITALÍCIO" disabled>FUNERAL AUTOMÁTICO
                                            VITALÍCIO</option>
                                        <option value="NÃO HÁ FUNERAL CONTRATADO">NÃO HÁ FUNERAL CONTRATADO</option>
                                        <option value="FUNERAL CONTRATADO">FUNERAL CONTRATADO</option>
                                    </select>
                                </div>
                                <div class="form-group col-3" *ngIf="empresaAssociado == 'VIVA MAX' || hibrido">
                                    <label>Sinistro?</label>
                                    <input type="text" class="form-control"
                                        *ngIf="dependentesEditar.controls.dependenteEditar.value[i].sinistro" readonly
                                        value="SIM">
                                    <input type="text" class="form-control"
                                        *ngIf="!dependentesEditar.controls.dependenteEditar.value[i].sinistro" readonly
                                        value="NÃO">
                                </div>
                                <div class="form-group col-2" *ngIf="empresaAssociado == 'VIVA MAX' || hibrido">
                                    <label>Nº Sorte</label>
                                    <input type="number" min="0" class="form-control" formControlName="numSorte">
                                </div>
                                <div class="form-group col-2" *ngIf="empresaAssociado == 'VIVA MAX' || hibrido">
                                    <div class="checkCor">
                                        <label class="form-check-label" for="corrCheckEditar{{i}}">Corretora</label><br>
                                        <input class="form-check-input" type="checkbox" id="corrCheckEditar{{i}}" formControlName="corretora">
                                    </div>
                                </div>
                                <div class="form-group col-2" *ngIf="empresaAssociado == 'CONECTA'">
                                    <div class="checkCor">
                                        <label class="form-check-label" for="conectaCheckEditar{{i}}">Conecta</label><br>
                                        <input class="form-check-input" [ngStyle]="{'pointer-events': !dependentesEditar.controls.dependenteEditar.value[i].conecta && (qtdeConectaDependentesEditar() >= maxDependentesConectaEditar) ? 'none' : 'all'}" type="checkbox" id="conectaCheckEditar{{i}}" formControlName="conecta" (change)="checkConectaEditar(dependentesEditar.controls.dependenteEditar.value[i])">
                                    </div>
                                </div>
                                <div class="form-group col-3">
                                    <label>Nascimento:</label>
                                    <input type="text" class="form-control" formControlName="nascimento" id="nascimentoEditar{{i}}" maxlength="10"
                                        onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}"
                                        [ngStyle]="{'border-color': (dependentesEditar.controls.dependenteEditar.value[i].nascimento == '' || dependentesEditar.controls.dependenteEditar.value[i].nascimento == null) ? '#dd443e' : null}">
                                </div>
                                <div class="form-group col-3">
                                    <label>CPF:</label>
                                    <input type="text" class="form-control" formControlName="cpf" cpf (keyup)="app.checkCPF(dependentesEditar.controls.dependenteEditar.value[i].cpf)"
                                        id="cpfEditar{{i}}" [textMask]="{mask: MASKS.cpf.textMask}"
                                        [ngStyle]="{'border-color': (dependentesEditar.controls.dependenteEditar.value[i].cpf == '' || dependentesEditar.controls.dependenteEditar.value[i].cpf == null || !app.checkCPF(dependentesEditar.controls.dependenteEditar.value[i].cpf)) ? '#dd443e' : null}">
                                        <div class="errorMsg" *ngIf="dependentesEditar.controls.dependenteEditar.value[i].cpf && !app.checkCPF(dependentesEditar.controls.dependenteEditar.value[i].cpf)">CPF incorreto!</div>
                                </div>
                                <div class="form-group col-3" *ngIf="empresaAssociado != 'VIVA MAX'">
                                    <label>Sexo:</label>
                                    <select class="form-control" formControlName="sexo" id="sexoEditar{{i}}"
                                    [ngStyle]="{'border-color': (dependentesEditar.controls.dependenteEditar.value[i].sexo == '' || dependentesEditar.controls.dependenteEditar.value[i].sexo == null) ? '#dd443e' : null}">
                                        <option value=""></option>
                                        <option value="M">MASCULINO</option>
                                        <option value="F">FEMININO</option>
                                    </select>
                                </div>
                                <div class="form-group col-9" *ngIf="empresaAssociado != 'VIVA MAX'">
                                    <label>E-mail:</label>
                                    <input type="text" class="form-control" id="emailEditar{{i}}" formControlName="email"
                                    (keyup)="app.checkEmail(dependentesEditar.controls.dependenteEditar.value[i].email)"
                                    [ngStyle]="{'border-color': (dependentesEditar.controls.dependenteEditar.value[i].email == '' || dependentesEditar.controls.dependenteEditar.value[i].email == null || !app.checkEmail(dependentesEditar.controls.dependenteEditar.value[i].email)) ? '#dd443e' : null}">
                                    <div class="errorMsg" *ngIf="dependentesEditar.controls.dependenteEditar.value[i].email && !app.checkEmail(dependentesEditar.controls.dependenteEditar.value[i].email)">E-mail inválido!</div>
                                </div>
                                <div style="width:100%;text-align:right">
                                    <br>
                                    <button class="btn btn-outline-primary" (click)="baixarCarteirinha('dep',i)" style="margin:5px">
                                        <fa-icon [icon]="['fas', 'address-card']" size="1x"></fa-icon>
                                    </button>
                                    <button class="btn btn-outline-danger" (click)="removeDependenteEditar(i)" style="margin:5px">
                                        <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                    </button>
                                    <button class="btn btn-outline-success" (click)="addDependenteEditar('')" style="margin:5px" [hidden]="empresaAssociado != 'VIVA MAX' && !hibridoEditar && qtdeConectaDependentesEditar() >= maxDependentesConectaEditar"
                                        [disabled]="(dependentesEditar.controls.dependenteEditar.value[i].nome == '' || dependentesEditar.controls.dependenteEditar.value[i].nome == null) || (dependentesEditar.controls.dependenteEditar.value[i].nascimento == '' || dependentesEditar.controls.dependenteEditar.value[i].nascimento == null) || (dependentesEditar.controls.dependenteEditar.value[i].conecta && (dependentesEditar.controls.dependenteEditar.value[i].cpf == '' || dependentesEditar.controls.dependenteEditar.value[i].cpf == null))">
                                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                    </button>
                                </div>
                            </div>
                            <hr>
                        </div>
                    </div>
                </form>
                <form [formGroup]="dependentesExtraEditar" *ngIf="empresaAssociado == 'VIVA MAX' || hibrido">
                    <div class="row" formArrayName="dependenteExtraEditar">
                        <div class="form-group col-3" style="text-align: left;"
                            [hidden]="listaDeDependentesExtraEditar?.length > 0">
                            <br>
                            <button class="btn btn-outline-success btn-sm" (click)="addDependenteExtraEditar('')">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE EXTRA
                            </button>
                        </div>
                        <div class="form-group col-9" [hidden]="listaDeDependentesExtraEditar?.length > 0">
                            &nbsp;
                        </div>
                        <div class="col col-12"
                            *ngFor="let dependenteExtraEditar of dependentesExtraEditarFormGroup.controls; let i = index;">
                            <div class="row align-items-center" [formGroupName]="i">
                                <div class="form-group col-2">
                                    <label>Situação:</label>
                                    <select class="form-control" formControlName="situacao">
                                        <option value="ATIVO">ATIVO</option>
                                        <option value="SUSPENSO">SUSPENSO</option>
                                    </select>
                                </div>
                                <div class="form-group col-3">
                                    <label>Tipo:</label>
                                    <select class="form-control" formControlName="tipo" (change)="tipoDependenteExtraEditar(i)" id="tipoEditar{{100+i}}" style="text-align: left;">
                                        <option value=""></option>
                                        <option value="MÃE">MÃE</option>
                                        <option value="PAI">PAI</option>
                                        <option value="SOGRO">SOGRO</option>
                                        <option value="SOGRA">SOGRA</option>
                                        <option value="OUTRO">OUTRO</option>
                                    </select>
                                </div>
                                <div class="form-group col-7">
                                    <label>Nome:</label>
                                    <input type="text" class="form-control" formControlName="nome"
                                        oninput="this.value = this.value.toUpperCase()"
                                        [ngStyle]="{'border-color': (dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nome == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nome == null || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].tipo == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].tipo == null) ? '#dd443e' : null}">
                                </div>
                                <div class="form-group col-5" *ngIf="empresaAssociado == 'VIVA MAX' || hibrido">
                                    <label>ASF:</label>
                                    <select class="form-control" formControlName="asf" id="asfExtraEditar{{i}}"
                                        (change)="selectAsfEditar()">
                                        <option value="FUNERAL AUTOMÁTICO ATÉ 18 ANOS" disabled>FUNERAL AUTOMÁTICO ATÉ
                                            18 ANOS</option>
                                        <option value="FUNERAL AUTOMÁTICO VITALÍCIO" disabled>FUNERAL AUTOMÁTICO
                                            VITALÍCIO</option>
                                        <option value="NÃO HÁ FUNERAL CONTRATADO">NÃO HÁ FUNERAL CONTRATADO</option>
                                        <option value="FUNERAL CONTRATADO">FUNERAL CONTRATADO</option>
                                    </select>
                                </div>
                                <div class="form-group col-3" *ngIf="empresaAssociado == 'VIVA MAX' || hibrido">
                                    <label>Sinistro?</label>
                                    <input type="text" class="form-control"
                                        *ngIf="dependentesExtraEditar.controls.dependenteExtraEditar.value[i].sinistro" readonly
                                        value="SIM">
                                    <input type="text" class="form-control"
                                        *ngIf="!dependentesExtraEditar.controls.dependenteExtraEditar.value[i].sinistro" readonly
                                        value="NÃO">
                                </div>
                                <div class="form-group col-2" *ngIf="empresaAssociado == 'VIVA MAX' || hibrido">
                                    <label>Nº Sorte</label>
                                    <input type="number" min="0" class="form-control" formControlName="numSorte">
                                </div>
                                <div class="form-group col-2" *ngIf="empresaAssociado == 'VIVA MAX' || hibrido">
                                    <div class="checkCor">
                                        <label class="form-check-label" for="corrCheckExtraEditard{{i}}">Corretora</label><br>
                                        <input class="form-check-input" type="checkbox" id="corrCheckExtraEditard{{i}}" formControlName="corretora">
                                    </div>
                                </div>
                                <div class="form-group col-2" *ngIf="empresaAssociado == 'CONECTA'">
                                    <div class="checkCor">
                                        <label class="form-check-label" for="conectaCheckExtraEditar{{i}}">Conecta</label><br>
                                        <input class="form-check-input" [ngStyle]="{'pointer-events': !dependentesExtraEditar.controls.dependenteExtraEditar.value[i].conecta && (qtdeConectaDependentesEditar() >= maxDependentesConectaEditar) ? 'none' : 'all'}" type="checkbox" id="conectaCheckExtraEditar{{i}}" formControlName="conecta" (change)="checkConectaExtraEditar(dependentesExtraEditar.controls.dependenteExtraEditar.value[i])">
                                    </div>
                                </div>
                                <div class="form-group col-3">
                                    <label>Nascimento:</label>
                                    <input type="text" class="form-control" formControlName="nascimento"
                                        id="nascimentoEditar{{100+i}}" maxlength="10"
                                        onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}"
                                        [ngStyle]="{'border-color': dependentesExtraEditar.controls.dependenteExtraEditar.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf == null) || (dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nascimento == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nascimento == null) || (dependentesExtraEditar.controls.dependenteExtraEditar.value[i].tipo == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].tipo == null)) ? '#dd443e' : null}">
                                </div>
                                <div class="form-group col-3">
                                    <label>CPF:</label>
                                    <input type="text" class="form-control" formControlName="cpf" cpf
                                        id="cpfEditar{{100+i}}" [textMask]="{mask: MASKS.cpf.textMask}" (keyup)="app.checkCPF(dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf)"
                                        [ngStyle]="{'border-color': dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf == null || !app.checkCPF(dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf) ? '#dd443e' : null}">
                                        <div class="errorMsg" *ngIf="dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf && !app.checkCPF(dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf)">CPF incorreto!</div>
                                 </div>
                                <div class="form-group col-3" *ngIf="empresaAssociado == 'CONECTA' || hibrido">
                                    <label>Sexo:</label>
                                    <select class="form-control" formControlName="sexo" id="sexoExtraEditar{{i}}"
                                    [ngStyle]="{'border-color': dependentesExtraEditar.controls.dependenteExtraEditar.value[i].conecta && (dependentesExtraEditar.controls.dependenteExtraEditar.value[i].sexo == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].sexo == null) ? '#dd443e' : null}">
                                        <option value=""></option>
                                        <option value="M">MASCULINO</option>
                                        <option value="F">FEMININO</option>
                                    </select>
                                </div>
                                <div class="form-group col-9" *ngIf="empresaAssociado == 'CONECTA' || hibrido">
                                    <label>E-mail:</label>
                                    <input type="text" class="form-control" id="emailExtraEditar{{i}}" formControlName="email"
                                    (keyup)="app.checkEmail(dependentesExtraEditar.controls.dependenteExtraEditar.value[i].email)"
                                    [ngStyle]="{'border-color': dependentesExtraEditar.controls.dependenteExtraEditar.value[i].conecta && (dependentesExtraEditar.controls.dependenteExtraEditar.value[i].email == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].email == null) ? '#dd443e' : null}">
                                    <div class="errorMsg" *ngIf="dependentesExtraEditar.controls.dependenteExtraEditar.value[i].email && !app.checkEmail(dependentesExtraEditar.controls.dependenteExtraEditar.value[i].email)">E-mail inválido!</div>
                                </div>
                                <div style="width:100%;text-align:right">
                                    <button class="btn btn-outline-primary" (click)="baixarCarteirinha('extra',i)" style="margin:5px">
                                        <fa-icon [icon]="['fas', 'address-card']" size="1x"></fa-icon>
                                    </button>
                                    <button class="btn btn-outline-danger" type="button" style="margin:5px" (click)="removeDependenteExtraEditar(i)">
                                        <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                    </button>
                                    <button class="btn btn-outline-success" type="button" style="margin:5px"
                                        (click)="addDependenteExtraEditar('')" [hidden]="empresaAssociado != 'VIVA MAX' && !hibridoEditar && qtdeConectaDependentesEditar() >= maxDependentesConectaEditar"
                                        [disabled]="((dependentesExtraEditar.controls.dependenteExtraEditar.value[i].tipo == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].tipo == null) || (dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nome == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nome == null)) || (dependentesExtraEditar.controls.dependenteExtraEditar.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nascimento == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nascimento == null) || ((dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf == null))))">
                                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                    </button>
                                </div>
                            </div>
                            <hr>
                        </div>
                    </div>
                </form>
            </tab>
            <tab heading="PLANO" style="padding:10px" [ngStyle]="{'pointer-events': situacaoEditar != 'CANCELADO' && (db?.usuario?.permissoes['conecta'] == 'EDITAR' || db?.usuario?.permissoes['associados'] == 'EDITAR') ? 'auto' : 'none'}">
                <div [hidden]="empresaAssociado != 'CONECTA'">
                    <!-- PLANO CONECTA-->
                    <div class="row align-items-center">
                        <div class="col col-4">
                            <label>ADESÃO E MANUTENÇÃO</label>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col col-3" *ngIf="situacaoEditar == 'ATIVO' || situacaoEditar == 'ALTERAÇÃO'">
                            <button class="btn btn-danger" (click)="converterPlano('seguros')">
                                CONVERTER PARA SEGUROS
                            </button>
                        </div>
                        <div class="col col-3" *ngIf="situacaoEditar == 'ATIVO' || situacaoEditar == 'ALTERAÇÃO'">
                            <button class="btn btn-danger" (click)="converterPlano('vivamax')">
                                CONVERTER PARA VIVA MAX
                            </button>
                        </div>
                        <div class="col col-3" style="text-align: center;">
                            <button class="btn btn-success btn-block" (click)="editarPlano()" [disabled]="!diaVencimentoEditar || !planoEditar || !formaPagamentoEditar">
                                <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;SALVAR ALTERAÇÕES
                            </button>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-4">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="planoFisicoCheckEditar"
                                    id="planoFisicoEditar" [disabled]="subContratoEditar || planoEmpresarialCheckEditar" (change)="escolhePlanoFisicoEditar(planoFisicoCheckEditar)">
                                <label class="form-check-label" for="planoFisicoEditar">&nbsp;&nbsp;Plano Físico
                                </label>
                            </div>
                        </div>
                        <div class="col col-4">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox"
                                    [(ngModel)]="planoEmpresarialCheckEditar" id="planoEmpresarialEditar"
                                    [disabled]="subContratoEditar || planoFisicoCheckEditar"
                                    (change)="escolhePlanoEmpresarialEditar(planoEmpresarialCheckEditar)">
                                <label class="form-check-label" for="planoEmpresarialEditar">&nbsp;&nbsp;Plano Empresarial
                                </label>
                            </div>
                        </div>
                        <div class="col col-4">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="valorPersonalizado"
                                    id="vlrPersonalizado">
                                <label class="form-check-label" for="vlrPersonalizado">&nbsp;&nbsp;Valor Personalizado
                                </label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-12">
                            <label>Sub-Contrato</label>
                            <select class="form-control" [(ngModel)]="subContratoEditar"
                                [disabled]="planoFisicoCheckEditar || planoEmpresarialCheckEditar"
                                (change)="escolheSubContratoEditar(subContratoEditar)">
                                <option value=""></option>
                                <option *ngFor="let sub of subContratos" [value]="sub.nome">{{sub.nome}}</option>
                            </select>
                        </div>
                    </div>
                    <br>
                    <div class="row align-items-center">
                        <div class="col col-4" *ngIf="planoEmpresarialCheckEditar">
                            <label>Plano:</label>
                            <select class="form-control" [(ngModel)]="planoEditar"
                                (change)="selectPlanoEditar('empresarial',planoEditar,'codigo')"
                                [ngStyle]="{'border-color': !planoEditar ? '#dd443e' : '#3cb42c'}">
                                <option *ngFor="let plan of planosEmpresariaisEditar" [value]="plan.nome">{{plan.nome}}
                                </option>
                            </select>
                        </div>
                        <div class="col col-4" *ngIf="planoFisicoCheckEditar">
                            <label>Plano:</label>
                            <select class="form-control" [(ngModel)]="planoEditar"
                                (change)="selectPlanoEditar('fisico',planoEditar,'codigo')"
                                [ngStyle]="{'border-color': !planoEditar ? '#dd443e' : '#3cb42c'}">
                                <option *ngFor="let plan of planosFisicosEditar" [value]="plan.nome">{{plan.nome}}</option>
                            </select>
                        </div>
                        <div class="col col-4" *ngIf="subContratoEditar">
                            <label>Plano:</label>
                            <select class="form-control" [(ngModel)]="planoEditar"
                                (change)="selectPlanoEditar('servidor',planoEditar,'codigo')"
                                [ngStyle]="{'border-color': !planoEditar ? '#dd443e' : '#3cb42c'}">
                                <option *ngFor="let plan of planosServidoresEditar" [value]="plan.nome">{{plan.nome}}</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Taxa Adesão:</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">R$</span>
                                </div>
                                <input type="number" class="form-control" [(ngModel)]="taxaAdesaoEditar"
                                    [disabled]="!valorPersonalizado">
                            </div>
                        </div>
                        <div class="col col-2">
                            <label>Mensalidade:</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">R$</span>
                                </div>
                                <input type="number" class="form-control" [(ngModel)]="mensalidadeEditar"
                                    [disabled]="!valorPersonalizado">
                            </div>
                        </div>
                        <div class="col col-2">
                            <label>Total:</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">R$</span>
                                </div>
                                <input type="number" class="form-control" [(ngModel)]="totalMensalidadeEditar"
                                    [disabled]="!valorPersonalizado">
                            </div>
                        </div>
                        <div class="col col-2">
                            <label>Vencimento:</label>
                                <input type="date" class="form-control" [(ngModel)]="diaVencimentoEditar" [ngStyle]="{'border-color': !diaVencimentoEditar ? '#dd443e' : '#3cb42c'}">
                        </div>
                    </div>
                        <label>FORMA DE PAGAMENTO</label>
                        <br><br>
                        <div class="row">
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="teledescontoFolhaEditar"
                                        [disabled]="formaPagamentoEditar == 'DEBITO RECORRENTE' || formaPagamentoEditar == 'BOLETO' || formaPagamentoEditar == 'CARNÊ' || formaPagamentoEditar == 'CONVÊNIO CELESC' || bloqueioPagamentos || formaPagamentoEditar == 'PIX'"
                                        (change)="selectFormaPagamentoEditar($event)">
                                    <label class="form-check-label" for="teledescontoFolhaEditar"
                                        style="margin-left:15px">DESCONTO EM FOLHA DE PAGAMENTO</label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="formaPagamentoEditar == 'DESCONTO EM FOLHA DE PAGAMENTO'"
                            style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                            <br>
                            <div class="row" style="margin-left:20px">
                                <div class="col col-4">
                                    <label>A PARTIR DO MÊS:</label>
                                    <input type="month" [(ngModel)]="mesFolhaPagamentoEditar" class="form-control">
                                </div>
                            </div>
                            <br>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="teledebitoRecorrenteEditar"
                                        [disabled]="formaPagamentoEditar == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamentoEditar == 'BOLETO' || formaPagamentoEditar == 'CARNÊ' || formaPagamentoEditar == 'CONVÊNIO CELESC' || formaPagamentoEditar == 'PIX'"
                                        (change)="selectFormaPagamentoEditar($event)">
                                    <label class="form-check-label" for="teledebitoRecorrenteEditar"
                                        style="margin-left:15px">DEBITO RECORRENTE</label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="formaPagamentoEditar == 'DEBITO RECORRENTE'"
                            style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                            <br>
                            <div class="row" style="margin-left:20px">
                                <div class="col col-2" *ngFor="let cartao of cartoes; let i = index" style="padding:10px">
                                    <div class="form-check" style="padding:10px">
                                        <input class="form-check-input" type="checkbox" id="telecardEditar{{i}}" name="cards" (change)="cartaoSelectEditar($event,i)" [checked]="this.cartaoSelecionadoEditar == cartao">
                                        <label class="form-check-label" for="telecardEditar{{i}}" style="margin-left:15px">{{ cartao | uppercase }}</label>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div style="padding:10px">
                                <div class="row">
                                    <div class="col col-4">
                                        <label>Nome</label>&nbsp;<small>(idêntico ao do cartão de crédito)</small>
                                        <input type="text" class="form-control" [(ngModel)]="nomeCartaoEditar"
                                            oninput="this.value = this.value.toUpperCase()">
                                    </div>
                                    <div class="col col-4">
                                        <label>Número do Cartão</label>
                                        <input type="text" class="form-control" [(ngModel)]="numeroCartaoEditar"
                                            maxlength="19"
                                            onkeyup="var val = this.value;var newval = '';val = val.replace(/\s/g, '');for(var i = 0; i < val.length; i++) {if(i%4 == 0 && i > 0) newval = newval.concat(' ');newval = newval.concat(val[i]);}this.value = newval;">
                                    </div>
                                    <div class="col col-2">
                                        <label>Validade</label>
                                        <input type="text" class="form-control" [(ngModel)]="validadeCartaoEditar"
                                            maxlength="5"
                                            onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v}">
                                    </div>
                                    <div class="col col-2">
                                        <label>Código Segurança</label>
                                        <input type="text" class="form-control" [(ngModel)]="codigoCartaoEditar">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="teleboletoMensalEditar"
                                        [disabled]="formaPagamentoEditar == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamentoEditar == 'DEBITO RECORRENTE' || formaPagamentoEditar == 'CARNÊ' || formaPagamentoEditar == 'CONVÊNIO CELESC' || formaPagamentoEditar == 'PIX'"
                                        (change)="selectFormaPagamentoEditar($event)">
                                    <label class="form-check-label" for="teleboletoMensalEditar"
                                        style="margin-left:15px">BOLETO</label>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="telepixMensalEditar"
                                        [disabled]="formaPagamentoEditar == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamentoEditar == 'DEBITO RECORRENTE' || formaPagamentoEditar == 'CARNÊ' || formaPagamentoEditar == 'CONVÊNIO CELESC' || formaPagamentoEditar == 'BOLETO'"
                                        (change)="selectFormaPagamentoEditar($event)">
                                    <label class="form-check-label" for="telepixMensalEditar"
                                        style="margin-left:15px">PIX</label>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="telecarneEditar"
                                        [disabled]="formaPagamentoEditar == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamentoEditar == 'BOLETO' || formaPagamentoEditar == 'DEBITO RECORRENTE' || formaPagamentoEditar == 'CONVÊNIO CELESC' || formaPagamentoEditar == 'PIX'"
                                        (change)="selectFormaPagamentoEditar($event)">
                                    <label class="form-check-label" for="telecarneEditar"
                                        style="margin-left:15px">CARNÊ</label>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row" *ngIf="estadoEditar == 'SC'">
                            <div class="col col-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="celescEditar"
                                        [disabled]="formaPagamentoEditar == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamentoEditar == 'BOLETO' || formaPagamentoEditar == 'DEBITO RECORRENTE' || formaPagamentoEditar == 'PIX' || formaPagamentoEditar == 'CARNÊ'"
                                        (change)="selectFormaPagamentoEditar($event)">
                                    <label class="form-check-label" for="celescEditar" style="margin-left:15px">CONVÊNIO
                                        CELESC</label>
                                </div>
                            </div>
                            <div class="col col-4" *ngIf="formaPagamentoEditar == 'CONVÊNIO CELESC'">
                                <label>Unidade Consumidora</label>
                                <input type="text" pattern="\d*" class="form-control" style="text-align:center"
                                    placeholder="Código Unidade Consumidora" min="0"
                                    [(ngModel)]="unidadeConsumidoraEditar" maxlength="13">
                            </div>
                        </div>
                    <div style="height:4em"></div>
                </div>
                <div [hidden]="empresaAssociado == 'CONECTA'">
                    <!-- PLANO VIVA MAX ou SEGUROS-->
                    <div class="row align-items-center">
                        <div class="col col-3">
                            <label>ADESÃO E MANUTENÇÃO</label>
                        </div>
                        <div class="col col-3" *ngIf="(situacaoEditar == 'ATIVO' || situacaoEditar == 'ALTERAÇÃO') && empresaAssociado == 'VIVA MAX'">
                            <button class="btn btn-danger" (click)="converterPlano('seguros')">
                                CONVERTER PARA SEGUROS
                            </button>
                        </div>
                        <div class="col col-3" *ngIf="(situacaoEditar == 'ATIVO' || situacaoEditar == 'ALTERAÇÃO') && empresaAssociado == 'SEGUROS'">
                            <button class="btn btn-danger" (click)="converterPlano('vivamax')">
                                CONVERTER PARA VIVA MAX
                            </button>
                        </div>                        
                        <div class="col col-3" *ngIf="situacaoEditar == 'ATIVO' || situacaoEditar == 'ALTERAÇÃO'">
                            <button class="btn btn-primary" (click)="converterPlanoParaConecta(empresaAssociado)">
                                CONVERTER PARA CONECTA
                            </button>
                        </div>
                        <div class="col col-3" style="text-align: center;" [hidden]="planoEmpresarialCheckEditar">
                            <button class="btn btn-success btn-block" (click)="editarPlano()"
                                [disabled]="!diaVencimentoEditar || !planoEditar || !formaPagamentoEditar || (!planoFisicoCheckEditar && !subContratoEditar) || (subContratoEditar && !mesFolhaPagamentoEditar)">
                                <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;SALVAR ALTERAÇÕES
                            </button>
                        </div>
                        <div class="col col-3" style="text-align: center;" *ngIf="planoEmpresarialCheckEditar">
                            <button class="btn btn-success btn-block" (click)="editarPlanoEmpresarial()">
                                <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;SALVAR ALTERAÇÕES
                            </button>
                        </div>
                    </div>
                    <br>
                    <div class="row" *ngIf="empresaAssociado == 'VIVA MAX'">
                        <div class="col col-4">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="planoFisicoCheckEditar"
                                    id="planoFisicoEditar"
                                    [disabled]="subContratoEditar || planoEmpresarialCheckEditar"
                                    (change)="escolhePlanoFisicoEditar(planoFisicoCheckEditar)">
                                <label class="form-check-label" for="planoFisicoEditar">&nbsp;&nbsp;Plano Físico
                                </label>
                            </div>
                        </div>
                        <div class="col col-4">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="planoEmpresarialCheckEditar"
                                    id="planoEmpresarialEditar"
                                    [disabled]="subContratoEditar || planoFisicoCheckEditar"
                                    (change)="escolhePlanoEmpresarialEditar(planoEmpresarialCheckEditar)">
                                <label class="form-check-label" for="planoEmpresarialEditar">&nbsp;&nbsp;Plano Empresarial
                                </label>
                            </div>
                        </div>
                        <div class="col col-4">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="valorPersonalizado"
                                    id="vlrPersonalizado">
                                <label class="form-check-label" for="vlrPersonalizado">&nbsp;&nbsp;Valor Personalizado
                                </label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-8" [hidden]="planoEmpresarialCheckEditar">
                            <label>Sub-Contrato</label>
                            <select class="form-control" [(ngModel)]="subContratoEditar"
                                [disabled]="planoFisicoCheckEditar || planoEmpresarialCheckEditar"
                                (change)="escolheSubContratoEditar(subContratoEditar)">
                                <option [value]=""></option>
                                <option *ngFor="let sub of subContratos" [value]="sub.nome">{{sub.nome}}</option>
                            </select>
                        </div>
                        <div class="col col-12" *ngIf="planoEmpresarialCheckEditar">
                            <label>Empresa</label>
                             <ng-select class="form-control" [items]="empresas" bindLabel="razaoEmpresa" appendTo="body"
                                [(ngModel)]="empresa" (change)="selectEmpresaEditar(empresa)">
                            </ng-select>
                        </div>
                        <div class="col col-4" *ngIf="subContratoEditar">
                            <label>Plano:</label>
                            <select class="form-control" [(ngModel)]="planoEditar"
                                (change)="selectPlanoEditar('servidor',planoEditar,'codigo')"
                                [ngStyle]="{'border-color': !planoEditar ? '#dd443e' : '#3cb42c'}">
                                <option *ngFor="let plan of planosServidoresEditar" [value]="plan.nome">{{plan.nome}}
                                </option>
                            </select>
                        </div>
                        <div class="col col-4" *ngIf="planoFisicoCheckEditar">
                            <label>Plano:</label>
                            <select class="form-control" [(ngModel)]="planoEditar"
                                (change)="selectPlanoEditar('fisico',planoEditar,'codigo')"
                                [ngStyle]="{'border-color': !planoEditar ? '#dd443e' : '#3cb42c'}">
                                <option *ngFor="let plan of planosFisicosEditar" [value]="plan.nome">{{plan.nome}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <br>
                    <div class="row align-items-center" [hidden]="planoEmpresarialCheckEditar">
                       <div class="col col-2">
                            <label>Dia Vencimento:</label>
                            <input type="date" class="form-control" [(ngModel)]="diaVencimentoEditar"
                                [ngStyle]="{'border-color': !diaVencimentoEditar ? '#dd443e' : '#3cb42c'}">
                        </div>
                        <div class="col col-2" *ngIf="primeiroVencimentoEditar">
                            <label>1ª Parcela</label><br>
                            <input type="date" class="form-control" [(ngModel)]="primeiroVencimentoEditar" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Taxa Adesão:</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">R$</span>
                                </div>
                                <input type="number" class="form-control" [(ngModel)]="taxaAdesaoEditar"
                                    [disabled]="!valorPersonalizado">
                            </div>
                        </div>
                        <div class="col col-2">
                            <label>Mensalidade:</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">R$</span>
                                </div>
                                <input type="number" class="form-control" [(ngModel)]="mensalidadeEditar"
                                    (change)="calcularDependentes()" [disabled]="!valorPersonalizado">
                            </div>
                        </div>
                        <div class="col col-2">
                            <label>Total ASF:</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">R$</span>
                                </div>
                                <input type="number" class="form-control" [(ngModel)]="totalMensalidadeEditar"
                                    [disabled]="!valorPersonalizado">
                            </div>
                        </div>
                        <div class="col col-2">
                            <label>Capital:</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">R$</span>
                                </div>
                                <input type="number" class="form-control" [(ngModel)]="valorCapitalSeguradoEditar" readonly>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="planoEmpresarialCheckEditar">
                        <label>FORMA DE PAGAMENTO</label>
                        <br><br>
                        <div class="row">
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="descontoFolhaEditar"
                                        [disabled]="formaPagamentoEditar == 'CONVÊNIO CELESC' || formaPagamentoEditar == 'DEBITO RECORRENTE' || formaPagamentoEditar == 'BOLETO' || formaPagamentoEditar == 'CARNÊ' || bloqueioPagamentos"
                                        (change)="selectFormaPagamentoEditar($event)">
                                    <label class="form-check-label" for="descontoFolhaEditar"
                                        style="margin-left:15px">DESCONTO EM FOLHA DE PAGAMENTO</label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="formaPagamentoEditar == 'DESCONTO EM FOLHA DE PAGAMENTO'"
                            style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                            <br>
                            <div class="row" style="margin-left:20px">
                                <div class="col col-4">
                                    <label>A PARTIR DO MÊS:</label>
                                    <input type="month" [(ngModel)]="mesFolhaPagamentoEditar" class="form-control">
                                </div>
                            </div>
                            <br>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="debitoRecorrenteEditar"
                                        [disabled]="formaPagamentoEditar == 'CONVÊNIO CELESC' || formaPagamentoEditar == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamentoEditar == 'BOLETO' || formaPagamentoEditar == 'CARNÊ'"
                                        (change)="selectFormaPagamentoEditar($event)">
                                    <label class="form-check-label" for="debitoRecorrenteEditar"
                                        style="margin-left:15px">DEBITO RECORRENTE</label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="formaPagamentoEditar == 'CONVÊNIO CELESC' || formaPagamentoEditar == 'DEBITO RECORRENTE'"
                            style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                            <br>
                            <div class="row" style="margin-left:20px">
                                <div class="col col-2" *ngFor="let cartao of cartoes; let i = index" style="padding:10px">
                                    <div class="form-check" style="padding:10px">
                                        <input class="form-check-input" type="checkbox" id="cardEditar{{i}}" name="cards" (change)="cartaoSelectEditar($event,i)" [checked]="this.cartaoSelecionadoEditar == cartao">
                                        <label class="form-check-label" for="cardEditar{{i}}" style="margin-left:15px">{{cartao | uppercase}}</label>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div style="padding:10px">
                                <div class="row" *ngIf="cartaoSelecionadoEditar != ''">
                                    <div class="col col-4">
                                        <label>Nome</label>&nbsp;<small>(idêntico ao do cartão de crédito)</small>
                                        <input type="text" class="form-control" [(ngModel)]="nomeCartaoEditar"
                                            oninput="this.value = this.value.toUpperCase()">
                                    </div>
                                    <div class="col col-4">
                                        <label>Número do Cartão</label>
                                        <input type="text" class="form-control" [(ngModel)]="numeroCartaoEditar"
                                            maxlength="19"
                                            onkeyup="var val = this.value;var newval = '';val = val.replace(/\s/g, '');for(var i = 0; i < val.length; i++) {if(i%4 == 0 && i > 0) newval = newval.concat(' ');newval = newval.concat(val[i]);}this.value = newval;">
                                    </div>
                                    <div class="col col-2">
                                        <label>Validade</label>
                                        <input type="text" class="form-control" [(ngModel)]="validadeCartaoEditar"
                                            maxlength="5"
                                            onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v}">
                                    </div>
                                    <div class="col col-2">
                                        <label>Código Segurança</label>
                                        <span *ngIf="db?.usuario?.permissoes['associados'] != 'EDITAR'"><br>* * * </span>
                                        <input type="text" class="form-control" [(ngModel)]="codigoCartaoEditar"
                                            *ngIf="db?.usuario?.permissoes['associados'] == 'EDITAR'">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="boletoMensalEditar"
                                        [disabled]="formaPagamentoEditar == 'CONVÊNIO CELESC' || formaPagamentoEditar == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamentoEditar == 'DEBITO RECORRENTE' || formaPagamentoEditar == 'CARNÊ'"
                                        (change)="selectFormaPagamentoEditar($event)">
                                    <label class="form-check-label" for="boletoMensalEditar"
                                        style="margin-left:15px">BOLETO</label>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="carneEditar"
                                        [disabled]="formaPagamentoEditar == 'CONVÊNIO CELESC' || formaPagamentoEditar == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamentoEditar == 'BOLETO' || formaPagamentoEditar == 'DEBITO RECORRENTE'"
                                        (change)="selectFormaPagamentoEditar($event)">
                                    <label class="form-check-label" for="carneEditar" style="margin-left:15px">CARNÊ</label>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row" *ngIf="estadoEditar == 'SC'">
                            <div class="col col-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="celescEditar"
                                        [disabled]="formaPagamentoEditar == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamentoEditar == 'BOLETO' || formaPagamentoEditar == 'DEBITO RECORRENTE' || formaPagamentoEditar == 'CARNÊ'"
                                        (change)="selectFormaPagamentoEditar($event)">
                                    <label class="form-check-label" for="celescEditar" style="margin-left:15px">CONVÊNIO
                                        CELESC</label>
                                </div>
                            </div>
                            <div class="col col-4" *ngIf="formaPagamentoEditar == 'CONVÊNIO CELESC'">
                                <label>Unidade Consumidora</label>
                                <input type="text" pattern="\d*" class="form-control" style="text-align:center"
                                    placeholder="Código Unidade Consumidora" min="0" [(ngModel)]="unidadeConsumidoraEditar"
                                    maxlength="13">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="planoEmpresarialCheckEditar">
                        <div class="row">
                            <div class="col col-3">
                                <label>Código</label>
                                <input type="text" class="form-control" [(ngModel)]="codigoEmpresa" readonly>
                            </div>
                            <div class="col col-9">
                                <label>Empresa</label>
                                <input type="text" class="form-control" [(ngModel)]="localTrabalhoEditar" readonly>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-6">
                                <label>Plano:</label>
                                <select class="form-control" [(ngModel)]="planoEditar"
                                    (change)="selectPlanoEditar('empresarial',planoEditar,'codigo')"
                                    [ngStyle]="{'border-color': !planoEditar ? '#dd443e' : '#3cb42c'}">
                                    <option *ngFor="let plan of planosEmpresariaisEditar" [value]="plan.nome">{{plan.nome}}
                                    </option>
                                </select>
                            </div>
                            <div class="col col-2">
                                <br>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="valorPersonalizado"
                                        id="vlrPersonalizado">
                                    <label class="form-check-label" for="vlrPersonalizado">&nbsp;&nbsp;Valor Personalizado
                                    </label>
                                </div>
                            </div>
                            <div class="col col-2">
                                <label>Mensalidade:</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">R$</span>
                                    </div>
                                    <input type="number" class="form-control" [(ngModel)]="mensalidadeEditar"
                                        [disabled]="!valorPersonalizado">
                                </div>
                            </div>
                            <div class="col col-2">
                                <label>Total com ASF:</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">R$</span>
                                    </div>
                                    <input type="number" class="form-control" [(ngModel)]="totalMensalidadeEditar"
                                        [disabled]="!valorPersonalizado">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>
            <tab heading="ATENDIMENTO" style="padding:10px" *ngIf="db?.usuario?.permissoes['atendimento'] != 'NENHUMA'">
                <div *ngIf="db?.usuario?.permissoes['atendimento'] == 'EDITAR'">
                    <div class="row align-items-center">
                        <div class="col col-6">
                            <label>NOVO</label>
                            <select class="form-control" [(ngModel)]="motivo" (change)="checkMotivo(motivo)">
                                <option value=""></option>
                                <option *ngFor="let motivo of db.motivosAtendimento" [value]="motivo.nome">
                                    {{motivo.nome}}</option>
                            </select>
                        </div>
                        <div class="col col-3" style="text-align:right" *ngIf="!novoAgendamento">
                            <br>
                            <button class="btn btn-success btn-block" [disabled]="!motivo || !mensagem"
                                (click)="cadastrarHistorico()">ENVIAR</button>
                        </div>
                    </div>
                    <br>
                    <div class="row" style="padding:10px" *ngIf="!novoAgendamento">
                        <div class="col">
                            <textarea class="form-control" [(ngModel)]="mensagem" rows="4"></textarea>
                        </div>
                    </div>
                </div>
                <hr>
                <div *ngIf="novoAgendamento"
                    [ngStyle]="{'pointer-events': db?.usuario?.permissoes['atendimento'] == 'EDITAR' ? 'auto' : 'none'}">
                    <br>
                    <div class="row">
                        <div class="col col-6">
                            <label>Paciente:</label>
                            <select class="form-control" [(ngModel)]="pacienteMedico">
                                <option selected></option>
                                <option [value]="nomeEditar">{{nomeEditar}} || TITULAR</option>
                                <option *ngFor="let dependente of todosDependentes" [value]="dependente.nome">
                                    {{dependente.nome}} || {{dependente.tipo}}</option>
                            </select>
                        </div>
                        <div class="col col-4">
                            <label>Telefone para Contato</label>
                            <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                                [(ngModel)]="telefoneContatoMedico">
                        </div>
                        <div class="col col-2" style="text-align: center;">
                            <div class="form-check">
                                <label class="form-check-label" for="whatsappContatoMedico" style="padding-bottom:1em">
                                    Whatsapp
                                </label>
                                <br>
                                <input class="form-check-input" type="checkbox" id="whatsappContatoMedico"
                                    [(ngModel)]="whatsappContatoMedico">
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-4">
                            <label>Telefone para Recado</label>
                            <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                                [(ngModel)]="telefoneRecadoMedico">
                        </div>
                        <div class="col col-2" style="text-align: center;">
                            <div class="form-check">
                                <label class="form-check-label" for="whatsappRecadoMedico" style="padding-bottom:1em">
                                    Whatsapp
                                </label>
                                <br>
                                <input class="form-check-input" type="checkbox" id="whatsappRecadoMedico"
                                    [(ngModel)]="whatsappRecadoMedico">
                            </div>
                        </div>
                        <div class="col col-3">
                            <label>Já é Paciente</label>
                            <select class="form-control" [(ngModel)]="jaEPacienteMedico">
                                <option selected></option>
                                <option value="SIM">SIM</option>
                                <option value="NÃO">NÃO</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Guia do SUS</label>
                            <select class="form-control" [(ngModel)]="guiaSusMedico">
                                <option selected></option>
                                <option value="SIM">SIM</option>
                                <option value="NÃO">NÃO</option>
                            </select>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-3">
                            <label>Procedimento</label>
                            <select class="form-control" [(ngModel)]="procedimentoMedico">
                                <option selected></option>
                                <option value="CONSULTA">CONSULTA</option>
                                <option value="EXAME">EXAME</option>
                            </select>
                        </div>
                        <div class="col col-3">
                            <label>Especialidade</label>
                            <select [(ngModel)]="especialidadeMedico" class="form-control">
                                <option selected></option>
                                <option *ngFor="let esp of todasEspecialidades" [value]="esp.nome">{{esp.nome}}</option>
                            </select>
                        </div>
                        <div class="col col-3">
                            <label>Médico(a) Preferencial</label>
                            <select class="form-control" (change)="medicoChange()"
                                [(ngModel)]="medicoPreferencialMedico">
                                <option selected></option>
                                <option value="SIM">SIM</option>
                                <option value="NÃO">NÃO</option>
                            </select>
                        </div>
                        <div class="col col-3">
                            <label>Nome do Médico(a)</label>
                            <input type="text" class="form-control" [(ngModel)]="medicoPreferencia"
                                [disabled]="medicoPreferencialMedico != 'SIM'"
                                oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-3">
                            <label>1ª Data Preferencial</label>
                            <input type="date" (change)="verificaData(primeiraDataMedico)" class="form-control"
                                [(ngModel)]="primeiraDataMedico"
                                [ngStyle]="{'border-color': (primeiraDataMedico && primeiraDataMedico < dataHoje) ? '#dd443e' : null}">
                        </div>
                        <div class="col col-3">
                            <label>1ª Periodo Preferencial</label>
                            <select class="form-control" (change)="medicoChange()" [(ngModel)]="primeiroPeriodoMedico">
                                <option selected></option>
                                <option value="MANHÃ">MANHÃ</option>
                                <option value="TARDE">TARDE</option>
                            </select>
                        </div>
                        <div class="col col-3">
                            <label>2ª Data Preferencial</label>
                            <input type="date" (change)="verificaData(segundaDataMedico)" class="form-control"
                                [(ngModel)]="segundaDataMedico"
                                [ngStyle]="{'border-color': (segundaDataMedico && segundaDataMedico < dataHoje) ? '#dd443e' : null}">
                        </div>
                        <div class="col col-3">
                            <label>2ª Periodo Preferencial</label>
                            <select class="form-control" (change)="medicoChange()" [(ngModel)]="segundoPeriodoMedico">
                                <option selected></option>
                                <option value="MANHÃ">MANHÃ</option>
                                <option value="TARDE">TARDE</option>
                            </select>
                        </div>
                    </div>
                    <br>
                    <hr>
                    <h5>Credenciado</h5>
                    <div class="row">
                        <div class="col col-6">
                            <label>Laboratório/Médico</label>
                            <input type="text" class="form-control" [(ngModel)]="laboratorioMedico"
                                oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <!-- <div class="col col-3">
                                    <label>Credenciado</label>
                                    <select class="form-control" [(ngModel)]="laboratorioMedico" (change)="getProfissionalByCredenciado(laboratorioMedico)">
                                        <option selected value=""></option>
                                        <option *ngFor="let cred of credenciados" [value]="cred.razaoCredenciado">{{cred.razaoCredenciado}}</option>
                                    </select>  
                                </div>
                                <div class="col col-3">
                                    <label>Profissional</label>
                                    <select class="form-control" [(ngModel)]="profissionalMedico">
                                        <option selected value=""></option>
                                        <option *ngFor="let prof of listaProfissionais" [value]="prof.profissional">{{prof.profissional}}</option>
                                    </select>  
                                </div> -->
                        <div class="col col-3">
                            <label>Telefone</label>
                            <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                                [(ngModel)]="telefoneMedico">
                        </div>
                        <div class="col col-3">
                            <label>Secretária</label>
                            <input type="text" class="form-control" oninput="this.value = this.value.toUpperCase()"
                                [(ngModel)]="secretariaMedico">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-4">
                            <label>Agendado Para</label>
                            <input type="date" (change)="verificaData(agendadoParaMedico)" class="form-control"
                                [(ngModel)]="agendadoParaMedico"
                                [ngStyle]="{'border-color': (agendadoParaMedico && agendadoParaMedico < dataHoje) ? '#dd443e' : null}">
                        </div>
                        <div class="col col-4">
                            <label>Horário</label>
                            <input type="time" class="form-control" [(ngModel)]="horarioMedico">
                        </div>
                        <div class="col col-4">
                            <label>Guia Emitida</label>
                            <input type="date" (change)="verificaData(guiaEmitidaMedico)" class="form-control"
                                [(ngModel)]="guiaEmitidaMedico">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-3">
                            <label>Valor da Consulta</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">R$</span>
                                </div>
                                <input type="number" class="form-control" [(ngModel)]="valorConsultaMedica">
                            </div>
                        </div>
                        <div class="col col-3">
                            <label>Forma de Pagamento</label>
                            <select class="form-control" [(ngModel)]="formaPagamentoMedico">
                                <option value="CARTÃO">CARTÃO</option>
                                <option value="DINHEIRO">DINHEIRO</option>
                            </select>
                        </div>
                        <div class="col col-3" *ngIf="formaPagamentoMedico == 'DINHEIRO'">
                            <label>Precisa Troco?</label>
                            <select class="form-control" [(ngModel)]="precisaTrocoMedico">
                                <option value="SIM">SIM</option>
                                <option value="NÃO">NÃO</option>
                            </select>
                        </div>
                        <div class="col col-3" *ngIf="precisaTrocoMedico == 'SIM'">
                            <label>Para Quanto?</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">R$</span>
                                </div>
                                <input type="number" class="form-control" [(ngModel)]="trocoMedico">
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="guiaCredenciado"
                                    [(ngModel)]="levarGuiaMedico">
                                <label class="form-check-label" for="guiaCredenciado" style="margin-left:10px">
                                    LEVAR GUIA
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="retiraGuia"
                                    [(ngModel)]="retiraGuiaMedico">
                                <label class="form-check-label" for="retiraGuia" style="margin-left:10px">
                                    RETIRAR GUIA NO ESCRITÓRIO
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="semGuiMedico"
                                    [(ngModel)]="semGuiaMedico">
                                <label class="form-check-label" for="semGuiMedico" style="margin-left:10px">
                                    SEM GUIA DE ATENDIMENTO
                                </label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row" style="padding:10px">
                        <div class="col">
                            <label>Observações</label>
                            <textarea class="form-control" [(ngModel)]="observacaoMedico" rows="4"
                                oninput="this.value = this.value.toUpperCase()"></textarea>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col">
                            <button class="btn btn-success btn-block" (click)="criarAgendamento()"
                                [disabled]="((agendadoParaMedico && agendadoParaMedico < dataHoje) || (primeiraDataMedico && primeiraDataMedico < dataHoje) || (segundaDataMedico && segundaDataMedico < dataHoje))">
                                <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;CRIAR AGENDAMENTO MÉDICO
                            </button>
                        </div>
                    </div>
                    <br><br><br>
                </div>
                <hr>
                <div style="text-align: center; font-weight: bold; font-size:1.2em; color:#c7444a; padding:1em">
                    AGENDAMENTOS
                </div>
                <div class="row" style="text-align: center; font-weight: bold;">
                    <div class="col col-1">
                        Criado
                    </div>
                    <div class="col col-2">
                        Procedimento
                    </div>
                    <div class="col col-3">
                        Paciente
                    </div>
                    <div class="col col-2">
                        Especialidade
                    </div>
                    <div class="col col-1">
                        Agendado
                    </div>
                    <div class="col col-1">
                        Emitida
                    </div>
                    <div class="col col-1" *ngIf="db?.usuario?.permissoes['atendimento'] != 'NENHUMA'"></div>
                    <div class="col col-1" *ngIf="db?.usuario?.permissoes['atendimento'] == 'EDITAR'"></div>
                </div>
                <div class="rolAgendamentos">
                    <div *ngFor="let agenda of agendamentos; let i = index; let o = even">
                        <div class="row" style="text-align: center; padding-bottom:5px"
                            [ngStyle]="{'background-color': o ? '#dadada' : 'null'}">
                            <div class="col col-1">
                                {{ agenda?.dataAgendamento | date: 'dd/MM/yyyy' }}
                            </div>
                            <div class="col col-2">
                                {{ agenda?.procedimentoMedico }}
                            </div>
                            <div class="col col-3">
                                {{ agenda?.pacienteMedico }}
                            </div>
                            <div class="col col-2">
                                {{ agenda?.especialidadeMedico }}
                            </div>
                            <div class="col col-1">
                                {{ agenda?.agendadoParaMedico | date: 'dd/MM/yyyy' }}
                            </div>
                            <div class="col col-1">
                                {{ agenda?.guiaEmitidaMedico | date: 'dd/MM/yyyy' }}
                            </div>
                            <div class="col col-1" *ngIf="db?.usuario?.permissoes['atendimento'] != 'NENHUMA'">
                                <button class="btn btn-outline-success btn-sm" (click)="editarAgenda(agenda)">
                                    <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                                </button>
                            </div>
                            <div class="col col-1" *ngIf="db?.usuario?.permissoes['atendimento'] == 'EDITAR'">
                                <button class="btn btn-outline-danger btn-sm" (click)="excluirAgenda(agenda)">
                                    <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <br>
                <div *ngFor="let historico of historicos">
                    <div class="card">
                        <h5 class="card-header" style='background-color: #274562; color:white'>{{historico?.motivo}}
                        </h5>
                        <div class="card-body">
                            <h5 class="card-title">{{historico?.data | date:'dd/MM/yyyy'}} - {{historico?.hora}}</h5>
                            <h6 class="card-title">Atendente: {{historico?.usuario}}</h6>
                            <p class="card-text">{{historico?.msg}}</p>
                        </div>
                    </div>
                </div>
            </tab>
            <tab heading="SINISTROS" style="padding:10px" *ngIf="db?.usuario?.permissoes['sinistro'] != 'NENHUMA'">
                <table class="table table-striped" style="text-align: center;" [hidden]="editSinitro">
                    <thead class="thead-dark">
                        <tr>
                            <th>Nome</th>
                            <th>Tipo</th>
                            <th>Sinistro</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{nomeEditar}}</td>
                            <td>TITULAR</td>
                            <td>
                                <div *ngIf="backup?.sinistro?.indenizacaoSinistro == true">
                                    INDENIZAÇÃO&nbsp;&nbsp;
                                </div>
                                <div *ngIf="backup?.sinistro?.assistenciaSinistro == true">
                                    ASSISTÊNCIA&nbsp;&nbsp;
                                </div>
                                <div *ngIf="backup?.sinistro?.servicosSinistro == true">
                                    SERVIÇOS&nbsp;&nbsp;
                                </div>
                            </td>
                            <button class="btn btn-outline-success" (click)="openSinistro('titular',backup)"
                                *ngIf="db?.usuario?.permissoes['sinistro'] != 'NENHUMA'">
                                <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                            </button>
                        </tr>
                        <tr *ngFor="let dependente of todosDependentes; let i = index">
                            <td>{{ dependente?.nome }}</td>
                            <td>{{ dependente?.tipo }}</td>
                            <td>
                                <div *ngIf="dependente?.sinistro?.indenizacaoSinistro == true">
                                    INDENIZAÇÃO&nbsp;&nbsp;
                                </div>
                                <div *ngIf="dependente?.sinistro?.assistenciaSinistro == true">
                                    ASSISTÊNCIA&nbsp;&nbsp;
                                </div>
                                <div *ngIf="dependente?.sinistro?.servicosSinistro == true">
                                    SERVIÇOS&nbsp;&nbsp;
                                </div>
                            </td>
                            <td>
                                <button class="btn btn-outline-success" (click)="openSinistro('dependente',dependente)"
                                    *ngIf="db?.usuario?.permissoes['sinistro'] != 'NENHUMA'">
                                    <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- EDITAR SINISTRO -->
                <div *ngIf="editSinitro">
                    <div class="row">
                        <div class="col col-3">
                            <button class="btn btn-outline-danger btn-block" (click)="closeSinistro()">
                                <fa-icon [icon]="['fas', 'undo']"></fa-icon>&nbsp;VOLTAR
                            </button>
                        </div>
                        <div class="col col-6" style="text-align: center;">
                            <h3>{{nomeSinistro}}</h3>
                        </div>
                        <div class="col col-3" [hidden]="novoSinistro">
                            <button *ngIf="db?.usuario?.permissoes['sinistro'] == 'EDITAR'"
                                class="btn btn-outline-success btn-block" (click)="cadastrarSinistro('editar')"
                                [disabled]="situacaoSinistro == 'LIQUIDADO' && !dataIndenizacao">
                                <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;SALVAR SINISTRO
                            </button>
                        </div>
                        <div class="col col-3" [hidden]="!novoSinistro">
                            <button *ngIf="db?.usuario?.permissoes['sinistro'] == 'EDITAR'"
                                class="btn btn-outline-success btn-block" (click)="cadastrarSinistro('novo')"
                                [disabled]="situacaoSinistro == 'LIQUIDADO' && !dataIndenizacao">
                                <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;CADASTRAR SINISTRO
                            </button>
                        </div>
                    </div>
                    <br><br>
                    <div>
                        <label>TIPO DE SINISTRO:</label>
                    </div>
                    <br>
                    <div class="row"
                        [ngStyle]="{'pointer-events': db?.usuario?.permissoes['sinistro'] == 'EDITAR' ? 'auto' : 'none'}">
                        <div class="col col-3">
                            <label>Data da Ocorrência:</label>
                            <input class="form-control" type="date" (change)="verificaData(dataSinistro)"
                                [(ngModel)]="dataSinistro">
                        </div>
                        <div class="col col-2">
                            <label>Número do Sinistro:</label>
                            <input class="form-control" type="number" [(ngModel)]="numeroSinistro">
                        </div>
                        <div class="col col-2">
                            <label>Valor da Indenização:</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">R$</span>
                                </div>
                                <input type="number" class="form-control" [(ngModel)]="valorSinistro">
                            </div>
                        </div>
                        <div class="col col-3">
                            <label>Data da Indenização:</label>
                            <input class="form-control" type="date" (change)="verificaData(dataIndenizacao)"
                                [(ngModel)]="dataIndenizacao">
                        </div>
                        <div class="col col-2">
                            <label>Situação:</label>
                            <select class="form-control" [(ngModel)]="situacaoSinistro">
                                <option value=""></option>
                                <option value="PENDENTE">PENDENTE</option>
                                <option value="RECUSADO">RECUSADO</option>
                                <option value="LIQUIDADO">LIQUIDADO</option>
                            </select>
                        </div>
                    </div>
                    <br>
                    <div class="row"
                        [ngStyle]="{'pointer-events': db?.usuario?.permissoes['sinistro'] == 'EDITAR' ? 'auto' : 'none'}">
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="indenizacaoSinistro"
                                    [(ngModel)]="indenizacaoSinistro">
                                <label class="form-check-label" for="indenizacaoSinistro" style="margin-left:10px">
                                    INDENIZAÇAO
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="assistenciaSinistro"
                                    [(ngModel)]="assistenciaSinistro">
                                <label class="form-check-label" for="assistenciaSinistro" style="margin-left:10px">
                                    ASSISTÊNCIA
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="servicosSinistro"
                                    [(ngModel)]="servicosSinistro">
                                <label class="form-check-label" for="servicosSinistro" style="margin-left:10px">
                                    SERVIÇOS
                                </label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row"
                        [ngStyle]="{'pointer-events':  db?.usuario?.permissoes['sinistro'] == 'EDITAR' ? 'auto' : 'none'}">
                        <div class="col">
                            <input type="text" class="form-control" *ngIf="indenizacaoSinistro"
                                [(ngModel)]="qualIndenizacaoSinistro" placeholder="Qual?"
                                oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" *ngIf="assistenciaSinistro"
                                [(ngModel)]="qualAssistenciaSinistro" placeholder="Qual?"
                                oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" *ngIf="servicosSinistro"
                                [(ngModel)]="qualServicosSinistro" placeholder="Qual?"
                                oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <br>
                    <div [hidden]="novoSinistro">
                        <div class="row align-items-center" *ngIf=" db?.usuario?.permissoes['sinistro'] == 'EDITAR'">
                            <div class="col">
                                <label>Histórico Sinistro</label>
                                <textarea class="form-control" [(ngModel)]="mensagemSinistro" rows="4"
                                    oninput="this.value = this.value.toUpperCase()"></textarea>
                            </div>
                            <div class="col col-3" style="text-align:right">
                                <br>
                                <button class="btn btn-success btn-block" [disabled]="!mensagemSinistro"
                                    (click)="cadastrarHistoricoSinistro()">ENVIAR</button>
                            </div>
                        </div>
                        <br>
                        <br>
                        <div *ngFor="let historico of sinistroHistoricos">
                            <div class="card">
                                <h5 class="card-header" style='background-color: #274562; color:white'>
                                    {{historico?.motivo}}</h5>
                                <div class="card-body">
                                    <h5 class="card-title">{{historico?.data | date:'dd/MM/yyyy'}} - {{historico?.hora}}
                                    </h5>
                                    <h6 class="card-title">Usuário: {{historico?.usuario}}</h6>
                                    <p class="card-text">{{historico?.historico | replace}}</p>
                                </div>
                            </div>
                        </div>
                        <br><br><br>
                        <!-- DRAG DROP SINISTRO -->
                        <div class="row" *ngIf=" db?.usuario?.permissoes['sinistro'] == 'EDITAR'">
                            <div class="col" style="text-align: center; color:#5e5e5e">
                                <h5>Clique ou Arraste para Enviar Documentos ou Fotos</h5>
                            </div>
                        </div>
                        <div class="row" *ngIf=" db?.usuario?.permissoes['sinistro'] == 'EDITAR'">
                            <div class="col">
                                <div class="uploadfilecontainer" (click)="fileInputSinistro.click()" appDragDrop
                                    (onFileDropped)="onChangeSinistro($event)">
                                    <input hidden type="file" #fileInputSinistro
                                        (change)="onChangeClickSinistro($event)" multiple>
                                </div>
                                <div class="files-list" *ngFor="let file of filesSinistro; let i= index">
                                    <div class="row align-items-center" style="width: 100%;">
                                        <div class="col col-11">
                                            <p>{{ file }}</p>
                                        </div>
                                        <div class="col col-1 del" (click)="deleteDocFilesSinistro(i)">
                                            X
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row" *ngIf=" db?.usuario?.permissoes['sinistro'] == 'EDITAR'">
                            <div class="col col-1">
                                <br>
                            </div>
                            <div class="col">
                                <button class="btn btn-primary btn-block" (click)="uploadFileSinistro()"
                                    [disabled]="filesSinistro.length < 1">
                                    <fa-icon [icon]="['fas', 'upload']"></fa-icon>&nbsp;&nbsp;ENVIAR
                                </button>
                            </div>
                            <div class="col col-1">
                                <br>
                            </div>
                        </div>
                        <br><br><br>
                        <div class="row justify-content-md-center">
                            <div class="col col-3" *ngFor="let pdf of pdfsSinistro;"
                                style="margin: 10px; background-color:#ce2626; color:white; height: 250px; width:100%; text-align: center; border-radius: 10px;">
                                <div class="delButton" name="delButton"
                                    *ngIf=" db?.usuario?.permissoes['sinistro'] == 'EDITAR'">
                                    <button class="btn btn-dark btn-sm"
                                        (click)="delDocSinistro(pdf.url)"><strong>X</strong></button>
                                </div>
                                <div (click)="openModalPdfViewer(pdf.url)"
                                    style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                                    <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                                    <br>DOCUMENTO PDF
                                </div>
                            </div>
                            <div class="col col-3 img-container" *ngFor="let img of imagesSinistro; let i = index"
                                style="margin: 10px; text-align: center;">
                                <div class="delButton" name="delButton"
                                    *ngIf=" db?.usuario?.permissoes['sinistro'] == 'EDITAR'">
                                    <button class="btn btn-danger btn-sm"
                                        (click)="delDocSinistro(img.url)"><strong>X</strong></button>
                                </div>
                                <img [src]="img.url" (click)="openModalLightBoxSinistro(img.url, i)"
                                    style="border-radius: 10px; height: 250px; width:100%;" crossorigin="anonymous">
                            </div>
                        </div>
                        <br><br><br>
                    </div>
                </div>
                <!-- FIM EDITAR SINISTRO -->
            </tab>
            <tab heading="DOCUMENTOS" *ngIf="db?.usuario?.permissoes['documentos'] == 'EDITAR'">
                <br><br>
                <div class="row" style="text-align: center;">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="semAssinatura"
                                id="semAssinatura" (change)="salvar('semAssinatura',semAssinatura)">
                            <label class="form-check-label" for="semAssinatura">&nbsp;&nbsp;PROPOSTA SEM
                                ASSINATURA</label>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="semAutorizacao"
                                id="semAutorizacao" (change)="salvar('semAutorizacao',semAutorizacao)">
                            <label class="form-check-label" for="semAutorizacao">&nbsp;&nbsp;AUTORIZAÇÃO NÃO
                                ENVIADA</label>
                        </div>
                    </div>
                </div>
                <br>
                <!-- DRAG DROP -->
                <div class="row" *ngIf="db?.usuario?.permissoes['documentos'] == 'EDITAR'">
                    <div class="col" style="text-align: center; color:#5e5e5e">
                        <h5>Clique ou Arraste para Enviar Documentos ou Fotos</h5>
                    </div>
                </div>
                <div class="row" *ngIf="db?.usuario?.permissoes['documentos'] == 'EDITAR'">
                    <div class="col">
                        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop
                            (onFileDropped)="onChange($event)">
                            <input hidden type="file" #fileInput (change)="onChangeClick($event)" multiple>
                        </div>
                        <div class="files-list" *ngFor="let file of files;let i= index">
                            <div class="row align-items-center" style="width: 100%;">
                                <div class="col col-11">
                                    <p>{{ file }}</p>
                                </div>
                                <div class="col col-1 del" (click)="deleteDocFilesEditar(i)">
                                    X
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="db?.usuario?.permissoes['documentos'] == 'EDITAR'">
                    <div class="col col-1">
                        <br>
                    </div>
                    <div class="col">
                        <button class="btn btn-primary btn-block" (click)="uploadFile()" [disabled]="files.length < 1">
                            <fa-icon [icon]="['fas', 'upload']"></fa-icon>&nbsp;&nbsp;ENVIAR
                        </button>
                    </div>
                    <div class="col col-1">
                        <br>
                    </div>
                </div>
                <br><br><br>
                <div class="row justify-content-md-center">
                    <div class="col col-3" *ngFor="let pdf of pdfs;"
                        style="margin: 10px; background-color:#ce2626; color:white; height: 250px; width:100%; text-align: center; border-radius: 10px;">
                        <div class="delButton" name="delButton"
                            *ngIf="db?.usuario?.permissoes['documentos'] == 'EDITAR'">
                            <button class="btn btn-dark btn-sm" (click)="delDoc(pdf.url)"><strong>X</strong></button>
                        </div>
                        <div (click)="openModalPdfViewer(pdf.url)"
                            style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                            <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                            <br>DOCUMENTO PDF
                        </div>
                    </div>
                    <div class="col col-3 img-container" *ngFor="let img of images; let i = index"
                        style="margin: 10px; text-align: center;">
                        <div class="delButton" name="delButton"
                            *ngIf="db?.usuario?.permissoes['documentos'] == 'EDITAR'">
                            <button class="btn btn-danger btn-sm" (click)="delDoc(img.url)"><strong>X</strong></button>
                        </div>
                        <img [src]="img.url" (click)="openModalLightBox(img.url, i)"
                            style="border-radius: 10px; height: 250px; width:100%;">
                    </div>
                </div>
                <br><br><br>
            </tab>
            <tab heading="CANCELAMENTO" *ngIf="db?.usuario?.permissoes['cancelamentos'] == 'VER' || db?.usuario?.permissoes['cancelamentos'] == 'EDITAR'">
                <br><br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="checkSolicitouCancelamento"
                                id="solicitacaoCancelamentoCheck" [disabled]="solicitadoCancelamento">
                            <label class="form-check-label" for="solicitacaoCancelamentoCheck">&nbsp;&nbsp;ASSOCIADO
                                SOLICITOU CANCELAMENTO
                            </label>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row align-items-center">
                    <div class="col">
                        <label>Motivo da Solicitação de Cancelamento</label>
                        <textarea class="form-control" [(ngModel)]="motivoSolicitacaoCancelamento" rows="4"
                            oninput="this.value = this.value.toUpperCase()"
                            [disabled]="solicitadoCancelamento"></textarea>
                    </div>
                </div>
                <div class="row align-items-center" *ngIf="solicitadoCancelamento">
                    <div class="col">
                        <label>Motivo da Resolução da Solicitação</label>
                        <textarea class="form-control" [(ngModel)]="motivoCancelarSolicitacaoCancelamento" rows="4"
                            oninput="this.value = this.value.toUpperCase()"></textarea>
                    </div>
                </div>
                <br>
                <div class="row"  *ngIf="db?.usuario?.permissoes['cancelamentos'] == 'EDITAR'">
                    <div class="col" *ngIf="!solicitadoCancelamento">
                        <button class="btn btn-success" (click)="solicitarCancelamento()"
                            [disabled]="!checkSolicitouCancelamento || !motivoSolicitacaoCancelamento">
                            ENVIAR SOLICITAÇÃO DE CANCELAMENTO
                        </button>
                    </div>
                    <div class="col" *ngIf="solicitadoCancelamento">
                        <button class="btn btn-danger" (click)="cancelarCancelamento()"
                            [disabled]="!motivoCancelarSolicitacaoCancelamento">
                            REVERTER SOLICITAÇÃO
                        </button>
                    </div>
                </div>
                <br>
                <hr>
                <br>
                <div class="row" style="text-align: center; color:firebrick">
                    <div class="col">
                        <h4>CANCELAMENTO DO CONTRATO</h4>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col">
                        <label>Motivo do Cancelamento</label>
                        <select class="form-control" [(ngModel)]="motivoCancelamento">
                            <option value="" selected></option>
                            <option *ngFor="let mot of motivosCancelamento" [value]="mot.nome">{{mot.nome}}</option>
                        </select>
                    </div>
                </div>
                <br>
                <div class="row align-items-center">
                    <div class="col">
                        <label>Observações do Cancelamento</label>
                        <textarea class="form-control" [(ngModel)]="observacoesCancelamento" rows="4"
                            oninput="this.value = this.value.toUpperCase()"></textarea>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="spcCancelamento" id="spcCheck">
                            <label class="form-check-label" for="spcCheck">&nbsp;&nbsp;INCLUSO NO SISTEMA DE PROTEÇÃO AO
                                CRÉDITO
                            </label>
                        </div>
                    </div>
                </div>
                <br><br><br>
                <!-- DRAG DROP CANCELAMENTO -->
                <div class="row" *ngIf="db?.usuario?.permissoes['cancelamentos'] == 'EDITAR'">
                    <div class="col" style="text-align: center; color:#5e5e5e">
                        <h5>Clique ou Arraste para Enviar Documentos ou Fotos</h5>
                    </div>
                </div>
                <div class="row" *ngIf="db?.usuario?.permissoes['cancelamentos'] == 'EDITAR'">
                    <div class="col">
                        <div class="uploadfilecontainer" (click)="fileInputCancelamento.click()" appDragDrop
                            (onFileDropped)="onChangeCancelamento($event)">
                            <input hidden type="file" #fileInputCancelamento
                                (change)="onChangeClickCancelamento($event)" multiple>
                        </div>
                        <div class="files-list" *ngFor="let file of filesCancelamento; let i= index">
                            <div class="row align-items-center" style="width: 100%;">
                                <div class="col col-11">
                                    <p>{{ file }}</p>
                                </div>
                                <div class="col col-1 del" (click)="deleteDocFilesCancelamento(i)">
                                    X
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="db?.usuario?.permissoes['cancelamentos'] == 'EDITAR'">
                    <div class="col col-1">
                        <br>
                    </div>
                    <div class="col">
                        <button class="btn btn-primary btn-block" (click)="uploadFileCancelamento()"
                            [disabled]="filesCancelamento.length < 1">
                            <fa-icon [icon]="['fas', 'upload']"></fa-icon>&nbsp;&nbsp;ENVIAR
                        </button>
                    </div>
                    <div class="col col-1">
                        <br>
                    </div>
                </div>
                <br><br><br>
                <div class="row justify-content-md-center">
                    <div class="col col-3" *ngFor="let pdf of pdfsCancelamento;"
                        style="margin: 10px; background-color:#ce2626; color:white; height: 250px; width:100%; text-align: center; border-radius: 10px;">
                        <div class="delButton" name="delButton"
                            *ngIf="db?.usuario?.permissoes['cancelamentos'] == 'EDITAR'">
                            <button class="btn btn-dark btn-sm"
                                (click)="delDocCancelamento(pdf.url)"><strong>X</strong></button>
                        </div>
                        <div (click)="openModalPdfViewer(pdf.url)"
                            style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                            <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                            <br>DOCUMENTO PDF
                        </div>
                    </div>
                    <div class="col col-3 img-container" *ngFor="let img of imagesCancelamento; let i = index"
                        style="margin: 10px; text-align: center;">
                        <div class="delButton" name="delButton"
                            *ngIf="db?.usuario?.permissoes['cancelamentos'] == 'EDITAR'">
                            <button class="btn btn-danger btn-sm"
                                (click)="delDocCancelamento(img.url)"><strong>X</strong></button>
                        </div>
                        <img [src]="img.url" (click)="openModalLightBoxCancelamento(img.url, i)"
                            style="border-radius: 10px; height: 250px; width:100%;" crossorigin="anonymous">
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="db?.usuario?.permissoes['cancelamentos'] == 'EDITAR'">
                    <div class="col col-1">
                        <br>
                    </div>
                    <div class="col">
                        <button class="btn btn-danger btn-block" (click)="cancelarContrato()"
                            [disabled]="!motivoCancelamento || !observacoesCancelamento || situacaoEditar == 'CANCELADO'">
                            CANCELAR CONTRATO
                        </button>
                    </div>
                    <div class="col col-1">
                        <br>
                    </div>
                </div>
                <br><br><br>
            </tab>
        </tabset>
    </div>
    <br><br>
</div>
<!-- NOVO ASSOCIADO PJ-->
<div class="modal" id="modalNovoPj">
    <div class="row  align-items-center header">
        <div class="col titulo">
            CADASTRAR ASSOCIADO VIVA MAX EMPRESARIAL
        </div>
        <div class="col botoes">
            <button class="btn btn-outline-light" (click)="fecharModalNovoPj()">
                <fa-icon [icon]="['fas', 'window-close']"></fa-icon>&nbsp;CANCELAR
            </button>
        </div>
    </div>
    <div class="corpo">
        <div class="row align-items-center">
            <div class="col col-2">
                <label>Data Adesão</label>
                <input type="date" (change)="verificaData(dataAdesao)" class="form-control" [(ngModel)]="dataAdesao"
                    [ngStyle]="{'border-color': !dataAdesao ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-6">
                <label>Vendedor</label>
                <select class="form-control" [(ngModel)]="vendedor" (change)="selectVendedor(vendedor)"
                    [ngStyle]="{'border-color': !vendedor ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let vendedor of todosVendedores" [value]="vendedor.nome">{{vendedor.nome}}</option>
                </select>
            </div>
            <div class="col col-4">
                <label>Empresa</label>
                <ng-select class="form-control" [items]="empresas" bindLabel="razaoEmpresa" appendTo="body"
                    [(ngModel)]="empresa" (change)="selectEmpresa(empresa)"></ng-select>
            </div>
        </div>
        <br>
        <div class="row" *ngIf="empresa">
            <div class="col col-2">
                <label>Tipo Cadastro:</label>
                <select class="form-control" [(ngModel)]="origem"
                    [ngStyle]="{'border-color': !origem ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option value="NOVO">NOVO</option>
                    <option value="INCLUSÃO EMPRESA">INCLUSÃO EMPRESA</option>
                </select>
            </div>
            <div class="col col-2">
                <label>CPF:</label>
                <input type="text" class="form-control" [(ngModel)]="cpf" cpf [textMask]="{mask: MASKS.cpf.textMask}"
                [ngStyle]="{'border-color': !cpfCheck || !app.checkCPF(cpf) ? '#dd443e' : '#3cb42c'}"
                    (keyup)="app.checkCPF(cpf);cpfUnico('empresarial',cpf)">
                    <div class="errorMsg" *ngIf="cpf && !app.checkCPF(cpf)">CPF incorreto!</div>
                    <div class="errorMsg" *ngIf="!cpfCheck">CPF Já Cadastrado!</div>
            </div>
            <div class="col col-2">
                <label>Nascimento:</label>
                <input type="text" class="form-control" [(ngModel)]="nascimento"
                    maxlength="10"
                    onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}"
                    [ngStyle]="{'border-color': (nascimento.length != 10 || maisSessentaNove) ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-4">
                <label>Nome Completo:</label>
                <input type="text" class="form-control" [(ngModel)]="nome"
                    oninput="this.value = this.value.toUpperCase()"
                    [ngStyle]="{'border-color': !nome ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-2">
                <label>Estado Civil:</label>
                <select class="form-control" [(ngModel)]="estadoCivil"
                    [ngStyle]="{'border-color': !estadoCivil ? '#dd443e' : '#3cb42c'}">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                    <option value="CASADO">CASADO</option>
                    <option value="DIVORCIADO">DIVORCIADO</option>
                    <option value="VIÚVO">VIÚVO</option>
                    <option value="SEPARADO">SEPARADO</option>
                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
        </div>
        <br>
        <div [hidden]="nascimento.length != 10 || !empresa || !cpfCheck || !vendedor || !nome || !estadoCivil">
            <div class="row align-items-center">
                <div class="col col-2">
                    <label>Sexo:</label>
                    <select class="form-control" [(ngModel)]="sexo"
                        [ngStyle]="{'border-color': !sexo ? '#dd443e' : '#3cb42c'}">
                        <option value="M">MASCULINO</option>
                        <option value="F">FEMININO</option>
                    </select>
                </div>
                <div class="col col-3">
                    <label>Telefone:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                        [(ngModel)]="telefone">
                </div>
                <div class="col col-3">
                    <label>Celular:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                        [(ngModel)]="celular" [ngStyle]="{'border-color': !celular ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="whatsapp" id="whats">
                        <label class="form-check-label" for="whats">&nbsp;&nbsp;Possui Whatsapp</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-2">
                    <label>CEP:</label>
                    <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control"
                        [(ngModel)]="cep" (keyup)="buscarCep(cep)">
                </div>
                <div class="col col-5">
                    <label>Rua:</label>
                    <input type="text" class="form-control" [(ngModel)]="rua"
                        oninput="this.value = this.value.toUpperCase()"
                        [ngStyle]="{'border-color': !rua ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-2">
                    <label>Número:</label>
                    <input type="text" class="form-control" [(ngModel)]="numero"
                        [ngStyle]="{'border-color': !numero ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Bairro:</label>
                    <input type="text" class="form-control" [(ngModel)]="bairro"
                        oninput="this.value = this.value.toUpperCase()"
                        [ngStyle]="{'border-color': !bairro ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <div class="row">
                <div class="col col-2">
                    <label>Complemento:</label>
                    <input type="text" class="form-control" [(ngModel)]="complemento"
                        oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-3">
                    <label>Município:</label>
                    <input type="text" class="form-control" [(ngModel)]="municipio"
                        oninput="this.value = this.value.toUpperCase()"
                        [ngStyle]="{'border-color': !municipio ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-1">
                    <label>UF:</label>
                    <input type="text" class="form-control" [(ngModel)]="estado"
                        oninput="this.value = this.value.toUpperCase()"
                        [ngStyle]="{'border-color': !estado ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Função:</label>
                    <input type="text" class="form-control" [(ngModel)]="funcao"
                        oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-3">
                    <label>E-mail:</label>
                    <input type="text" class="form-control" [(ngModel)]="email"
                    (keyup)="app.checkEmail(email)"
                    oninput="this.value = this.value.toUpperCase()">
                    <div class="errorMsg" *ngIf="email && !app.checkEmail(email)">E-mail inválido!</div>
                </div>
            </div>
        </div>
        <div [hidden]="!nome || !estadoCivil || !rua || !numero || !bairro || !municipio || !estado">
            <br>
            <label>DADOS CADASTRAIS DOS DEPENDENTES</label>
            <br>
            <form [formGroup]="dependentes">
                <div class="row" formArrayName="dependente">
                    <div class="form-group col-3" style="text-align: left;" [hidden]="listaDeDependentes?.length > 0">
                        <br>
                        <button class="btn btn-outline-success btn-sm" (click)="addDependente()">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE
                        </button>
                    </div>
                    <div class="form-group col-9" [hidden]="listaDeDependentes?.length > 0">
                        &nbsp;
                    </div>
                    <div class="col col-12" *ngFor="let dep of dependentesFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row align-items-center">
                            <div class="form-group col-2">
                                <label>Tipo:</label>
                                <select class="form-control" formControlName="tipo" id="tipoPj{{i}}"
                                    style="text-align: left;" (change)="tipoDependentePj(i)"
                                    [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null || dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) ? '#dd443e' : null}">
                                    <option value=""></option>
                                    <option value="CÔNJUGE">CÔNJUGE</option>
                                    <option value="FILHO">FILHO</option>
                                    <option value="FILHA">FILHA</option>
                                </select>
                            </div>
                            <div class="form-group col-3">
                                <label>Nome:</label>
                                <input type="text" class="form-control" formControlName="nome"
                                    oninput="this.value = this.value.toUpperCase()"
                                    [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null || dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-2">
                                <label>Data Nascimento:</label>
                                <input type="text" class="form-control" formControlName="nascimento"
                                    (change)="verificaNascimentoPj(i)" id="nascimentoPj{{i}}" maxlength="10"
                                    onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}"
                                    [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null) || (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) || (dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null)) ? '#dd443e' :  (dependentes.controls.dependente.value[i].asf == 'FUNERAL AUTOMÁTICO ATÉ 18 ANOS' && (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null)) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-2">
                                <label>CPF:</label>
                                <input type="text" class="form-control" formControlName="cpf" cpf id="cpfPj{{i}}"
                                    [textMask]="{mask: MASKS.cpf.textMask}" 
                                    [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null) || (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null)) ? '#dd443e' : null}">
                                    (keyup)="app.checkCPF(dependentes.controls.dependente.value[i].cpf)"
                                    <div class="errorMsg" *ngIf="dependentes.controls.dependente.value[i].cpf && !app.checkCPF(dependentes.controls.dependente.value[i].cpf)">CPF incorreto!</div>
                                </div>
                            <div class="form-group col-2">
                                <label>ASF:</label>
                                <select class="form-control" formControlName="asf" id="asfPj{{i}}">
                                    <option value="FUNERAL AUTOMÁTICO ATÉ 18 ANOS" disabled>FUNERAL AUTOMÁTICO ATÉ 18
                                        ANOS</option>
                                    <option value="FUNERAL AUTOMÁTICO VITALÍCIO" disabled>FUNERAL AUTOMÁTICO VITALÍCIO
                                    </option>
                                    <option value="NÃO HÁ FUNERAL CONTRATADO">NÃO HÁ FUNERAL CONTRATADO</option>
                                    <option value="FUNERAL CONTRATADO">FUNERAL CONTRATADO</option>
                                </select>
                            </div>
                            <div class="form-group col-1" style="text-align: right;">
                                <br>
                                <button class="btn btn-outline-danger btn-sm" (click)="removeDependente(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-outline-success btn-sm" (click)="addDependente()"
                                [hidden]="empresaAssociado != 'VIVA MAX' && !hibrido && qtdeConectaDependentes() >= maxDependentesConecta"
                                    [disabled]="(dependentes.controls.dependente.value[i].asf == '' || dependentes.controls.dependente.value[i].asf == null ) || ((dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) || (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null)) || (dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) || ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null))))">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <br>
            <label>DEPENDENTES EXTRAS</label>
            <form [formGroup]="dependentesExtra">
                <div class="row" formArrayName="dependenteExtra">
                    <div class="form-group col-3" style="text-align: left;"
                        [hidden]="listaDeDependentesExtra?.length > 0">
                        <br>
                        <button class="btn btn-outline-success btn-sm" (click)="addDependenteExtra()">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE EXTRA
                        </button>
                    </div>
                    <div class="form-group col-9" [hidden]="listaDeDependentesExtra?.length > 0">
                        &nbsp;
                    </div>
                    <div class="col col-12"
                        *ngFor="let dependenteExtra of dependentesExtraFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row align-items-center">
                            <div class="form-group col-2">
                                <label>Tipo:</label>
                                <select class="form-control" formControlName="tipo" id="tipoPj{{100+i}}"
                                    (change)="tipoDependenteExtraPj(i)" style="text-align: left;"
                                    [ngStyle]="{'border-color': (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null || dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) ? '#dd443e' : null}">
                                    <option value=""></option>
                                    <option value="MÃE">MÃE</option>
                                    <option value="PAI">PAI</option>
                                    <option value="SOGRO">SOGRO</option>
                                    <option value="SOGRA">SOGRA</option>
                                </select>
                            </div>
                            <div class="form-group col-3">
                                <label>Nome:</label>
                                <input type="text" class="form-control" formControlName="nome"
                                    oninput="this.value = this.value.toUpperCase()"
                                    [ngStyle]="{'border-color': (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null || dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-2">
                                <label>Data Nascimento:</label>
                                <input type="text" class="form-control" formControlName="nascimento"
                                    id="nascimentoPj{{100+i}}" maxlength="10"
                                    onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}"
                                    [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null) || (dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null)) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-2">
                                <label>CPF:</label>
                                <input type="text" class="form-control" formControlName="cpf" cpf id="cpfPj{{100+i}}"
                                    [textMask]="{mask: MASKS.cpf.textMask}" (keyup)="app.checkCPF(dependentesExtra.controls.dependenteExtra.value[i].cpf)"
                                    [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null)) ? '#dd443e' : null}">
                                    <div class="errorMsg" *ngIf="dependentesExtra.controls.dependenteExtra.value[i].cpf && !app.checkCPF(dependentesExtra.controls.dependenteExtra.value[i].cpf)">CPF incorreto!</div>
                                </div>
                            <div class="form-group col-2" [hidden]="funeralExtra == 'NÃO' || !funeralExtra">
                                <label>ASF:</label>
                                <select class="form-control" formControlName="asf" id="asfExtraPj{{100+i}}">
                                    <option value=""></option>
                                    <option value="AUTOMÁTICO SEM CUSTO" disabled>AUTOMÁTICO SEM CUSTO</option>
                                    <option value="NÃO HÁ FUNERAL CONTRATADO" selected>NÃO HÁ FUNERAL CONTRATADO
                                    </option>
                                    <option value="FUNERAL CONTRATADO">FUNERAL CONTRATADO</option>
                                </select>
                            </div>
                            <div class="form-group col-1" style="text-align: right;">
                                <br>
                                <button class="btn btn-outline-danger btn-sm" (click)="removeDependenteExtra(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-outline-success btn-sm" (click)="addDependenteExtra()"
                                [hidden]="empresaAssociado != 'VIVA MAX' && !hibrido && qtdeConectaDependentes() >= maxDependentesConecta"
                                    [disabled]="((dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) || (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null)) || dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null))">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <br>
            <hr>
            <label>Proponente Possui Problemas Pré Existentes?</label>
            <div style="padding:10px">
                <div class="row">
                    <div class="col col-2">
                        <br>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="doencaSim"
                                (change)="selectDoenca($event)">
                            <label class="form-check-label" for="doencaSim" style="margin-left:10px">SIM</label>
                        </div>
                    </div>
                    <div class="col col-2">
                        <br>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="doencaNao"
                                (change)="selectDoenca($event)">
                            <label class="form-check-label" for="doencaNao" style="margin-left:10px">NÃO</label>
                        </div>
                    </div>
                    <div class="col col-8" *ngIf="doenca == 'SIM'">
                        <label>Especificar:</label>
                        <input type="text" class="form-control" [(ngModel)]="especificarDoenca"
                            oninput="this.value = this.value.toUpperCase()">
                    </div>
                </div>
            </div>
            <div>
                <br><br>
                <div class="row">
                    <div class="col" style="text-align: center; color:#5e5e5e">
                        <h5>Clique ou Arraste para Enviar Documentos ou Fotos</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop
                            (onFileDropped)="onChangeCadastro($event)">
                            <input hidden type="file" #fileInput (change)="onChangeClickCadastro($event)" multiple>
                        </div>
                        <div class="files-list" *ngFor="let file of files;let i= index">
                            <div class="row align-items-center" style="width: 100%;">
                                <div class="col col-11">
                                    <p>{{ file }}</p>
                                </div>
                                <div class="col col-1 del" (click)="deleteDocFiles(i)">
                                    X
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br><br>
                <div class="row" style="padding:10px">
                    <div class="col col-12" style="text-align: center;">
                        <button class="btn btn-success btn-block" (click)="cadastrarAssociadoPj()"
                            [disabled]="!origem || !cpf || !nascimento || !estadoCivil || !rua || !numero || !bairro || !municipio || !estado">CADASTRAR
                            ASSOCIADO</button>
                    </div>
                </div>
            </div>
            <div style="height: 3em;"></div>
        </div>
    </div>
</div>
<!-- NOVO VIVA MAX -->
<div class="modal" id="modalNova">
    <div class="row  align-items-center header">
        <div class="col titulo">
            CADASTRAR ASSOCIADO VIVA MAX
        </div>
        <div class="col botoes">
            <button class="btn btn-outline-light" (click)="fecharModalNova()">
                <fa-icon [icon]="['fas', 'window-close']"></fa-icon>&nbsp;CANCELAR
            </button>
        </div>
    </div>
    <div class="corpo">
        <div class="row align-items-center">
            <div class="col col-2">
                <label>Matricula Servidor</label>
                <input type="number" min="0" class="form-control" [(ngModel)]="matriculaServidor">
            </div>
            <div class="col col-6">
                <label>Vendedor</label>
                <select class="form-control" [(ngModel)]="vendedor" (change)="selectVendedor(vendedor)"
                    [ngStyle]="{'border-color': !vendedor ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let vendedor of todosVendedores" [value]="vendedor.nome">{{vendedor.nome}}</option>
                </select>
            </div>
            <div class="col col-2">
                <br>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="planoFisicoCheck" id="planoFisicoCheck"
                        [disabled]="subContrato != '' && subContrato != null"
                        (change)="escolhePlanoFisico(planoFisicoCheck)">
                    <label class="form-check-label" for="planoFisicoCheck">&nbsp;&nbsp;Plano Físico
                    </label>
                </div>
            </div>
            <div class="col col-2">
                <br>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="pendente" id="pendenteCheck">
                    <label class="form-check-label" for="pendenteCheck">&nbsp;&nbsp;Pendente</label>
                </div>
            </div>
        </div>
        <br>
        <div class="row align-items-center">
            <div class="col col-8">
                <label>Sub-Contrato</label>
                <ng-select class="form-control" [items]="subContratos" bindLabel="nome" appendTo="body"
                    [(ngModel)]="subContrato" [disabled]="planoFisicoCheck || planoEmpresarialCheck"
                    (change)="escolheSubContrato(subContrato)"></ng-select>
            </div>
            <div class="col col-4" *ngIf="planoFisicoCheck == true">
                <label>Plano:</label>
                <select class="form-control" [(ngModel)]="plano" (change)="selectPlano('fisico',plano)"
                    [ngStyle]="{'border-color': !plano ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let plan of planosFisicos" [value]="plan.nome">{{plan.nome}}</option>
                </select>
            </div>
            <div class="col col-4" *ngIf="planoEmpresarialCheck == true">
                <label>Plano:</label>
                <select class="form-control" [(ngModel)]="plano" (change)="selectPlano('empresarial',plano)"
                    [ngStyle]="{'border-color': !plano ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let plan of planosEmpresariais" [value]="plan.nome">{{plan.nome}}</option>
                </select>
            </div>
            <div class="col col-4" *ngIf="subContrato != '' && subContrato != null">
                <label>Plano:</label>
                <select class="form-control" [(ngModel)]="plano" (change)="selectPlano('servidor',plano)"
                    [ngStyle]="{'border-color': !plano ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let plan of planosServidores" [value]="plan.nome">{{plan.nome}}</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row" *ngIf="plano">
            <div class="col col-2">
                <label>CPF:</label>
                <input type="text" class="form-control" [(ngModel)]="cpf" cpf [textMask]="{mask: MASKS.cpf.textMask}"
                [ngStyle]="{'border-color': !cpfCheck || !app.checkCPF(cpf) ? '#dd443e' : '#3cb42c'}"
                (keyup)="app.checkCPF(cpf);cpfUnico('vivamax',cpf)">
                <div class="errorMsg" *ngIf="cpf && !app.checkCPF(cpf)">CPF incorreto!</div>
                <div class="errorMsg" *ngIf="!cpfCheck">CPF Já Cadastrado!</div>
            </div>
            <div class="col col-2">
                <label>Nascimento:</label>
                <input type="text" class="form-control" [(ngModel)]="nascimento"
                    maxlength="10"
                    onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}"
                    [ngStyle]="{'border-color': (nascimento.length != 10 || maisSessentaNove) ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-5"
                *ngIf="pendente || (!maisSessentaNove && nascimento.length == 10 && plano && cpfCheck && vendedor)">
                <label>Nome Completo:</label>
                <input type="text" class="form-control" [(ngModel)]="nome"
                    oninput="this.value = this.value.toUpperCase()"
                    [ngStyle]="{'border-color': !nome ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-3"
                *ngIf="pendente || (!maisSessentaNove && nascimento.length == 10 && plano && cpfCheck && vendedor)">
                <label>Estado Civil:</label>
                <select class="form-control" [(ngModel)]="estadoCivil"
                    [ngStyle]="{'border-color': !estadoCivil ? '#dd443e' : '#3cb42c'}">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                    <option value="CASADO">CASADO</option>
                    <option value="DIVORCIADO">DIVORCIADO</option>
                    <option value="VIÚVO">VIÚVO</option>
                    <option value="SEPARADO">SEPARADO</option>
                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
        </div>
        <div class="row" *ngIf="subContrato != '' && subContrato != null">
            <div class="col col-6">
                <label>Login E-Consig:</label>
                <input type="text" class="form-control" [(ngModel)]="loginEconsig">
            </div>
            <div class="col col-6">
                <label>Senha E-Consig:</label>
                <input type="text" class="form-control" [(ngModel)]="senhaEconsig">
            </div>
        </div>
        <br>
        <div class="row" [hidden]="!pendente">
            <div class="col col-4">
                <label>Motivo da Pendência</label>
                <select class="form-control" [(ngModel)]="motivoPendencia">
                    <option value="" selected></option>
                    <option *ngFor="let motivo of motivosPendencia" [value]="motivo.nome">{{motivo.nome}}</option>
                </select>
            </div>
            <div class="col col-8">
                <label>Observação Pendente</label>
                <input type="text" class="form-control" [(ngModel)]="observacaoPendente"
                    oninput="this.value = this.value.toUpperCase()"
                    [ngStyle]="{'border-color': !observacaoPendente ? '#dd443e' : '#3cb42c'}">
            </div>
        </div>
        <br>
        <div
            [hidden]="!pendente && (nascimento.length != 10 || !plano || !cpfCheck || !vendedor || !nome || !estadoCivil)">
            <div class="row align-items-center">
                <div class="col col-3">
                    <label>Sexo:</label>
                    <select class="form-control" [(ngModel)]="sexo"
                        [ngStyle]="{'border-color': !sexo ? '#dd443e' : '#3cb42c'}">
                        <option value="M">MASCULINO</option>
                        <option value="F">FEMININO</option>
                    </select>
                </div>
                <div class="col col-3">
                    <label>Telefone:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                        [(ngModel)]="telefone">
                </div>
                <div class="col col-3">
                    <label>Celular:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                        [(ngModel)]="celular" [ngStyle]="{'border-color': !celular ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="whatsapp" id="whats">
                        <label class="form-check-label" for="whats">&nbsp;&nbsp;Possui Whatsapp</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-2">
                    <label>CEP:</label>
                    <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control"
                        [(ngModel)]="cep" (keyup)="buscarCep(cep)">
                </div>
                <div class="col col-5">
                    <label>Rua:</label>
                    <input type="text" class="form-control" [(ngModel)]="rua"
                        oninput="this.value = this.value.toUpperCase()"
                        [ngStyle]="{'border-color': !rua ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-2">
                    <label>Número:</label>
                    <input type="text" class="form-control" [(ngModel)]="numero"
                        [ngStyle]="{'border-color': !numero ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Bairro:</label>
                    <input type="text" class="form-control" [(ngModel)]="bairro"
                        oninput="this.value = this.value.toUpperCase()"
                        [ngStyle]="{'border-color': !bairro ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <div class="row">
                <div class="col col-2">
                    <label>Complemento:</label>
                    <input type="text" class="form-control" [(ngModel)]="complemento"
                        oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-3">
                    <label>Município:</label>
                    <input type="text" class="form-control" [(ngModel)]="municipio"
                        oninput="this.value = this.value.toUpperCase()"
                        [ngStyle]="{'border-color': !municipio ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-1">
                    <label>UF:</label>
                    <input type="text" class="form-control" [(ngModel)]="estado"
                        oninput="this.value = this.value.toUpperCase()"
                        [ngStyle]="{'border-color': !estado ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Local Trabalho:</label>
                    <input type="text" class="form-control" [(ngModel)]="localTrabalho"
                        oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-3">
                    <label>Função:</label>
                    <input type="text" class="form-control" [(ngModel)]="funcao"
                        oninput="this.value = this.value.toUpperCase()">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label>E-mail:</label>
                    <input type="text" class="form-control" [(ngModel)]="email"
                    (keyup)="app.checkEmail(email)"
                    oninput="this.value = this.value.toUpperCase()">
                    <div class="errorMsg" *ngIf="email && !app.checkEmail(email)">E-mail inválido!</div>
                </div>
            </div>
        </div>
        <div [hidden]="!pendente && (!nome || !estadoCivil || !rua || !numero || !bairro || !municipio || !estado)">
            <br>
            <label>DADOS CADASTRAIS DOS DEPENDENTES</label>
            <br>
            <form [formGroup]="dependentes">
                <div class="row" formArrayName="dependente">
                    <div class="form-group col-3" style="text-align: left;" [hidden]="listaDeDependentes?.length > 0">
                        <br>
                        <button class="btn btn-outline-success btn-sm" (click)="addDependente()">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE
                        </button>
                    </div>
                    <div class="form-group col-9" [hidden]="listaDeDependentes?.length > 0">
                        &nbsp;
                    </div>
                    <div class="col col-12" *ngFor="let dep of dependentesFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row align-items-center">
                            <div class="form-group col-2">
                                <label>Tipo:</label>
                                <select class="form-control" formControlName="tipo" id="tipo{{i}}"
                                    style="text-align: left;" (change)="tipoDependente(i)"
                                    [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null || dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) ? '#dd443e' : null}">
                                    <option value=""></option>
                                    <option value="CÔNJUGE">CÔNJUGE</option>
                                    <option value="FILHO">FILHO</option>
                                    <option value="FILHA">FILHA</option>
                                </select>
                            </div>
                            <div class="form-group col-3">
                                <label>Nome:</label>
                                <input type="text" class="form-control" formControlName="nome"
                                    oninput="this.value = this.value.toUpperCase()"
                                    [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null || dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-2">
                                <label>Data Nascimento:</label>
                                <input type="text" class="form-control" formControlName="nascimento"
                                    (change)="verificaNascimento(i)" id="nascimento{{i}}" maxlength="10"
                                    onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}"
                                    [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null) || (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) || (dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null)) ? '#dd443e' : (dependentes.controls.dependente.value[i].asf == 'FUNERAL AUTOMÁTICO ATÉ 18 ANOS' && (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null)) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-2">
                                <label>CPF:</label>
                                <input type="text" class="form-control" formControlName="cpf" cpf id="cpf{{i}}"
                                    [textMask]="{mask: MASKS.cpf.textMask}" (keyup)="app.checkCPF(dependentes.controls.dependente.value[i].cpf)"
                                    [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null) || (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null)) ? '#dd443e' : null}">
                                    <div class="errorMsg" *ngIf="dependentes.controls.dependente.value[i].cpf && !app.checkCPF(dependentes.controls.dependente.value[i].cpf)">CPF incorreto!</div>
                                </div>
                            <div class="form-group col-2">
                                <label>ASF:</label>
                                <select class="form-control" formControlName="asf" id="asf{{i}}" (change)="selectAsf()">
                                    <option value="FUNERAL AUTOMÁTICO ATÉ 18 ANOS" disabled>FUNERAL AUTOMÁTICO ATÉ 18
                                        ANOS</option>
                                    <option value="FUNERAL AUTOMÁTICO VITALÍCIO" disabled>FUNERAL AUTOMÁTICO VITALÍCIO
                                    </option>
                                    <option value="NÃO HÁ FUNERAL CONTRATADO"
                                        [disabled]="dependentes.controls.dependente.value[i].tipo == 'CÔNJUGE'">NÃO HÁ
                                        FUNERAL CONTRATADO</option>
                                    <option value="FUNERAL CONTRATADO"
                                        [disabled]="dependentes.controls.dependente.value[i].tipo == 'CÔNJUGE'">FUNERAL
                                        CONTRATADO</option>
                                </select>
                            </div>
                            <div class="form-group col-1" style="text-align: right;">
                                <br>
                                <button class="btn btn-outline-danger btn-sm" (click)="removeDependente(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-outline-success btn-sm" (click)="addDependente()"
                                    [disabled]="((dependentes.controls.dependente.value[i].asf == 'FUNERAL AUTOMÁTICO ATÉ 18 ANOS' && (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null)) || (dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) || (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null)) || (dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) || ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null))))">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <br>
            <hr>
            <label>DEPENDENTES EXTRAS</label>
            <form [formGroup]="dependentesExtra">
                <div class="row" formArrayName="dependenteExtra">
                    <div class="form-group col-3" style="text-align: left;"
                        [hidden]="listaDeDependentesExtra?.length > 0">
                        <br>
                        <button class="btn btn-outline-success btn-sm" (click)="addDependenteExtra()">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE EXTRA
                        </button>
                    </div>
                    <div class="form-group col-9" [hidden]="listaDeDependentesExtra?.length > 0">
                        &nbsp;
                    </div>
                    <div class="col col-12"
                        *ngFor="let dependenteExtra of dependentesExtraFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row align-items-center">
                            <div class="form-group col-2">
                                <label>Tipo:</label>
                                <select class="form-control" formControlName="tipo" id="tipo{{100+i}}"
                                    (change)="tipoDependenteExtra(i)" style="text-align: left;"
                                    [ngStyle]="{'border-color': (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null || dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) ? '#dd443e' : null}">
                                    <option value=""></option>
                                    <option value="MÃE">MÃE</option>
                                    <option value="PAI">PAI</option>
                                    <option value="SOGRO">SOGRO</option>
                                    <option value="SOGRA">SOGRA</option>
                                </select>
                            </div>
                            <div class="form-group col-3">
                                <label>Nome:</label>
                                <input type="text" class="form-control" formControlName="nome"
                                    oninput="this.value = this.value.toUpperCase()"
                                    [ngStyle]="{'border-color': (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null || dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-2">
                                <label>Data Nascimento:</label>
                                <input type="text" class="form-control" formControlName="nascimento"
                                    id="nascimento{{100+i}}" maxlength="10"
                                    onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}"
                                    [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null) || (dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null)) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-2">
                                <label>CPF:</label>
                                <input type="text" class="form-control" formControlName="cpf" cpf id="cpf{{100+i}}"
                                    [textMask]="{mask: MASKS.cpf.textMask}" (keyup)="app.checkCPF(dependentesExtra.controls.dependenteExtra.value[i].cpf)"
                                    [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null)) ? '#dd443e' : null}">
                                    <div class="errorMsg" *ngIf="dependentesExtra.controls.dependenteExtra.value[i].cpf && !app.checkCPF(dependentesExtra.controls.dependenteExtra.value[i].cpf)">CPF incorreto!</div>
                                </div>
                            <div class="form-group col-2">
                                <label>ASF:</label>
                                <select class="form-control" formControlName="asf" id="asfExtra{{100+i}}"
                                    (change)="selectAsfExtras()">
                                    <option value=""></option>
                                    <option value="AUTOMÁTICO SEM CUSTO" disabled>AUTOMÁTICO SEM CUSTO</option>
                                    <option value="NÃO HÁ FUNERAL CONTRATADO">NÃO HÁ FUNERAL CONTRATADO</option>
                                    <option value="FUNERAL CONTRATADO">FUNERAL CONTRATADO</option>
                                </select>
                            </div>
                            <div class="form-group col-1" style="text-align: right;">
                                <br>
                                <button class="btn btn-outline-danger btn-sm" (click)="removeDependenteExtra(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-outline-success btn-sm" (click)="addDependenteExtra()"
                                    [disabled]="((dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) || (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null)) || dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null))">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <br>
            <label>Proponente Possui Problemas Pré Existentes?</label>
            <div style="padding:10px">
                <div class="row">
                    <div class="col col-2">
                        <br>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="checkDoencaSim"
                                [disabled]="doenca == 'NÃO'" (change)="selectDoenca($event)">
                            <label class="form-check-label" for="checkDoencaSim" style="margin-left:10px">SIM</label>
                        </div>
                    </div>
                    <div class="col col-2">
                        <br>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="checkDoencaNao"
                                [disabled]="doenca == 'SIM'" (change)="selectDoenca($event)">
                            <label class="form-check-label" for="checkDoencaNao" style="margin-left:10px">NÃO</label>
                        </div>
                    </div>
                    <div class="col col-8" *ngIf="doenca == 'SIM'">
                        <label>Especificar:</label>
                        <input type="text" class="form-control" [(ngModel)]="especificarDoenca"
                            oninput="this.value = this.value.toUpperCase()">
                    </div>
                </div>
            </div>
            <br><br>
            <label>ADESÃO E MANUTENÇÃO</label>
            <div class="row">
                <div class="col col-3">
                    <label>Data Adesão</label>
                    <input type="date" (change)="verificaData(dataAdesao)" class="form-control" [(ngModel)]="dataAdesao"
                        [ngStyle]="{'border-color': !dataAdesao ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Data 1ª Vencimento</label>
                    <input type="date" (change)="verificaData(primeiroVencimento)" class="form-control"
                        [(ngModel)]="primeiroVencimento"
                        [ngStyle]="{'border-color': !primeiroVencimento ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3" *ngIf="editaAdesao == 'SIM'">
                    <label>Taxa de Adesão:</label>
                    <input type="number" class="form-control" [(ngModel)]="taxaAdesao">
                </div>
                <div class="col col-3" *ngIf="editaAdesao == 'NÃO'">
                    <label>Taxa de Adesão:</label>
                    <input type="number" class="form-control" [(ngModel)]="taxaAdesao" readonly>
                </div>
                <div class="col col-3" *ngIf="editaMensalidade == 'SIM'">
                    <label>Mensalidade:</label>
                    <input type="number" class="form-control" [(ngModel)]="mensalidade">
                </div>
                <div class="col col-3" *ngIf="editaMensalidade == 'NÃO'">
                    <label>Mensalidade:</label>
                    <input type="number" class="form-control" [(ngModel)]="mensalidade" readonly>
                </div>
                <div class="col col-3" *ngIf="tipoPlano != 'servidor'">
                    <label>Dia Vencimento:</label>
                    <input type="date" class="form-control" [(ngModel)]="diaVencimento"
                        [ngStyle]="{'border-color': !diaVencimento ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3" *ngIf="editaTotal == 'SIM'">
                    <label>Total Com ASF:</label>
                    <input type="number" class="form-control" [(ngModel)]="totalMensalidade">
                </div>
                <div class="col col-3" *ngIf="editaTotal == 'NÃO'">
                    <label>Total Com ASF:</label>
                    <input type="number" class="form-control" [(ngModel)]="totalMensalidade" readonly>
                </div>
            </div>
            <br><br>
            <div>
                <label>FORMA DE PAGAMENTO</label>
                <br><br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="descontoFolha"
                                [disabled]="formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'BOLETO' || formaPagamento == 'CARNÊ' || bloqueioPagamentos || formaPagamento == 'CONVÊNIO CELESC'"
                                (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="descontoFolha" style="margin-left:15px">DESCONTO EM FOLHA DE PAGAMENTO</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO'"
                    style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                    <br>
                    <div class="row" style="margin-left:20px">
                        <div class="col col-4">
                            <label>A PARTIR DO MÊS:</label>
                            <input type="month" [(ngModel)]="mesFolhaPagamento" class="form-control">
                        </div>
                    </div>
                    <br><br>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="debitoRecorrente"
                                [disabled]="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'BOLETO' || formaPagamento == 'CARNÊ' || formaPagamento == 'CONVÊNIO CELESC'"
                                (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="debitoRecorrente" style="margin-left:15px">DEBITO
                                RECORRENTE</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="formaPagamento == 'DEBITO RECORRENTE'"
                    style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                    <div class="row" style="margin-left:20px">
                        <div class="col" *ngFor="let cartao of cartoes; let i = index" style="padding:10px">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="card{{i}}" name="ch" (change)="cartaoSelect($event,i)">
                                <label class="form-check-label" for="card{{i}}" style="margin-left: 15px; font-size:0.8em;">{{cartao | uppercase}}</label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div style="padding:10px">
                        <div class="row" *ngIf="cartaoSelecionado != ''">
                            <div class="col col-4">
                                <label>Nome</label>&nbsp;<small>(idêntico ao do cartão de crédito)</small>
                                <input type="text" class="form-control" [(ngModel)]="nomeCartao"
                                    oninput="this.value = this.value.toUpperCase()">
                            </div>
                            <div class="col col-4">
                                <label>Número do Cartão</label>
                                <input type="text" class="form-control" [(ngModel)]="numeroCartao" maxlength="19"
                                    onkeyup="var val = this.value;var newval = '';val = val.replace(/\s/g, '');for(var i = 0; i < val.length; i++) {if(i%4 == 0 && i > 0) newval = newval.concat(' ');newval = newval.concat(val[i]);}this.value = newval;">
                            </div>
                            <div class="col col-2">
                                <label>Validade</label>
                                <input type="text" class="form-control" [(ngModel)]="validadeCartao" maxlength="5"
                                    onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v}">
                            </div>
                            <div class="col col-2">
                                <label>Código Segurança</label>
                                <input type="text" class="form-control" [(ngModel)]="codigoCartao" maxlength="3">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <hr>
                    <div class="row" style="margin-left:25px">
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="geraLink"
                                    [(ngModel)]="gerarLinkCartao"
                                    [disabled]="nomeCartao  != ''|| numeroCartao  != ''|| validadeCartao  != ''|| codigoCartao != ''">
                                <label class="form-check-label" for="geraLink" style="margin-left:20px">Gerar Link
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="boletoMensal"
                                [disabled]="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'CARNÊ' || formaPagamento == 'CONVÊNIO CELESC'"
                                (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="boletoMensal" style="margin-left:15px">BOLETO</label>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="carne"
                                [disabled]="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'BOLETO' || formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'CONVÊNIO CELESC'"
                                (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="carne" style="margin-left:15px">CARNÊ</label>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="estado == 'SC'">
                    <div class="col col-3">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="celesc"
                                [disabled]="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'BOLETO' || formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'CARNÊ'"
                                (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="celesc" style="margin-left:15px">CONVÊNIO
                                CELESC</label>
                        </div>
                    </div>
                    <div class="col col-4" *ngIf="formaPagamento == 'CONVÊNIO CELESC'">
                        <label>Unidade Consumidora</label>
                        <input type="text" pattern="\d*" class="form-control" style="text-align:center"
                            placeholder="Código Unidade Consumidora" min="0" [(ngModel)]="unidadeConsumidora"
                            maxlength="13">
                    </div>
                </div>
                <br><br>
                <div class="row">
                    <div class="col" style="text-align: center; color:#5e5e5e">
                        <h5>Clique ou Arraste para Enviar Documentos ou Fotos</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop
                            (onFileDropped)="onChangeCadastro($event)">
                            <input hidden type="file" #fileInput (change)="onChangeClick($event)" multiple>
                        </div>
                        <div class="files-list" *ngFor="let file of files;let i= index">
                            <div class="row align-items-center" style="width: 100%;">
                                <div class="col col-11">
                                    <p>{{ file }}</p>
                                </div>
                                <div class="col col-1 del" (click)="deleteDocFiles(i)">
                                    X
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br><br>
                <div class="row" style="padding:10px">
                    <div class="col col-12" style="text-align: center;">
                        <button class="btn btn-success btn-block" (click)="cadastrarAssociado()" [hidden]="pendente"
                            [disabled]="!primeiroVencimento || !formaPagamento || (formaPagamento == 'DEBITO RECORRENTE' && (!nomeCartao || !numeroCartao || !validadeCartao || !codigoCartao)) || plano == '' || !diaVencimento || !cpf || !nascimento || !estadoCivil || !rua || !numero || !bairro || !municipio || !estado || !botao">CADASTRAR
                            ASSOCIADO</button>
                        <button class="btn btn-success btn-block" (click)="cadastrarAssociado()" [hidden]="!pendente"
                            [disabled]="!primeiroVencimento || !observacaoPendente || plano == '' || !nome">CADASTRAR
                            ASSOCIADO PENDENTE</button>
                        <span *ngIf="pendente && !observacaoPendente" style="color:red;font-weight: bold;">*Observação
                            Pendente Necessária</span>&nbsp;&nbsp;<span
                            *ngIf="pendente && !nome" style="color:red;font-weight: bold;">*Nome
                            Necessário</span>&nbsp;&nbsp;<span *ngIf="pendente && !plano"
                            style="color:red;font-weight: bold;">*Plano Necessário</span>
                    </div>
                </div>
            </div>
            <div style="height: 3em;"></div>
        </div>
    </div>
</div>
<!-- NOVO CONECTA -->
<div class="modal" id="telemodalNova">
    <div class="row  align-items-center header">
        <div class="col titulo">
            CADASTRAR ASSOCIADO CONECTA
        </div>
        <div class="col botoes">
            <button class="btn btn-outline-light" (click)="fecharModalNovoConecta()">
                <fa-icon [icon]="['fas', 'window-close']"></fa-icon>&nbsp;CANCELAR
            </button>
        </div>
    </div>
    <div class="corpo">
        <div class="row align-items-center">
            <div class="col col-2">
                <label>Matricula Servidor</label>
                <input type="number" min="0" class="form-control" [(ngModel)]="matriculaServidor">
            </div>
            <div class="col col-7">
                <label>Vendedor</label>
                <select class="form-control" [(ngModel)]="vendedor" (change)="selectVendedor(vendedor)"
                    [ngStyle]="{'border-color': !vendedor ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let vendedor of todosVendedores" [value]="vendedor.nome">{{vendedor.nome}}</option>
                </select>
            </div>
            <div class="col col-3">
                <label>Tipo de Plano:</label>
                <select class="form-control" [(ngModel)]="tipoPlano" (change)="escolherTipoPlano(tipoPlano)"
                    [ngStyle]="{'border-color': !tipoPlano ? '#dd443e' : '#3cb42c'}">
                    <option value="FISICO">FÍSICO</option>
                    <option value="SERVIDOR">SERVIDOR PÚBLICO</option>
                    <option value="EMPRESARIAL">EMPRESARIAL</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row align-items-center">
            <div class="col col-8" *ngIf="tipoPlano == 'EMPRESARIAL'">
                <label>Empresa</label>
                <ng-select class="form-control" [items]="empresas" bindLabel="razaoEmpresa" appendTo="body"
                    [(ngModel)]="empresa" (change)="selectEmpresa(empresa)"></ng-select>
            </div>
            <div class="col col-4" *ngIf="tipoPlano == 'EMPRESARIAL'">
                <label>Plano Empresarial:</label>
                <select class="form-control" [(ngModel)]="plano" (change)="selectPlano('empresarial',plano)"
                    [ngStyle]="{'border-color': !plano ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let plan of planosEmpresariais" [value]="plan.nome">{{plan.nome}}</option>
                </select>
            </div>
            <div class="col col-4" *ngIf="tipoPlano == 'FISICO'">
                <label>Plano Físico:</label>
                <select class="form-control" [(ngModel)]="plano" (change)="selectPlano('fisico',plano)"
                    [ngStyle]="{'border-color': !plano ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let plan of planosFisicos" [value]="plan.nome">{{plan.nome}}</option>
                </select>
            </div>
            <div class="col col-8" *ngIf="tipoPlano == 'SERVIDOR'">
                <label>Sub-Contrato</label>
                <select class="form-control" [(ngModel)]="subContrato" (change)="escolheSubContrato(subContrato)">
                    <option value=""></option>
                    <option *ngFor="let sub of subContratos" [value]="sub.nome">{{sub.nome}}</option>
                </select>
            </div>
            <div class="col col-4" *ngIf="tipoPlano == 'SERVIDOR' && subContrato">
                <label>Plano Servidor:</label>
                <select class="form-control" [(ngModel)]="plano" (change)="selectPlano('servidor',plano)"
                    [ngStyle]="{'border-color': !plano ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let plan of planosServidores" [value]="plan.nome">{{plan.nome}}</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row" *ngIf="subContrato != '' && subContrato != null">
            <div class="col col-6">
                <label>Login E-Consig:</label>
                <input type="text" class="form-control" [(ngModel)]="loginEconsig">
            </div>
            <div class="col col-6">
                <label>Senha E-Consig:</label>
                <input type="text" class="form-control" [(ngModel)]="senhaEconsig">
            </div>
        </div>
        <br>
        <div class="row" *ngIf="plano">
            <div class="col col-3">
                <label>CPF:</label>
                <input type="text" class="form-control" [(ngModel)]="cpf" cpf [textMask]="{mask: MASKS.cpf.textMask}"
                [ngStyle]="{'border-color': !cpfCheck || !app.checkCPF(cpf) ? '#dd443e' : '#3cb42c'}"
                (keyup)="app.checkCPF(cpf);cpfUnico('conecta',cpf)">
                <div class="errorMsg" *ngIf="cpf && !app.checkCPF(cpf)">CPF incorreto!</div>
                <div class="errorMsg" *ngIf="!cpfCheck">CPF Já Cadastrado!</div>
            </div>
            <div class="col col-3">
                <label>Nascimento:</label>
                <input type="text" class="form-control" [(ngModel)]="nascimento" maxlength="10"
                    onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}"
                    [ngStyle]="{'border-color': nascimento.length != 10 ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-4">
                <label>Nome Completo:</label>
                <input type="text" class="form-control" [(ngModel)]="nome"
                    oninput="this.value = this.value.toUpperCase()"
                    [ngStyle]="{'border-color': !nome ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-2">
                <label>Sexo:</label>
                <select class="form-control" [(ngModel)]="sexo"
                    [ngStyle]="{'border-color': !sexo ? '#dd443e' : '#3cb42c'}">
                    <option value="M">MASCULINO</option>
                    <option value="F">FEMININO</option>
                </select>
            </div>
        </div>
        <br>
        <div [hidden]="!app.checkCPF(cpf) || nascimento.length != 10 || !plano || !vendedor || !nome || !sexo || !cpfCheck">
            <div class="row align-items-center">
                <div class="col col-3">
                    <label>Telefone:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                        [(ngModel)]="telefone">
                </div>
                <div class="col col-3">
                    <label>Celular:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                        [(ngModel)]="celular" [ngStyle]="{'border-color': !celular ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-2">
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="whatsapp" id="telewhats">
                        <label class="form-check-label" for="telewhats">&nbsp;&nbsp;Possui Whatsapp</label>
                    </div>
                </div>
                <div class="col col-4">
                    <label>E-mail:</label>
                    <input type="text" class="form-control" [(ngModel)]="email"
                        (keyup)="app.checkEmail(email)"
                        [ngStyle]="{'border-color': !email ? '#dd443e' : '#3cb42c'}"
                        oninput="this.value = this.value.toUpperCase()">
                        <div class="errorMsg" *ngIf="email && !app.checkEmail(email)">E-mail inválido!</div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-2">
                    <label>CEP:</label>
                    <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control"
                        [(ngModel)]="cep" (keyup)="buscarCep(cep)">
                </div>
                <div class="col col-5">
                    <label>Rua:</label>
                    <input type="text" class="form-control" [(ngModel)]="rua"
                        oninput="this.value = this.value.toUpperCase()"
                        [ngStyle]="{'border-color': !rua ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-2">
                    <label>Número:</label>
                    <input type="text" class="form-control" [(ngModel)]="numero"
                        [ngStyle]="{'border-color': !numero ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Bairro:</label>
                    <input type="text" class="form-control" [(ngModel)]="bairro"
                        oninput="this.value = this.value.toUpperCase()"
                        [ngStyle]="{'border-color': !bairro ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-6">
                    <label>Complemento:</label>
                    <input type="text" class="form-control" [(ngModel)]="complemento"
                        oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-4">
                    <label>Município:</label>
                    <input type="text" class="form-control" [(ngModel)]="municipio"
                        oninput="this.value = this.value.toUpperCase()"
                        [ngStyle]="{'border-color': !municipio ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-2">
                    <label>UF:</label>
                    <input type="text" class="form-control" [(ngModel)]="estado"
                        oninput="this.value = this.value.toUpperCase()"
                        [ngStyle]="{'border-color': !estado ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
        </div>
        <div [hidden]="!pendente && (!email  || !nome || !rua || !numero || !bairro || !municipio || !estado)">
            <br>
            <label>DADOS CADASTRAIS DOS DEPENDENTES</label>
            <br>
            <form [formGroup]="dependentes">
                    <div class="row" formArrayName="dependente">
                        <div class="form-group col-3" style="text-align: left;"
                            [hidden]="listaDeDependentes?.length > 0">
                            <br>
                            <button class="btn btn-outline-success btn-sm" (click)="addDependente()">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE
                            </button>
                        </div>
                        <div class="form-group col-9" [hidden]="listaDeDependentes?.length > 0">
                            &nbsp;
                        </div>
                        <div class="col col-12" *ngFor="let dependente of dependentesFormGroup.controls; let i = index;">
                            <div [formGroupName]="i" class="row align-items-center">
                                <div class="form-group col-2">
                                    <label>Tipo:</label>
                                    <select class="form-control" formControlName="tipo" id="tipoSmart{{i}}" style="text-align: left;" (change)="tipoDependenteSmart(i)">
                                        <option value=""></option>
                                        <option value="CÔNJUGE">CÔNJUGE</option>
                                        <option value="FILHO">FILHO</option>
                                        <option value="FILHA">FILHA</option>
                                        <option value="OUTROS">OUTROS</option>
                                    </select>
                                </div>
                                <div class="form-group col-7">
                                    <label>Nome:</label>
                                    <input type="text" class="form-control" formControlName="nome" oninput="this.value = this.value.toUpperCase()"
                                        [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null) ? '#dd443e' : null}">
                                </div>
                                <div class="form-group col-3">
                                    <label>Nascimento:</label>
                                    <input type="text" class="form-control" formControlName="nascimento" id="nascimentoSmart{{i}}" maxlength="10"
                                        onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}"
                                        [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) ? '#dd443e' : null}">
                                </div>
                                <div class="form-group col-6" *ngIf="hibrido">
                                    <label>ASF:</label>
                                    <select class="form-control" formControlName="asf" id="asfSmart{{i}}"
                                        (change)="selectAsf()">
                                        <option value="FUNERAL AUTOMÁTICO ATÉ 18 ANOS" disabled>FUNERAL AUTOMÁTICO ATÉ 18 ANOS</option>
                                        <option value="FUNERAL AUTOMÁTICO VITALÍCIO" disabled>FUNERAL AUTOMÁTICO VITALÍCIO</option>
                                        <option value="NÃO HÁ FUNERAL CONTRATADO">NÃO HÁ FUNERAL CONTRATADO</option>
                                        <option value="FUNERAL CONTRATADO">FUNERAL CONTRATADO</option>
                                    </select>
                                </div>
                                 <div class="form-group col-3">
                                    <label>CPF:</label>
                                    <input type="text" class="form-control" formControlName="cpf" cpf (keyup)="app.checkCPF(dependentes.controls.dependente.value[i].cpf)"
                                        id="cpfSmart{{i}}" [textMask]="{mask: MASKS.cpf.textMask}"
                                        [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null || !app.checkCPF(dependentes.controls.dependente.value[i].cpf)) ? '#dd443e' : null}">
                                        <div class="errorMsg" *ngIf="dependentes.controls.dependente.value[i].cpf && !app.checkCPF(dependentes.controls.dependente.value[i].cpf)">CPF incorreto!</div>
                                </div>
                                <div class="form-group col-3">
                                    <label>Sexo:</label>
                                    <select class="form-control" formControlName="sexo" id="sexoSmart{{i}}"
                                    [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].sexo == '' || dependentes.controls.dependente.value[i].sexo == null) ? '#dd443e' : null}">
                                        <option value=""></option>
                                        <option value="M">MASCULINO</option>
                                        <option value="F">FEMININO</option>
                                    </select>
                                </div>
                                <div class="form-group col-3">
                                    <div class="checkCor">
                                        <label class="form-check-label" for="conectaCheckSmart{{i}}">Conecta</label><br>
                                        <input class="form-check-input" type="checkbox" id="conectaCheckSmart{{i}}"
                                        [ngStyle]="{'pointer-events': !dependentes.controls.dependente.value[i].conecta && (qtdeConectaDependentes() >= maxDependentesConecta) ? 'none' : 'all'}"
                                        formControlName="conecta">
                                    </div>
                                </div>
                                <div class="form-group col-9">
                                    <label>E-mail:</label>
                                    <input type="text" class="form-control" id="emailSmart{{i}}" formControlName="email"
                                    (keyup)="app.checkEmail(dependentes.controls.dependente.value[i].email)"
                                    [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].email == '' || dependentes.controls.dependente.value[i].email == null || !app.checkEmail(dependentes.controls.dependente.value[i].email)) ? '#dd443e' : null}">
                                    <div class="errorMsg" *ngIf="dependentes.controls.dependente.value[i].email && !app.checkEmail(dependentes.controls.dependente.value[i].email)">E-mail inválido!</div>
                                </div>
                                <div style="width:100%;text-align:right">
                                    <br>
                                    <button class="btn btn-outline-danger" (click)="removeDependente(i)" style="margin:5px">
                                        <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                    </button>
                                    <button class="btn btn-outline-success" (click)="addDependente()" style="margin:5px"
                                        [disabled]="(dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null) || (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) || (dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null)">
                                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                    </button>
                                </div>
                            </div>
                            <hr>
                        </div>
                    </div>
            </form>
            <form [formGroup]="dependentesExtra" *ngIf="hibrido">
                <div class="row" formArrayName="dependenteExtra">
                    <div class="form-group col-3" style="text-align: left;"
                        [hidden]="listaDeDependentesExtra?.length > 0">
                        <br>
                        <button class="btn btn-outline-success btn-sm" (click)="addDependenteExtra()">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE EXTRA
                        </button>
                    </div>
                    <div class="form-group col-9" [hidden]="listaDeDependentesExtra?.length > 0">
                        &nbsp;
                    </div>
                    <div class="col col-12"
                        *ngFor="let dependenteExtra of dependentesExtraFormGroup.controls; let i = index;">
                        <div class="row align-items-center" [formGroupName]="i">
                            <div class="form-group col-2">
                                <label>Tipo:</label>
                                <select class="form-control" formControlName="tipo" (change)="tipoDependenteExtraSmart(i)" id="tipoSmartExtra{{100+i}}" style="text-align: left;">
                                    <option value=""></option>
                                    <option value="MÃE">MÃE</option>
                                    <option value="PAI">PAI</option>
                                    <option value="SOGRO">SOGRO</option>
                                    <option value="SOGRA">SOGRA</option>
                                    <option value="OUTRO">OUTRO</option>
                                </select>
                            </div>
                            <div class="form-group col-7">
                                <label>Nome:</label>
                                <input type="text" class="form-control" formControlName="nome"
                                    oninput="this.value = this.value.toUpperCase()"
                                    [ngStyle]="{'border-color': (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null || dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-3">
                                <label>Nascimento:</label>
                                <input type="text" class="form-control" formControlName="nascimento"
                                    id="nascimentoSmartExtra{{100+i}}" maxlength="10"
                                    onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}"
                                    [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null) || (dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null)) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-6" *ngIf="hibrido">
                                <label>ASF:</label>
                                <select class="form-control" formControlName="asf" id="asfSmartExtra{{i}}" (change)="selectAsfExtras()">
                                    <option value="FUNERAL AUTOMÁTICO ATÉ 18 ANOS" disabled>FUNERAL AUTOMÁTICO ATÉ 18 ANOS</option>
                                    <option value="FUNERAL AUTOMÁTICO VITALÍCIO" disabled>FUNERAL AUTOMÁTICO VITALÍCIO</option>
                                    <option value="NÃO HÁ FUNERAL CONTRATADO">NÃO HÁ FUNERAL CONTRATADO</option>
                                    <option value="FUNERAL CONTRATADO">FUNERAL CONTRATADO</option>
                                </select>
                            </div>                              
                            <div class="form-group col-3">
                                <label>CPF:</label>
                                <input type="text" class="form-control" formControlName="cpf" cpf
                                    id="cpfSmartExtra{{100+i}}" [textMask]="{mask: MASKS.cpf.textMask}" (keyup)="app.checkCPF(dependentesExtra.controls.dependenteExtra.value[i].cpf)"
                                    [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null || !app.checkCPF(dependentesExtra.controls.dependenteExtra.value[i].cpf) ? '#dd443e' : null}">
                                    <div class="errorMsg" *ngIf="dependentesExtra.controls.dependenteExtra.value[i].cpf && !app.checkCPF(dependentesExtra.controls.dependenteExtra.value[i].cpf)">CPF incorreto!</div>
                                </div>
                            <div class="form-group col-3">
                                <label>Sexo:</label>
                                <select class="form-control" formControlName="sexo" id="sexoSmartExtra{{i}}"
                                [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].conecta && (dependentesExtra.controls.dependenteExtra.value[i].sexo == '' || dependentesExtra.controls.dependenteExtra.value[i].sexo == null) ? '#dd443e' : null}">
                                    <option value=""></option>
                                    <option value="M">MASCULINO</option>
                                    <option value="F">FEMININO</option>
                                </select>
                            </div>
                            <div class="form-group col-3" *ngIf="hibrido">
                                <div class="checkCor">
                                    <label class="form-check-label" for="conectaCheckSmartExtra{{i}}">Conecta</label><br>
                                    <input class="form-check-input" type="checkbox" id="conectaCheckSmartExtra{{i}}" [ngStyle]="{'pointer-events': !dependentesExtra.controls.dependenteExtra.value[i].conecta && (qtdeConectaDependentes() >= maxDependentesConecta) ? 'none' : 'all'}"  formControlName="conecta">
                                </div>
                            </div>
                            <div class="form-group col-9">
                                <label>E-mail:</label>
                                <input type="text" class="form-control" id="emailSmartExtra{{i}}" formControlName="email"
                                (keyup)="app.checkEmail(dependentesExtra.controls.dependenteExtra.value[i].email)"
                                [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].conecta && (dependentesExtra.controls.dependenteExtra.value[i].email == '' || dependentesExtra.controls.dependenteExtra.value[i].email == null) ? '#dd443e' : null}">
                                <div class="errorMsg" *ngIf="dependentesExtra.controls.dependenteExtra.value[i].email && !app.checkEmail(dependentesExtra.controls.dependenteExtra.value[i].email)">E-mail inválido!</div>
                            </div>
                            <div style="width:100%;text-align:right">
                                <button class="btn btn-outline-danger" type="button" style="margin:5px" (click)="removeDependenteExtra(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>
                                <button class="btn btn-outline-success" type="button" style="margin:5px"
                                    (click)="addDependenteExtra()"
                                    [disabled]="((dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) || (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null)) || (dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null) || ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null))))">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </form>
            <br>
            <label>Proponente Possui Problemas Pré Existentes?</label>
            <div style="padding:10px">
                <div class="row">
                    <div class="col col-2">
                        <br>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="doencaSimSmart"
                                [disabled]="doenca == 'NÃO'" (change)="selectDoencaSmart($event)">
                            <label class="form-check-label" for="doencaSimSmart" style="margin-left:10px">SIM</label>
                        </div>
                    </div>
                    <div class="col col-2">
                        <br>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="doencaNaoSmart"
                                [disabled]="doenca == 'SIM'" (change)="selectDoencaSmart($event)">
                            <label class="form-check-label" for="doencaNaoSmart" style="margin-left:10px">NÃO</label>
                        </div>
                    </div>
                    <div class="col col-8" *ngIf="doenca == 'SIM'">
                        <label>Especificar:</label>
                        <input type="text" class="form-control" [(ngModel)]="especificarDoenca"
                            oninput="this.value = this.value.toUpperCase()">
                    </div>
                </div>
            </div>
            <br><br>
            <label>ADESÃO E MANUTENÇÃO</label>
            <div class="row">
                <div class="col col-3">
                    <label>Data Adesão</label>
                    <input type="date" (change)="verificaData(dataAdesao)" class="form-control" [(ngModel)]="dataAdesao"
                        [ngStyle]="{'border-color': !dataAdesao ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Data 1ª Vencimento</label>
                    <input type="date" (change)="verificaData(primeiroVencimento)" class="form-control"
                        [(ngModel)]="primeiroVencimento"
                        [ngStyle]="{'border-color': !primeiroVencimento ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3" *ngIf="editaAdesao == 'SIM'">
                    <label>Taxa de Adesão:</label>
                    <input type="number" class="form-control" [(ngModel)]="taxaAdesao">
                </div>
                <div class="col col-3" *ngIf="editaAdesao == 'NÃO'">
                    <label>Taxa de Adesão:</label>
                    <input type="number" class="form-control" [(ngModel)]="taxaAdesao" readonly>
                </div>
                <div class="col col-3" *ngIf="editaMensalidade == 'SIM'">
                    <label>Mensalidade:</label>
                    <input type="number" class="form-control" [(ngModel)]="mensalidade">
                </div>
                <div class="col col-3" *ngIf="editaMensalidade == 'NÃO'">
                    <label>Mensalidade:</label>
                    <input type="number" class="form-control" [(ngModel)]="mensalidade" readonly>
                </div>
                <div class="col col-3" *ngIf="tipoPlano != 'servidor'">
                    <label>Dia Vencimento:</label>
                    <input type="date" class="form-control" [(ngModel)]="diaVencimento"
                        [ngStyle]="{'border-color': !diaVencimento ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3" *ngIf="editaTotal == 'SIM'">
                    <label>Total Com ASF:</label>
                    <input type="number" class="form-control" [(ngModel)]="totalMensalidade">
                </div>
                <div class="col col-3" *ngIf="editaTotal == 'NÃO'">
                    <label>Total Com ASF:</label>
                    <input type="number" class="form-control" [(ngModel)]="totalMensalidade" readonly>
                </div>
            </div>
            <br><br>
            <div>
                <label>FORMA DE PAGAMENTO</label>
                <br><br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="descontoFolhaSmart"
                                [disabled]="formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'BOLETO' || formaPagamento == 'CARNÊ' || bloqueioPagamentos || formaPagamento == 'CONVÊNIO CELESC'"
                                (change)="selectFormaPagamentoSmart($event)">
                            <label class="form-check-label" for="descontoFolhaSmart" style="margin-left:15px">DESCONTO EM FOLHA DE PAGAMENTO</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO'"
                    style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                    <br>
                    <div class="row" style="margin-left:20px">
                        <div class="col col-4">
                            <label>A PARTIR DO MÊS:</label>
                            <input type="month" [(ngModel)]="mesFolhaPagamento" class="form-control">
                        </div>
                    </div>
                    <br><br>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="debitoRecorrenteSmart"
                                [disabled]="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'BOLETO' || formaPagamento == 'CARNÊ' || formaPagamento == 'CONVÊNIO CELESC'"
                                (change)="selectFormaPagamentoSmart($event)">
                            <label class="form-check-label" for="debitoRecorrenteSmart" style="margin-left:15px">DEBITO
                                RECORRENTE</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="formaPagamento == 'DEBITO RECORRENTE'"
                    style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                    <div class="row" style="margin-left:20px">
                        <div class="col" *ngFor="let cartao of cartoes; let i = index" style="padding:10px">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="cardSmartSmart{{i}}" name="ch" (change)="cartaoSelect($event,i)">
                                <label class="form-check-label" for="cardSmartSmart{{i}}" style="margin-left: 15px; font-size:0.8em;">{{cartao | uppercase}}</label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div style="padding:10px">
                        <div class="row" *ngIf="cartaoSelecionado != ''">
                            <div class="col col-4">
                                <label>Nome</label>&nbsp;<small>(idêntico ao do cartão de crédito)</small>
                                <input type="text" class="form-control" [(ngModel)]="nomeCartao"
                                    oninput="this.value = this.value.toUpperCase()">
                            </div>
                            <div class="col col-4">
                                <label>Número do Cartão</label>
                                <input type="text" class="form-control" [(ngModel)]="numeroCartao" maxlength="19"
                                    onkeyup="var val = this.value;var newval = '';val = val.replace(/\s/g, '');for(var i = 0; i < val.length; i++) {if(i%4 == 0 && i > 0) newval = newval.concat(' ');newval = newval.concat(val[i]);}this.value = newval;">
                            </div>
                            <div class="col col-2">
                                <label>Validade</label>
                                <input type="text" class="form-control" [(ngModel)]="validadeCartao" maxlength="5"
                                    onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v}">
                            </div>
                            <div class="col col-2">
                                <label>Código Segurança</label>
                                <input type="text" class="form-control" [(ngModel)]="codigoCartao" maxlength="3">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <hr>
                    <div class="row" style="margin-left:25px">
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="geraLinkSmart"
                                    [(ngModel)]="gerarLinkCartao"
                                    [disabled]="nomeCartao  != ''|| numeroCartao  != ''|| validadeCartao  != ''|| codigoCartao != ''">
                                <label class="form-check-label" for="geraLinkSmart" style="margin-left:20px">Gerar Link
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="boletoMensalSmart"
                                [disabled]="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'CARNÊ' || formaPagamento == 'CONVÊNIO CELESC'"
                                (change)="selectFormaPagamentoSmart($event)">
                            <label class="form-check-label" for="boletoMensalSmart" style="margin-left:15px">BOLETO</label>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="carneSmart"
                                [disabled]="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'BOLETO' || formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'CONVÊNIO CELESC'"
                                (change)="selectFormaPagamentoSmart($event)">
                            <label class="form-check-label" for="carneSmart" style="margin-left:15px">CARNÊ</label>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="estado == 'SC'">
                    <div class="col col-3">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="celescSmart"
                                [disabled]="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'BOLETO' || formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'CARNÊ'"
                                (change)="selectFormaPagamentoSmart($event)">
                            <label class="form-check-label" for="celescSmart" style="margin-left:15px">CONVÊNIO
                                CELESC</label>
                        </div>
                    </div>
                    <div class="col col-4" *ngIf="formaPagamento == 'CONVÊNIO CELESC'">
                        <label>Unidade Consumidora</label>
                        <input type="text" pattern="\d*" class="form-control" style="text-align:center"
                            placeholder="Código Unidade Consumidora" min="0" [(ngModel)]="unidadeConsumidora"
                            maxlength="13">
                    </div>
                </div>
                <br><br>
                <div class="row">
                    <div class="col" style="text-align: center; color:#5e5e5e">
                        <h5>Clique ou Arraste para Enviar Documentos ou Fotos</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="uploadfilecontainer" (click)="fileInputSmart.click()" appDragDrop
                            (onFileDropped)="onChangeCadastro($event)">
                            <input hidden type="file" #fileInputSmart (change)="onChangeClick($event)" multiple>
                        </div>
                        <div class="files-list" *ngFor="let file of files;let i= index">
                            <div class="row align-items-center" style="width: 100%;">
                                <div class="col col-11">
                                    <p>{{ file }}</p>
                                </div>
                                <div class="col col-1 del" (click)="deleteDocFiles(i)">
                                    X
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br><br>
                <div class="row" style="padding:10px">
                    <div class="col col-12" style="text-align: center;">
                        <button class="btn btn-success btn-block" (click)="cadastrarAssociadoSmart()" [hidden]="empresaAssociado != 'CONECTA'" [disabled]="!primeiroVencimento || !formaPagamento || (formaPagamento == 'DEBITO RECORRENTE' && (!nomeCartao || !numeroCartao || !validadeCartao || !codigoCartao)) || plano == '' || !diaVencimento || !cpf || !nascimento || !rua || !numero || !bairro || !municipio || !estado || !botao">CADASTRAR ASSOCIADO SMART</button>
                        <button class="btn btn-success btn-block" (click)="cadastrarAssociado()" [hidden]="!pendente" [disabled]="!primeiroVencimento || !observacaoPendente || plano == '' || !nome">CADASTRAR ASSOCIADO PENDENTE</button>
                        <button class="btn btn-success btn-block" (click)="cadastrarAssociado()" [hidden]="pendente || empresaAssociado == 'CONECTA'" [disabled]="!primeiroVencimento || !formaPagamento || (formaPagamento == 'DEBITO RECORRENTE' && (!nomeCartao || !numeroCartao || !validadeCartao || !codigoCartao)) || plano == '' || !diaVencimento || !cpf || !nascimento || !rua || !numero || !bairro || !municipio || !estado || !botao">CADASTRAR ASSOCIADO</button>
                        <button class="btn btn-success btn-block" (click)="cadastrarAssociado()" [hidden]="!pendente" [disabled]="!primeiroVencimento || !observacaoPendente || plano == '' || !nome">CADASTRAR ASSOCIADO PENDENTE</button>
                        <span *ngIf="pendente && !observacaoPendente" style="color:red;font-weight: bold;">*Observação
                            Pendente Necessária</span>&nbsp;&nbsp;<span
                            *ngIf="pendente && !nome" style="color:red;font-weight: bold;">*Nome
                            Necessário</span>&nbsp;&nbsp;<span *ngIf="pendente && !plano"
                            style="color:red;font-weight: bold;">*Plano Necessário</span>
                    </div>
                </div>
            </div>
            <div style="height: 3em;"></div>
        </div>
    </div>
</div>
<!-- NOVO SEGUROS -->
<div class="modal" id="modalNovaSeg">
    <div class="row  align-items-center header">
        <div class="col titulo">
            CADASTRAR ASSOCIADO SEGUROS
        </div>
        <div class="col botoes">
            <button class="btn btn-outline-light" (click)="fecharModalNovoSeguros()">
                <fa-icon [icon]="['fas', 'window-close']"></fa-icon>&nbsp;CANCELAR
            </button>
        </div>
    </div>
    <div class="corpo">
        <div class="row align-items-center">
            <div class="col col-4">
                <label>Data Adesão</label>
                <input type="date" (change)="verificaData(dataAdesao)" class="form-control" [(ngModel)]="dataAdesao"
                    [ngStyle]="{'border-color': !dataAdesao ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-6">
                <label>Vendedor</label>
                <select class="form-control" [(ngModel)]="vendedor" (change)="selectVendedor(vendedor)"
                    [ngStyle]="{'border-color': !vendedor ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let vendedor of todosVendedores" [value]="vendedor.nome">{{vendedor.nome}}</option>
                </select>
            </div>
            <div class="col col-2">
                <br>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="pendente" id="pendenteCheckSeg">
                    <label class="form-check-label" for="pendenteCheckSeg">&nbsp;&nbsp;Pendente</label>
                </div>
            </div>
        </div>
        <br>
        <div class="row align-items-center">
            <div class="col col-8">
                <label>Sub-Contrato</label>
                <ng-select class="form-control" [items]="subContratos" bindLabel="nome" appendTo="body"
                    [(ngModel)]="subContrato" [disabled]="planoFisicoCheck || planoEmpresarialCheck"
                    (change)="escolheSubContrato(subContrato)"></ng-select>
            </div>
            <div class="col col-4">
                <label>Plano:</label>
                <select class="form-control" [(ngModel)]="plano" (change)="selectPlano('seguro',plano)"
                    [ngStyle]="{'border-color': !plano ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let plan of planosServidores" [value]="plan.nome">{{plan.nome}}</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row" [hidden]="!pendente">
            <div class="col col-4">
                <label>Motivo da Pendência</label>
                <select class="form-control" [(ngModel)]="motivoPendencia">
                    <option value="" selected></option>
                    <option *ngFor="let motivo of motivosPendencia" [value]="motivo.nome">{{motivo.nome}}</option>
                </select>
            </div>
            <div class="col col-8">
                <label>Observação Pendente</label>
                <input type="text" class="form-control" [(ngModel)]="observacaoPendente"
                    oninput="this.value = this.value.toUpperCase()"
                    [ngStyle]="{'border-color': !observacaoPendente ? '#dd443e' : '#3cb42c'}">
            </div>
        </div>
        <div class="row" *ngIf="plano">
            <div class="col col-2">
                <label>CPF:</label>
                <input type="text" class="form-control" [(ngModel)]="cpf" cpf [textMask]="{mask: MASKS.cpf.textMask}"
                    [ngStyle]="{'border-color': !cpfCheck || !app.checkCPF(cpf) ? '#dd443e' : '#3cb42c'}"
                    (keyup)="app.checkCPF(cpf);cpfUnico('seguros',cpf)">
                    <div class="errorMsg" *ngIf="cpf && !app.checkCPF(cpf)">CPF incorreto!</div>
                    <div class="errorMsg" *ngIf="!cpfCheck">CPF Já Cadastrado!</div>
            </div>
            <div class="col col-2">
                <label>Nascimento:</label>
                <input type="text" class="form-control" [(ngModel)]="nascimento" maxlength="10"
                    onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}"
                    [ngStyle]="{'border-color': (nascimento.length != 10 || maisSessentaNove) ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-5"
                *ngIf="pendente || (!maisSessentaNove && nascimento.length == 10 && plano && cpfCheck && vendedor)">
                <label>Nome Completo:</label>
                <input type="text" class="form-control" [(ngModel)]="nome"
                    oninput="this.value = this.value.toUpperCase()"
                    [ngStyle]="{'border-color': !nome ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-3"
                *ngIf="pendente || (!maisSessentaNove && nascimento.length == 10 && plano && cpfCheck && vendedor)">
                <label>Estado Civil:</label>
                <select class="form-control" [(ngModel)]="estadoCivil"
                    [ngStyle]="{'border-color': !estadoCivil ? '#dd443e' : '#3cb42c'}">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                    <option value="CASADO">CASADO</option>
                    <option value="DIVORCIADO">DIVORCIADO</option>
                    <option value="VIÚVO">VIÚVO</option>
                    <option value="SEPARADO">SEPARADO</option>
                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
        </div>
        <br>
        <div [hidden]="!pendente && (nascimento.length != 10 || !plano || !cpfCheck || !vendedor || !nome || !estadoCivil || !app.checkCPF(cpf))">
            <div class="row align-items-center">
                <div class="col col-3">
                    <label>Sexo:</label>
                    <select class="form-control" [(ngModel)]="sexo"
                        [ngStyle]="{'border-color': !sexo ? '#dd443e' : '#3cb42c'}">
                        <option value="M">MASCULINO</option>
                        <option value="F">FEMININO</option>
                    </select>
                </div>
                <div class="col col-3">
                    <label>Telefone:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                        [(ngModel)]="telefone">
                </div>
                <div class="col col-3">
                    <label>Celular:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                        [(ngModel)]="celular" [ngStyle]="{'border-color': !celular ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3">
                    <label>Profissão:</label>
                    <input type="text" class="form-control" [(ngModel)]="funcao"
                        oninput="this.value = this.value.toUpperCase()">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <label>E-mail:</label>
                    <input type="text" class="form-control" [(ngModel)]="email"
                    (keyup)="app.checkEmail(email)"
                    oninput="this.value = this.value.toUpperCase()">
                    <div class="errorMsg" *ngIf="email && !app.checkEmail(email)">E-mail inválido!</div>
                </div>
            </div>
        </div>
        <div [hidden]="!pendente && (!nome || !cpfCheck || !app.checkCPF(cpf))">
            <form [formGroup]="dependentes" [hidden]="empresaAssociado == 'SEGUROS'">
                <br>
                <label>DADOS CADASTRAIS DOS DEPENDENTES</label>
                <br>
                <div class="row" formArrayName="dependente">
                    <div class="form-group col-3" style="text-align: left;" [hidden]="listaDeDependentes?.length > 0">
                        <br>
                        <button class="btn btn-outline-success btn-sm" (click)="addDependente()">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE
                        </button>
                    </div>
                    <div class="form-group col-9" [hidden]="listaDeDependentes?.length > 0">
                        &nbsp;
                    </div>
                    <div class="col col-12" *ngFor="let dep of dependentesFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row align-items-center">
                            <div class="form-group col-2">
                                <label>Tipo:</label>
                                <select class="form-control" formControlName="tipo" id="tipoSeg{{i}}"
                                    style="text-align: left;" (change)="tipoDependente(i)"
                                    [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null || dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) ? '#dd443e' : null}">
                                    <option value=""></option>
                                    <option value="CÔNJUGE">CÔNJUGE</option>
                                    <option value="FILHO">FILHO</option>
                                    <option value="FILHA">FILHA</option>
                                </select>
                            </div>
                            <div class="form-group col-3">
                                <label>Nome:</label>
                                <input type="text" class="form-control" formControlName="nome"
                                    oninput="this.value = this.value.toUpperCase()"
                                    [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null || dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-2">
                                <label>Data Nascimento:</label>
                                <input type="text" class="form-control" formControlName="nascimento"
                                    (change)="verificaNascimento(i)" id="nascimentoSeg{{i}}" maxlength="10"
                                    onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}"
                                    [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null) || (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) || (dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null)) ? '#dd443e' : (dependentes.controls.dependente.value[i].asf == 'FUNERAL AUTOMÁTICO ATÉ 18 ANOS' && (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null)) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-2">
                                <label>CPF:</label>
                                <input type="text" class="form-control" formControlName="cpf" cpf id="cpfSeg{{i}}"
                                    [textMask]="{mask: MASKS.cpf.textMask}" (keyup)="app.checkCPF(dependentes.controls.dependente.value[i].cpf)"
                                    [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null) || (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null)) ? '#dd443e' : null}">
                                    <div class="errorMsg" *ngIf="dependentes.controls.dependente.value[i].cpf && !app.checkCPF(dependentes.controls.dependente.value[i].cpf)">CPF incorreto!</div>
                                </div>
                            <div class="form-group col-2">
                                <label>ASF:</label>
                                <select class="form-control" formControlName="asf" id="asfSeg{{i}}"
                                    (change)="selectAsf()">
                                    <option value="FUNERAL AUTOMÁTICO ATÉ 18 ANOS" disabled>FUNERAL AUTOMÁTICO ATÉ 18
                                        ANOS</option>
                                    <option value="FUNERAL AUTOMÁTICO VITALÍCIO" disabled>FUNERAL AUTOMÁTICO VITALÍCIO
                                    </option>
                                    <option value="NÃO HÁ FUNERAL CONTRATADO"
                                        [disabled]="dependentes.controls.dependente.value[i].tipo == 'CÔNJUGE'">NÃO HÁ
                                        FUNERAL CONTRATADO</option>
                                    <option value="FUNERAL CONTRATADO"
                                        [disabled]="dependentes.controls.dependente.value[i].tipo == 'CÔNJUGE'">FUNERAL
                                        CONTRATADO</option>
                                </select>
                            </div>
                            <div class="form-group col-1" style="text-align: right;">
                                <br>
                                <button class="btn btn-outline-danger btn-sm" (click)="removeDependente(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-outline-success btn-sm" (click)="addDependente()"
                                    [disabled]="((dependentes.controls.dependente.value[i].asf == 'FUNERAL AUTOMÁTICO ATÉ 18 ANOS' && (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null)) || (dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) || (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null)) || (dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) || ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null))))">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <form [formGroup]="dependentesExtra" [hidden]="empresaAssociado == 'SEGUROS'">
                <br>
                <hr>
                <label>DEPENDENTES EXTRAS</label>
                <div class="row" formArrayName="dependenteExtra">
                    <div class="form-group col-3" style="text-align: left;"
                        [hidden]="listaDeDependentesExtra?.length > 0">
                        <br>
                        <button class="btn btn-outline-success btn-sm" (click)="addDependenteExtra()">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE EXTRA
                        </button>
                    </div>
                    <div class="form-group col-9" [hidden]="listaDeDependentesExtra?.length > 0">
                        &nbsp;
                    </div>
                    <div class="col col-12"
                        *ngFor="let dependenteExtra of dependentesExtraFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row align-items-center">
                            <div class="form-group col-2">
                                <label>Tipo:</label>
                                <select class="form-control" formControlName="tipo" id="tipoSeg{{100+i}}"
                                    (change)="tipoDependenteExtra(i)" style="text-align: left;"
                                    [ngStyle]="{'border-color': (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null || dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) ? '#dd443e' : null}">
                                    <option value=""></option>
                                    <option value="MÃE">MÃE</option>
                                    <option value="PAI">PAI</option>
                                    <option value="SOGRO">SOGRO</option>
                                    <option value="SOGRA">SOGRA</option>
                                </select>
                            </div>
                            <div class="form-group col-3">
                                <label>Nome:</label>
                                <input type="text" class="form-control" formControlName="nome"
                                    oninput="this.value = this.value.toUpperCase()"
                                    [ngStyle]="{'border-color': (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null || dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-2">
                                <label>Data Nascimento:</label>
                                <input type="text" class="form-control" formControlName="nascimento"
                                    id="nascimentoSeg{{100+i}}" maxlength="10"
                                    onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}"
                                    [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null) || (dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null)) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-2">
                                <label>CPF:</label>
                                <input type="text" class="form-control" formControlName="cpf" cpf id="cpfSeg{{100+i}}"
                                    [textMask]="{mask: MASKS.cpf.textMask}" (keyup)="app.checkCPF(dependentesExtra.controls.dependenteExtra.value[i].cpf)"
                                    [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null)) ? '#dd443e' : null}">
                                    <div class="errorMsg" *ngIf="dependentesExtra.controls.dependenteExtra.value[i].cpf && !app.checkCPF(dependentesExtra.controls.dependenteExtra.value[i].cpf)">CPF incorreto!</div>
                                </div>
                            <div class="form-group col-2">
                                <label>ASF:</label>
                                <select class="form-control" formControlName="asf" id="asfExtraSeg{{100+i}}"
                                    (change)="selectAsfExtras()">
                                    <option value=""></option>
                                    <option value="AUTOMÁTICO SEM CUSTO" disabled>AUTOMÁTICO SEM CUSTO</option>
                                    <option value="NÃO HÁ FUNERAL CONTRATADO">NÃO HÁ FUNERAL CONTRATADO</option>
                                    <option value="FUNERAL CONTRATADO">FUNERAL CONTRATADO</option>
                                </select>
                            </div>
                            <div class="form-group col-1" style="text-align: right;">
                                <br>
                                <button class="btn btn-outline-danger btn-sm" (click)="removeDependenteExtra(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-outline-success btn-sm" (click)="addDependenteExtra()"
                                    [disabled]="((dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) || (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null)) || dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null))">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <br><br>
            </form>
            <div [hidden]="!app.checkCPF(cpf) && !cpfCheck">
                <label>ADESÃO E MANUTENÇÃO</label>
                <div class="row">
                    <div class="col col-3">
                        <label>Data 1ª Vencimento</label>
                        <input type="date" (change)="verificaData(primeiroVencimento)" class="form-control"
                            [(ngModel)]="primeiroVencimento"
                            [ngStyle]="{'border-color': !primeiroVencimento ? '#dd443e' : '#3cb42c'}">
                    </div>
                    <div class="col col-3" *ngIf="editaAdesao == 'SIM'">
                        <label>Taxa de Adesão:</label>
                        <input type="number" class="form-control" [(ngModel)]="taxaAdesao">
                    </div>
                    <div class="col col-3" *ngIf="editaAdesao == 'NÃO'">
                        <label>Taxa de Adesão:</label>
                        <input type="number" class="form-control" [(ngModel)]="taxaAdesao" readonly>
                    </div>
                    <div class="col col-2" *ngIf="editaMensalidade == 'SIM'">
                        <label>Mensalidade:</label>
                        <input type="number" class="form-control" [(ngModel)]="mensalidade">
                    </div>
                    <div class="col col-2" *ngIf="editaMensalidade == 'NÃO'">
                        <label>Mensalidade:</label>
                        <input type="number" class="form-control" [(ngModel)]="mensalidade" readonly>
                    </div>
                    <div class="col col-2" *ngIf="editaTotal == 'SIM'">
                        <label>Total ASF:</label>
                        <input type="number" class="form-control" [(ngModel)]="totalMensalidade">
                    </div>
                    <div class="col col-2" *ngIf="editaTotal == 'NÃO'">
                        <label>Total ASF:</label>
                        <input type="number" class="form-control" [(ngModel)]="totalMensalidade" readonly>
                    </div>
                </div>
                <br><br>
                <label>FORMA DE PAGAMENTO</label>
                <br><br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="descontoFolhaSeg"
                                [disabled]="formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'BOLETO' || formaPagamento == 'CARNÊ' || bloqueioPagamentos || formaPagamento == 'CONVÊNIO CELESC'"
                                (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="descontoFolhaSeg" style="margin-left:15px">DESCONTO EM FOLHA DE PAGAMENTO</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO'"
                    style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                    <br>
                    <div class="row" style="margin-left:20px">
                        <div class="col col-4">
                            <label>A PARTIR DO MÊS:</label>
                            <input type="month" [(ngModel)]="mesFolhaPagamento" class="form-control">
                        </div>
                    </div>
                    <br><br>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="debitoRecorrenteSeg"
                                [disabled]="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'BOLETO' || formaPagamento == 'CARNÊ' || formaPagamento == 'CONVÊNIO CELESC'"
                                (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="debitoRecorrenteSeg" style="margin-left:15px">DEBITO RECORRENTE</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="formaPagamento == 'DEBITO RECORRENTE'"
                    style="margin-left:40px; background-color:#ddd; border-radius: 10px; padding:10px">
                    <div class="row" style="margin-left:20px">
                        <div class="col" *ngFor="let cartao of cartoes; let i = index" style="padding:10px">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="cardSeg{{i}}" name="ch" (change)="cartaoSelect($event,i)">
                                <label class="form-check-label" for="cardSeg{{i}}" style="margin-left: 15px; font-size:0.8em;">{{cartao | uppercase}}</label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div style="padding:10px">
                        <div class="row" *ngIf="cartaoSelecionado != ''">
                            <div class="col col-4">
                                <label>Nome</label>&nbsp;<small>(idêntico ao do cartão de crédito)</small>
                                <input type="text" class="form-control" [(ngModel)]="nomeCartao"
                                    oninput="this.value = this.value.toUpperCase()">
                            </div>
                            <div class="col col-4">
                                <label>Número do Cartão</label>
                                <input type="text" class="form-control" [(ngModel)]="numeroCartao" maxlength="19"
                                    onkeyup="var val = this.value;var newval = '';val = val.replace(/\s/g, '');for(var i = 0; i < val.length; i++) {if(i%4 == 0 && i > 0) newval = newval.concat(' ');newval = newval.concat(val[i]);}this.value = newval;">
                            </div>
                            <div class="col col-2">
                                <label>Validade</label>
                                <input type="text" class="form-control" [(ngModel)]="validadeCartao" maxlength="5"
                                    onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v}">
                            </div>
                            <div class="col col-2">
                                <label>Código Segurança</label>
                                <input type="text" class="form-control" [(ngModel)]="codigoCartao" maxlength="3">
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-left:25px">
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="geraLinkSeg"
                                    [(ngModel)]="gerarLinkCartao"
                                    [disabled]="nomeCartao  != ''|| numeroCartao  != ''|| validadeCartao  != ''|| codigoCartao != ''">
                                <label class="form-check-label" for="geraLinkSeg" style="margin-left:20px">Gerar Link
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="boletoMensalSeg"
                                [disabled]="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'CARNÊ' || formaPagamento == 'CONVÊNIO CELESC'"
                                (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="boletoMensalSeg"
                                style="margin-left:15px">BOLETO</label>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="carneSeg"
                                [disabled]="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'BOLETO' || formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'CONVÊNIO CELESC'"
                                (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="carneSeg" style="margin-left:15px">CARNÊ</label>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="estado == 'SC'">
                    <div class="col col-3">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="celescSeg"
                                [disabled]="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'BOLETO' || formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'CARNÊ'"
                                (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="celescSeg" style="margin-left:15px">CONVÊNIO
                                CELESC</label>
                        </div>
                    </div>
                    <div class="col col-4" *ngIf="formaPagamento == 'CONVÊNIO CELESC'">
                        <label>Unidade Consumidora</label>
                        <input type="text" pattern="\d*" class="form-control" style="text-align:center"
                            placeholder="Código Unidade Consumidora" min="0" [(ngModel)]="unidadeConsumidora"
                            maxlength="13">
                    </div>
                </div>
                <br><br>
                <div class="row">
                    <div class="col" style="text-align: center; color:#5e5e5e">
                        <h5>Clique ou Arraste para Enviar Documentos ou Fotos</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop
                            (onFileDropped)="onChangeCadastro($event)">
                            <input hidden type="file" #fileInput (change)="onChangeClick($event)" multiple>
                        </div>
                        <div class="files-list" *ngFor="let file of files;let i= index">
                            <div class="row align-items-center" style="width: 100%;">
                                <div class="col col-11">
                                    <p>{{ file }}</p>
                                </div>
                                <div class="col col-1 del" (click)="deleteDocFiles(i)">
                                    X
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br><br>
                <div class="row" style="padding:10px">
                    <div class="col col-12" style="text-align: center;">
                        <button class="btn btn-success btn-block" (click)="cadastrarAssociado()" [hidden]="pendente"
                            [disabled]="!primeiroVencimento || !formaPagamento || (formaPagamento == 'DEBITO RECORRENTE' && (!nomeCartao || !numeroCartao || !validadeCartao || !codigoCartao)) || plano == '' || !cpf || !nascimento || !estadoCivil || !botao">CADASTRAR
                            ASSOCIADO</button>
                        <button class="btn btn-success btn-block" (click)="cadastrarAssociado()" [hidden]="!pendente"
                            [disabled]="!primeiroVencimento || !observacaoPendente || plano == '' || !nome">CADASTRAR
                            ASSOCIADO PENDENTE</button>
                        <span *ngIf="pendente && !observacaoPendente" style="color:red;font-weight: bold;">*Observação
                            Pendente Necessária</span>&nbsp;&nbsp;<span
                            *ngIf="pendente && !nome" style="color:red;font-weight: bold;">*Nome
                            Necessário</span>&nbsp;&nbsp;<span *ngIf="pendente && !plano"
                            style="color:red;font-weight: bold;">*Plano Necessário</span>
                    </div>
                </div>
            </div>
            <div style="height: 3em;"></div>
        </div>
    </div>
</div>
<div class="row align-items-center header">
    <div class="col titulo" style="text-align: left;">
        ASSOCIADOS
    </div>
    <div class="col">
        <button class="btn btn-outline-light" (click)="openModalNovoPj()"
            *ngIf="db?.usuario?.permissoes['cadastroAssociados'] == 'EDITAR'">
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>&nbsp;EMPRESARIAL
        </button>
    </div>
    <div class="col">
        <button class="btn btn-outline-light" (click)="openModalNova()"
            *ngIf="db?.usuario?.permissoes['cadastroAssociados'] == 'EDITAR'">
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>&nbsp;VIVA MAX
        </button>
    </div>
    <div class="col">
        <button class="btn btn-outline-light" (click)="openModalNovoSeguros()"
            *ngIf="db?.usuario?.permissoes['cadastroAssociados'] == 'EDITAR'">
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>&nbsp;SEGUROS
        </button>
    </div>
    <div class="col">
        <button class="btn btn-outline-light" (click)="openModalNovoConecta()"
            *ngIf="db?.usuario?.permissoes['cadastroAssociados'] == 'EDITAR'">
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>&nbsp;CONECTA
        </button>
    </div>
</div>
<div class="container-fluid">
    <div class="row align-items-end" style="padding:5px">
        <div class="col col-5">
            <label>Selecionar Tipo de Produto:</label>
            <select class="form-control" [(ngModel)]="tipoProduto">
                <option value="TODOS">TODOS</option>
                <option value="VIVA MAX">VIVA MAX</option>
                <option value="SEGUROS">SEGUROS</option>
                <option value="CONECTA">CONECTA</option>
            </select>
        </div>
        <div class="col col-3">
            <label>Adesão Inicio</label>
            <input type="date" class="form-control" [(ngModel)]="dataInicio">
        </div>
        <div class="col col-3">
            <label>Adesão Fim</label>
            <input type="date" class="form-control" [(ngModel)]="dataFim">
        </div>
        <div class="col col-1">
            <button class="btn btn-primary" (click)="buscarPorData(dataInicio,dataFim)"
                [disabled]="!dataInicio || !dataFim">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
    </div>
    <div class="row align-items-end" style="padding:5px">
        <div class="col col-5">
            <label>Matricula</label>
            <input type="number" class="form-control" min="0" [(ngModel)]="matriculaSearch"
                (keyup)="verificaEnter(matriculaSearch,'matricula',$event)">
        </div>
        <div class="col col-1">
            <button class="btn btn-primary" (click)="buscarAssociadoMatricula(matriculaSearch)"
                [disabled]="!matriculaSearch">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
        <div class="col col-5">
            <label>CPF</label>
            <input type="text" class="form-control" [(ngModel)]="cpfAssociado" cpf
                [textMask]="{mask: MASKS.cpf.textMask}" (keyup)="verificaEnter(cpfAssociado,'cpf',$event)">
        </div>
        <div class="col col-1">
            <button class="btn btn-primary" (click)="buscarAssociadoCpf(cpfAssociado)" [disabled]="!cpfAssociado">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
    </div>
    <div class="row align-items-end" style="padding:5px">
        <div class="col col-5">
            <label>Titular</label>
            <input type="text" class="form-control" [(ngModel)]="nomeTitular"
                (keyup)="verificaEnter(nomeTitular,'titular',$event)">
        </div>
        <div class="col col-1">
            <button class="btn btn-primary" (click)="buscarTitular(nomeTitular)" [disabled]="!nomeTitular">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
        <div class="col col-5">
            <label>Beneficiário</label>
            <input type="text" class="form-control" [(ngModel)]="nomeBeneficiario"
                (keyup)="verificaEnter(nomeBeneficiario,'beneficiario',$event)">
        </div>
        <div class="col col-1">
            <button class="btn btn-primary" (click)="buscarBeneficiario(nomeBeneficiario)"
                [disabled]="!nomeBeneficiario">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
    </div>
    <table class="table table-striped" style="text-align: center;">
        <thead class="thead-dark">
            <tr>
                <th>Adesão</th>
                <th>Matricula</th>
                <th>Associado</th>
                <th>CPF</th>
                <th>Contrato</th>
                <th>Produto</th>
                <th>Situação</th>
                <th
                    *ngIf="db?.usuario?.permissoes['associados'] == 'EDITAR' || db?.usuario?.permissoes['associados'] == 'VER'">
                </th>
                <th *ngIf="db?.usuario?.permissoes['associados'] == 'EDITAR'"></th>
            </tr>
        </thead>
        <tbody *ngFor="let associado of associados">
            <tr
                [ngStyle]="{'background-color': associado?.situacao == 'AFASTADO' ? '#a78bbb' : associado?.situacao == 'CANCELADO' ? '#c7444a' : associado?.situacao == 'PENDENTE' ? '#f7e96b' : (associado?.situacao == 'INADIMPLENTE I' || associado?.situacao == 'INADIMPLENTE II') ? '#f08929' : null, 'color':associado?.situacao == 'CANCELADO' ?  '#ffffff' : '#000000'}">
                <td>{{ associado?.dataAdesao | date: 'dd/MM/yyyy' }}</td>
                <td>{{ associado?.matricula }}</td>
                <td>{{ associado?.nome }}</td>
                <td>{{ associado?.cpf }}</td>
                <td>{{ associado?.subContrato }} <span
                        *ngIf="associado?.planoFisicoCheck && !associado?.planoEmpresarialCheck">PLANO
                        FISICO</span><span
                        *ngIf="!associado?.planoFisicoCheck && associado?.planoEmpresarialCheck">PLANO
                        EMPRESARIAL</span></td>
                <td>{{ associado?.empresa }}</td>
                <td>{{ associado?.situacao }}</td>
                <td
                    *ngIf="db?.usuario?.permissoes['associados'] == 'EDITAR' || db?.usuario?.permissoes['associados'] == 'VER'">
                    <button class="btn btn-outline-success" (click)="openModalEditar(associado, 'none')">
                        <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                    </button>
                </td>
                <td *ngIf="db.permissaoExcluirAssociado == 'SIM'">
                    <button class="btn btn-outline-danger" (click)="excluirAssociado(associado)">
                        <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>